$theme-color: #1b59a8;
$theme-color-hover: #1b59a8;

.ant-input:focus,
.ant-input-focused {
	// box-shadow: inset 0 0 0 1px #303030, 0 0 0 1px rgb(255 255 255 / 40%), 0 0 0 4px rgb(31 117 203 / 48%) !important;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
}
.ant-table-wrapper {
	.ant-table-thead {
		.ant-table-cell {
			padding: 10px !important;
			background-color: $theme-color;
			color: white;
		}
	}
	.ant-table-summary {
		.ant-table-cell {
			padding: 6px !important;
			text-align: center;
			&:nth-child(1) {
				text-align: left !important;
			}
		}
	}
}

.text_right {
	text-align: right !important;
}

.ant-layout {
	.ant-layout-sider {
		box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px !important;
		.ant-layout-sider-children {
			// overflow-y: scroll !important;
		}
		&.ant-layout-sider-collapsed {
			min-width: 50px !important;
			max-width: 50px !important;
			.ant-layout-sider-children {
				background-color: #fff !important;
				.ant-menu {
					border: none !important;
					.ant-menu-item {
						display: flex !important;
						// padding: 0 calc(50% - 16px / 2) !important;
						.ant-menu-title-content {
							transition: all 0s ease-in-out !important;
							opacity: 0 !important;
						}
					}
				}
			}
			.ant-layout-sider-trigger {
				min-width: 50px !important;
				max-width: 50px !important;
				// border-radius: 10px !important;
			}
		}
		background-color: #fff !important;
		.ant-layout-sider-children {
			background: #fff !important;
			.ant-menu {
				border: none !important;
				padding: 0 0;
				border-radius: 0;
				background: #fff !important;
				.ant-menu-submenu-title {
					padding-left: 10px !important;
					// height: 40px !important;
					margin: 2px 0 !important;
					// border-radius: 4px;
					&:hover {
						background: #e0e0e4 !important;
						color: #303030 !important;
					}
				}
				.ant-menu-item {
					margin: 10px 0 !important;
					// border-radius: 4px;
					padding-left: 10px !important;
					height: 40px !important;
					.ant-menu-title-content,
					svg {
						font-size: 0.8rem !important;
						// color: #303030 !important;
					}
					margin: 0 !important;
					// &:first-child {
					// 	margin-top: 0 !important;
					// }
					&.ant-menu-item-selected {
						background: $theme-color-hover !important;
						color: white;
						// background: #f8f8f8 !important;
						svg,
						span {
							color: #fff !important;
							// color: $theme-color !important;
						}
					}
					&:hover {
						background: #e0e0e4 !important;
						color: #303030 !important;
					}
				}
				.ant-menu-submenu {
					.ant-menu-sub {
						background: #0048a35c !important;
					}
					.ant-menu-title-content,
					svg {
						font-size: 0.8rem !important;
					}
					.ant-menu-submenu-title {
						margin: 0 !important;
					}
					margin: 0 !important;
					&:hover {
						background: #f8f8f8 !important;
						color: #303030 !important;
					}
				}
				.ant-menu-submenu-selected {
					.ant-menu-item-selected {
						background: #1b59a88f !important;
						svg,
						span {
							color: #fff !important;
						}
					}
					.ant-menu-submenu-title {
						background: $theme-color-hover !important;
						color: white;
						&:hover {
							background: $theme-color-hover !important;
							color: white !important;
						}
					}
					.ant-menu-submenu-arrow {
						color: white;
					}
				}
				.ant-menu-sub {
					.ant-menu-item {
						&:last-of-type {
							margin-bottom: 0 !important;
						}
					}
				}
			}
		}
		.ant-layout-sider-trigger {
			border-top: 1px solid #dbdbdb;
			max-width: 200px !important;
			min-width: 200px !important;
			background-color: $theme-color !important;
			border: none;
			span {
				svg {
					color: white !important;
				}
			}
		}
	}
}

.ant-menu-inline-collapsed {
	.ant-menu-item,
	.ant-menu-submenu {
		align-items: center !important;
		// height: 40px !important;
		// padding-left: 12px !important;

		.ant-menu-submenu-title {
			transition: none !important;
			height: auto !important;
			// padding-top: 5px !important;
			// padding-left: 12px !important;
			padding-left: 0 calc(50% - 16px / 2) !important;
		}
		.ant-menu-title-content {
			display: none !important;
		}
	}
}

.ant-menu-item svg {
	font-size: 14px !important;
}
.ant-menu-item-icon {
	font-size: 14px !important;
}

.ant-page-header {
	background-color: #fff !important;
	color: #000 !important;
	box-shadow: none !important;
	height: 60px !important;
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	// box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
	.ant-dropdown-trigger {
		color: #000 !important;
	}
}

.box_shadow {
	border: none !important;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	&:hover {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}
}

.card_container {
	.card {
		padding: 20px;
		&:nth-child(1) {
			.facility_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(2) {
			.facility_container_card {
				background: radial-gradient(#fbc1cc, #fa99b2);
			}
		}
		&:nth-child(3) {
			.facility_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
		&:nth-child(4) {
			.facility_container_card {
				background: radial-gradient(#60efbc, #58d5c9);
			}
		}
		&:nth-child(5) {
			.facility_container_card {
				background: radial-gradient(#f588d8, #c0a3e5);
			}
		}
		&:nth-child(6) {
			.facility_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(7) {
			.facility_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
	}
	&_card {
		border-radius: 10px;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
		transition: all 0.2s;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 20vh;
		transform: scale(0.95);
		color: #fff;
		font-size: 1.5rem;
		&:hover {
			box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
			transform: scale(1);
		}
	}
}

$primaryGradient1: linear-gradient(135deg, #cce7ff 0%, #027bce 100%);
$primaryGradient2: linear-gradient(135deg, #006393 0%, #0088ca 100%);
$primaryGradient3: linear-gradient(135deg, #071a57 0%, #006393 98.96%);
$secondaryGradient1: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
$secondaryGradient2: linear-gradient(135deg, #a51105 0%, #d52b1e 98.96%);

.badge {
	background-color: tomato;
	padding: 5px 10px;
	font-size: 12px;
	font-weight: 700;
	border-radius: 100px;
}

.app_container {
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-image: url('https://images.pexels.com/photos/2199293/pexels-photo-2199293.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
	.login_card {
		background: rgba(255, 255, 255, 0.6);
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(3.5px);
		-webkit-backdrop-filter: blur(3.5px);
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.18);
	}
}

.bold {
	font-weight: 700;
}

.error {
	border-color: red !important;
	.ant-select-selector {
		border-color: red !important;
	}
}

.edit_icon,
.pay_icon,
.delete_icon {
	cursor: pointer;
	background-color: #24b395;
	color: #fff;
	width: 30px;
	height: 30px;
	border-radius: 2px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.delete_icon {
	background-color: #eb5353;
	margin-right: 0;
}
.pay_icon {
	background-color: #1890ff;
}

.space-between {
	display: flex;
	justify-content: space-between !important;
}

.form-no-padding {
	.ant-form-item-label {
		padding: 0 !important;
	}
}

.errorText {
	color: red !important;
}
