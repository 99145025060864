/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^='ant-']::-ms-clear,
[class*='ant-']::-ms-clear,
[class^='ant-'] input::-ms-clear,
[class*='ant-'] input::-ms-clear,
[class^='ant-'] input::-ms-reveal,
[class*='ant-'] input::-ms-reveal {
	display: none;
}
/* stylelint-disable at-rule-no-unknown */
html,
body {
	width: 100%;
	height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
	display: none;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}
html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
	width: device-width;
}
* {
	font-family: 'Open Sans', sans-serif !important;
}

body {
	margin: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
	outline: none !important;
}
hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5em;
	/* color: rgba(0, 0, 0, 0.85); */
	font-weight: 500;
}
p {
	margin-top: 0;
	margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	text-decoration: underline dotted;
	border-bottom: 0;
	cursor: help;
}
address {
	margin-bottom: 1em;
	font-style: normal;
	line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
	-webkit-appearance: none;
}
ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}
dt {
	font-weight: 500;
}
dd {
	margin-bottom: 0.5em;
	margin-left: 0;
}
blockquote {
	margin: 0 0 1em;
}
dfn {
	font-style: italic;
}
b,
strong {
	font-weight: bolder;
}
small {
	font-size: 80%;
}
sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}
sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}
a {
	color: #5c987a;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	-webkit-text-decoration-skip: objects;
}
a:hover {
	color: #80a691;
}
a:active {
	color: #40735b;
}
a:active,
a:hover {
	text-decoration: none;
	outline: 0;
}
a:focus {
	text-decoration: none;
	outline: 0;
}
a[disabled] {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
pre,
code,
kbd,
samp {
	font-size: 1em;
	/* font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace; */
}
pre {
	margin-top: 0;
	margin-bottom: 1em;
	overflow: auto;
}
figure {
	margin: 0 0 1em;
}
img {
	vertical-align: middle;
	border-style: none;
}
svg:not(:root) {
	overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
	touch-action: manipulation;
}
table {
	border-collapse: collapse;
}
caption {
	padding-top: 0.75em;
	padding-bottom: 0.3em;
	color: rgba(0, 0, 0, 0.45);
	text-align: left;
	caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
	margin: 0;
	color: inherit;
	font-size: inherit;
	/* font-family: inherit; */
	line-height: inherit;
}
button,
input {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	padding: 0;
	border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
	box-sizing: border-box;
	padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
	-webkit-appearance: listbox;
}
textarea {
	overflow: auto;
	resize: vertical;
}
fieldset {
	min-width: 0;
	margin: 0;
	padding: 0;
	border: 0;
}
legend {
	display: block;
	width: 100%;
	max-width: 100%;
	margin-bottom: 0.5em;
	padding: 0;
	color: inherit;
	font-size: 1.5em;
	line-height: inherit;
	white-space: normal;
}
progress {
	vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}
[type='search'] {
	outline-offset: -2px;
	-webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}
::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}
output {
	display: inline-block;
}
summary {
	display: list-item;
}
template {
	display: none;
}
[hidden] {
	display: none !important;
}
mark {
	padding: 0.2em;
	background-color: #feffe6;
}
::selection {
	color: #fff;
	background: #1b59a8;
}
.clearfix::before {
	display: table;
	content: '';
}
.clearfix::after {
	display: table;
	clear: both;
	content: '';
}
.anticon {
	display: inline-flex;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.anticon > * {
	line-height: 1;
}
.anticon svg {
	display: inline-block;
}
.anticon::before {
	display: none;
}
.anticon .anticon-icon {
	display: block;
}
.anticon[tabindex] {
	cursor: pointer;
}
.anticon-spin::before {
	display: inline-block;
	animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
	display: inline-block;
	animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-fade-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
	animation-name: antFadeIn;
	animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
	animation-name: antFadeOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
	opacity: 0;
	animation-timing-function: linear;
}
.ant-fade-leave {
	animation-timing-function: linear;
}
@keyframes antFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes antFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.ant-move-up-enter,
.ant-move-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
	animation-name: antMoveUpIn;
	animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
	animation-name: antMoveUpOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
	animation-name: antMoveDownIn;
	animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
	animation-name: antMoveDownOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
	animation-name: antMoveLeftIn;
	animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
	animation-name: antMoveLeftOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
	animation-name: antMoveRightIn;
	animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
	animation-name: antMoveRightOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
	0% {
		transform: translateY(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveDownOut {
	0% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateY(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes antMoveLeftIn {
	0% {
		transform: translateX(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveLeftOut {
	0% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateX(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes antMoveRightIn {
	0% {
		transform: translateX(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveRightOut {
	0% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateX(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes antMoveUpIn {
	0% {
		transform: translateY(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveUpOut {
	0% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateY(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes loadingCircle {
	100% {
		transform: rotate(360deg);
	}
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
	position: relative;
}
html {
	--antd-wave-shadow-color: #1b59a8;
	--scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	border-radius: inherit;
	box-shadow: 0 0 0 0 #1b59a8;
	box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
	opacity: 0.2;
	animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-fill-mode: forwards;
	content: '';
	pointer-events: none;
}
@keyframes waveEffect {
	100% {
		box-shadow: 0 0 0 #1b59a8;
		box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
	}
}
@keyframes fadeEffect {
	100% {
		opacity: 0;
	}
}
.ant-slide-up-enter,
.ant-slide-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
	animation-name: antSlideUpIn;
	animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
	animation-name: antSlideUpOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
	animation-name: antSlideDownIn;
	animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
	animation-name: antSlideDownOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
	animation-name: antSlideLeftIn;
	animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
	animation-name: antSlideLeftOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
	animation-name: antSlideRightIn;
	animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
	animation-name: antSlideRightOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@keyframes antSlideUpOut {
	0% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@keyframes antSlideDownIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
}
@keyframes antSlideDownOut {
	0% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
}
@keyframes antSlideLeftIn {
	0% {
		transform: scaleX(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleX(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@keyframes antSlideLeftOut {
	0% {
		transform: scaleX(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleX(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@keyframes antSlideRightIn {
	0% {
		transform: scaleX(0.8);
		transform-origin: 100% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleX(1);
		transform-origin: 100% 0%;
		opacity: 1;
	}
}
@keyframes antSlideRightOut {
	0% {
		transform: scaleX(1);
		transform-origin: 100% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleX(0.8);
		transform-origin: 100% 0%;
		opacity: 0;
	}
}
.ant-zoom-enter,
.ant-zoom-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
	animation-name: antZoomIn;
	animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
	animation-name: antZoomOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
	transform: none;
}
.ant-zoom-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-big-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
	animation-name: antZoomBigIn;
	animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
	animation-name: antZoomBigOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
	transform: none;
}
.ant-zoom-big-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
	animation-duration: 0.1s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
	animation-duration: 0.1s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
	animation-name: antZoomBigIn;
	animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
	animation-name: antZoomBigOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
	transform: none;
}
.ant-zoom-big-fast-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
	animation-name: antZoomUpIn;
	animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
	animation-name: antZoomUpOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
	transform: none;
}
.ant-zoom-up-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
	animation-name: antZoomDownIn;
	animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
	animation-name: antZoomDownOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
	transform: none;
}
.ant-zoom-down-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
	animation-name: antZoomLeftIn;
	animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
	animation-name: antZoomLeftOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
	transform: none;
}
.ant-zoom-left-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
	animation-name: antZoomRightIn;
	animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
	animation-name: antZoomRightOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
	transform: none;
}
.ant-zoom-right-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes antZoomOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.2);
		opacity: 0;
	}
}
@keyframes antZoomBigIn {
	0% {
		transform: scale(0.8);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes antZoomBigOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
		opacity: 0;
	}
}
@keyframes antZoomUpIn {
	0% {
		transform: scale(0.8);
		transform-origin: 50% 0%;
		opacity: 0;
	}
	100% {
		transform: scale(1);
		transform-origin: 50% 0%;
	}
}
@keyframes antZoomUpOut {
	0% {
		transform: scale(1);
		transform-origin: 50% 0%;
	}
	100% {
		transform: scale(0.8);
		transform-origin: 50% 0%;
		opacity: 0;
	}
}
@keyframes antZoomLeftIn {
	0% {
		transform: scale(0.8);
		transform-origin: 0% 50%;
		opacity: 0;
	}
	100% {
		transform: scale(1);
		transform-origin: 0% 50%;
	}
}
@keyframes antZoomLeftOut {
	0% {
		transform: scale(1);
		transform-origin: 0% 50%;
	}
	100% {
		transform: scale(0.8);
		transform-origin: 0% 50%;
		opacity: 0;
	}
}
@keyframes antZoomRightIn {
	0% {
		transform: scale(0.8);
		transform-origin: 100% 50%;
		opacity: 0;
	}
	100% {
		transform: scale(1);
		transform-origin: 100% 50%;
	}
}
@keyframes antZoomRightOut {
	0% {
		transform: scale(1);
		transform-origin: 100% 50%;
	}
	100% {
		transform: scale(0.8);
		transform-origin: 100% 50%;
		opacity: 0;
	}
}
@keyframes antZoomDownIn {
	0% {
		transform: scale(0.8);
		transform-origin: 50% 100%;
		opacity: 0;
	}
	100% {
		transform: scale(1);
		transform-origin: 50% 100%;
	}
}
@keyframes antZoomDownOut {
	0% {
		transform: scale(1);
		transform-origin: 50% 100%;
	}
	100% {
		transform: scale(0.8);
		transform-origin: 50% 100%;
		opacity: 0;
	}
}
.ant-motion-collapse-legacy {
	overflow: hidden;
}
.ant-motion-collapse-legacy-active {
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
	overflow: hidden;
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
	position: fixed;
	z-index: 10;
}
.ant-alert {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: flex;
	align-items: center;
	padding: 8px 15px;
	word-wrap: break-word;
	border-radius: 2px;
}
.ant-alert-content {
	flex: 1;
	min-width: 0;
}
.ant-alert-icon {
	margin-right: 8px;
}
.ant-alert-description {
	display: none;
	font-size: 14px;
	line-height: 22px;
}
.ant-alert-success {
	background-color: #f6ffed;
	border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
	color: #52c41a;
}
.ant-alert-info {
	background-color: #fff4e6;
	border: 1px solid #ffbd7a;
}
.ant-alert-info .ant-alert-icon {
	color: #1b59a8;
}
.ant-alert-warning {
	background-color: #fffbe6;
	border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
	color: #faad14;
}
.ant-alert-error {
	background-color: #fff1f0;
	border: 1px solid #ffa39e;
}
.ant-alert-error .ant-alert-icon {
	color: #f5222d;
}
.ant-alert-error .ant-alert-description > pre {
	margin: 0;
	padding: 0;
}
.ant-alert-action {
	margin-left: 8px;
}
.ant-alert-close-icon {
	margin-left: 8px;
	padding: 0;
	overflow: hidden;
	font-size: 12px;
	line-height: 12px;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
	color: rgba(0, 0, 0, 0.45);
	transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
	color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
	color: rgba(0, 0, 0, 0.45);
	transition: color 0.3s;
}
.ant-alert-close-text:hover {
	color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
	align-items: flex-start;
	padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
	padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
	margin-right: 15px;
	font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
	display: block;
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
}
.ant-alert-message {
	color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
	display: block;
}
.ant-alert.ant-alert-motion-leave {
	overflow: hidden;
	opacity: 1;
	transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
	max-height: 0;
	margin-bottom: 0 !important;
	padding-top: 0;
	padding-bottom: 0;
	opacity: 0;
}
.ant-alert-banner {
	margin-bottom: 0;
	border: 0;
	border-radius: 0;
}
.ant-alert.ant-alert-rtl {
	direction: rtl;
}
.ant-alert-rtl.ant-alert.ant-alert-no-icon {
	padding: 8px 15px;
}
.ant-alert-rtl .ant-alert-icon {
	margin-right: auto;
	margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
	margin-right: 8px;
	margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
	margin-right: 8px;
	margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
	margin-right: auto;
	margin-left: 15px;
}
.ant-anchor {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	padding-left: 2px;
}
.ant-anchor-wrapper {
	margin-left: -4px;
	padding-left: 4px;
	overflow: auto;
	background-color: transparent;
}
.ant-anchor-ink {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
}
.ant-anchor-ink::before {
	position: relative;
	display: block;
	width: 2px;
	height: 100%;
	margin: 0 auto;
	background-color: #f0f0f0;
	content: ' ';
}
.ant-anchor-ink-ball {
	position: absolute;
	left: 50%;
	display: none;
	width: 8px;
	height: 8px;
	background-color: #fff;
	border: 2px solid #1b59a8;
	border-radius: 8px;
	transform: translateX(-50%);
	transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
	display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
	display: none;
}
.ant-anchor-link {
	padding: 7px 0 7px 16px;
	line-height: 1.143;
}
.ant-anchor-link-title {
	position: relative;
	display: block;
	margin-bottom: 6px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
	margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
	color: #1b59a8;
}
.ant-anchor-link .ant-anchor-link {
	padding-top: 5px;
	padding-bottom: 5px;
}
.ant-anchor-rtl {
	direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
	margin-right: -4px;
	margin-left: 0;
	padding-right: 4px;
	padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
	right: 0;
	left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
	right: 50%;
	left: 0;
	transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
	padding: 7px 16px 7px 0;
}
.ant-select-auto-complete {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
}
.ant-select-auto-complete .ant-select-clear {
	right: 13px;
}
.ant-avatar {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	overflow: hidden;
	color: #fff;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	background: #ccc;
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 50%;
}
.ant-avatar-image {
	background: transparent;
}
.ant-avatar .ant-image-img {
	display: block;
}
.ant-avatar-string {
	position: absolute;
	left: 50%;
	transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
	font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
	margin: 0;
}
.ant-avatar-lg {
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
}
.ant-avatar-lg-string {
	position: absolute;
	left: 50%;
	transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
	font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
	margin: 0;
}
.ant-avatar-sm {
	width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 50%;
}
.ant-avatar-sm-string {
	position: absolute;
	left: 50%;
	transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
	font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
	margin: 0;
}
.ant-avatar-square {
	border-radius: 2px;
}
.ant-avatar > img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.ant-avatar-group {
	display: inline-flex;
}
.ant-avatar-group .ant-avatar {
	border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
	margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
	margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
	margin-right: -8px;
	margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
	margin-right: 3px;
	margin-left: 0;
}
.ant-back-top {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: fixed;
	right: 100px;
	bottom: 50px;
	z-index: 10;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.ant-back-top:empty {
	display: none;
}
.ant-back-top-rtl {
	right: auto;
	left: 100px;
	direction: rtl;
}
.ant-back-top-content {
	width: 40px;
	height: 40px;
	overflow: hidden;
	color: #fff;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.45);
	border-radius: 20px;
	transition: all 0.3s;
}
.ant-back-top-content:hover {
	background-color: rgba(0, 0, 0, 0.65);
	transition: all 0.3s;
}
.ant-back-top-icon {
	font-size: 24px;
	line-height: 40px;
}
@media screen and (max-width: 768px) {
	.ant-back-top {
		right: 60px;
	}
}
@media screen and (max-width: 480px) {
	.ant-back-top {
		right: 20px;
	}
}
.ant-badge {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	line-height: 1;
}
.ant-badge-count {
	z-index: auto;
	min-width: 20px;
	height: 20px;
	padding: 0 6px;
	color: #fff;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	text-align: center;
	background: #ff4d4f;
	border-radius: 10px;
	box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
	color: #fff;
}
.ant-badge-count-sm {
	min-width: 14px;
	height: 14px;
	padding: 0;
	font-size: 12px;
	line-height: 14px;
	border-radius: 7px;
}
.ant-badge-multiple-words {
	padding: 0 8px;
}
.ant-badge-dot {
	z-index: auto;
	width: 6px;
	min-width: 6px;
	height: 6px;
	background: #ff4d4f;
	border-radius: 100%;
	box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
	transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
	transform-origin: 100% 0%;
}
.ant-badge-count.anticon-spin,
.ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
	animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
	line-height: inherit;
	vertical-align: baseline;
}
.ant-badge-status-dot {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 6px;
	height: 6px;
	vertical-align: middle;
	border-radius: 50%;
}
.ant-badge-status-success {
	background-color: #52c41a;
}
.ant-badge-status-processing {
	position: relative;
	background-color: #1890ff;
}
.ant-badge-status-processing::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #1890ff;
	border-radius: 50%;
	animation: antStatusProcessing 1.2s infinite ease-in-out;
	content: '';
}
.ant-badge-status-default {
	background-color: #d9d9d9;
}
.ant-badge-status-error {
	background-color: #f5222d;
}
.ant-badge-status-warning {
	background-color: #faad14;
}
.ant-badge-status-pink {
	background: #eb2f96;
}
.ant-badge-status-magenta {
	background: #eb2f96;
}
.ant-badge-status-red {
	background: #f5222d;
}
.ant-badge-status-volcano {
	background: #fa541c;
}
.ant-badge-status-orange {
	background: #fa8c16;
}
.ant-badge-status-yellow {
	background: #fadb14;
}
.ant-badge-status-gold {
	background: #faad14;
}
.ant-badge-status-cyan {
	background: #13c2c2;
}
.ant-badge-status-lime {
	background: #a0d911;
}
.ant-badge-status-green {
	background: #52c41a;
}
.ant-badge-status-blue {
	background: #1890ff;
}
.ant-badge-status-geekblue {
	background: #2f54eb;
}
.ant-badge-status-purple {
	background: #722ed1;
}
.ant-badge-status-text {
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
	animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	animation-fill-mode: both;
}
.ant-badge-zoom-leave {
	animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
	animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
	animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
	animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
	vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
	transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
	position: relative;
	top: auto;
	display: block;
	transform-origin: 50% 50%;
}
.ant-badge-not-a-wrapper .ant-badge-count {
	transform: none;
}
@keyframes antStatusProcessing {
	0% {
		transform: scale(0.8);
		opacity: 0.5;
	}
	100% {
		transform: scale(2.4);
		opacity: 0;
	}
}
.ant-scroll-number {
	overflow: hidden;
}
.ant-scroll-number-only {
	position: relative;
	display: inline-block;
	height: 20px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
	height: 20px;
	margin: 0;
	-webkit-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
}
.ant-scroll-number-symbol {
	vertical-align: top;
}
@keyframes antZoomBadgeIn {
	0% {
		transform: scale(0) translate(50%, -50%);
		opacity: 0;
	}
	100% {
		transform: scale(1) translate(50%, -50%);
	}
}
@keyframes antZoomBadgeOut {
	0% {
		transform: scale(1) translate(50%, -50%);
	}
	100% {
		transform: scale(0) translate(50%, -50%);
		opacity: 0;
	}
}
@keyframes antNoWrapperZoomBadgeIn {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
	}
}
@keyframes antNoWrapperZoomBadgeOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
		opacity: 0;
	}
}
@keyframes antBadgeLoadingCircle {
	0% {
		transform-origin: 50%;
	}
	100% {
		transform: translate(50%, -50%) rotate(360deg);
		transform-origin: 50%;
	}
}
.ant-ribbon-wrapper {
	position: relative;
}
.ant-ribbon {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: 8px;
	height: 22px;
	padding: 0 8px;
	color: #fff;
	line-height: 22px;
	white-space: nowrap;
	background-color: #1b59a8;
	border-radius: 2px;
}
.ant-ribbon-text {
	color: #fff;
}
.ant-ribbon-corner {
	position: absolute;
	top: 100%;
	width: 8px;
	height: 8px;
	color: currentColor;
	border: 4px solid;
	transform: scaleY(0.75);
	transform-origin: top;
}
.ant-ribbon-corner::after {
	position: absolute;
	top: -4px;
	left: -4px;
	width: inherit;
	height: inherit;
	color: rgba(0, 0, 0, 0.25);
	border: inherit;
	content: '';
}
.ant-ribbon-color-pink {
	color: #eb2f96;
	background: #eb2f96;
}
.ant-ribbon-color-magenta {
	color: #eb2f96;
	background: #eb2f96;
}
.ant-ribbon-color-red {
	color: #f5222d;
	background: #f5222d;
}
.ant-ribbon-color-volcano {
	color: #fa541c;
	background: #fa541c;
}
.ant-ribbon-color-orange {
	color: #fa8c16;
	background: #fa8c16;
}
.ant-ribbon-color-yellow {
	color: #fadb14;
	background: #fadb14;
}
.ant-ribbon-color-gold {
	color: #faad14;
	background: #faad14;
}
.ant-ribbon-color-cyan {
	color: #13c2c2;
	background: #13c2c2;
}
.ant-ribbon-color-lime {
	color: #a0d911;
	background: #a0d911;
}
.ant-ribbon-color-green {
	color: #52c41a;
	background: #52c41a;
}
.ant-ribbon-color-blue {
	color: #1890ff;
	background: #1890ff;
}
.ant-ribbon-color-geekblue {
	color: #2f54eb;
	background: #2f54eb;
}
.ant-ribbon-color-purple {
	color: #722ed1;
	background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
	right: -8px;
	border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
	right: 0;
	border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-start {
	left: -8px;
	border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
	left: 0;
	border-color: currentColor currentColor transparent transparent;
}
.ant-badge-rtl {
	direction: rtl;
}
.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot,
.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
	right: auto;
	left: 0;
	direction: ltr;
	transform: translate(-50%, -50%);
	transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
	right: auto;
	left: 0;
	transform: translate(-50%, -50%);
	transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
	margin-right: 8px;
	margin-left: 0;
}
.ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge-rtl .ant-badge-zoom-enter {
	animation-name: antZoomBadgeInRtl;
}
.ant-badge-rtl .ant-badge-zoom-leave {
	animation-name: antZoomBadgeOutRtl;
}
.ant-badge-not-a-wrapper .ant-badge-count {
	transform: none;
}
.ant-ribbon-rtl {
	direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
	right: unset;
	left: -8px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
	right: unset;
	left: 0;
	border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
	border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
	right: -8px;
	left: unset;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 5px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
	right: 0;
	left: unset;
	border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
	border-color: currentColor transparent transparent currentColor;
}
@keyframes antZoomBadgeInRtl {
	0% {
		transform: scale(0) translate(-50%, -50%);
		opacity: 0;
	}
	100% {
		transform: scale(1) translate(-50%, -50%);
	}
}
@keyframes antZoomBadgeOutRtl {
	0% {
		transform: scale(1) translate(-50%, -50%);
	}
	100% {
		transform: scale(0) translate(-50%, -50%);
		opacity: 0;
	}
}
.ant-breadcrumb {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}
.ant-breadcrumb .anticon {
	font-size: 14px;
}
.ant-breadcrumb a {
	color: rgba(0, 0, 0, 0.45);
	transition: color 0.3s;
}
.ant-breadcrumb a:hover {
	color: #1b59a8;
}
.ant-breadcrumb > span:last-child {
	color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child a {
	color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
	display: none;
}
.ant-breadcrumb-separator {
	margin: 0 8px;
	color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
	margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
	margin-left: 4px;
}
.ant-breadcrumb-rtl {
	direction: rtl;
}
.ant-breadcrumb-rtl::before {
	display: table;
	content: '';
}
.ant-breadcrumb-rtl::after {
	display: table;
	clear: both;
	content: '';
}
.ant-breadcrumb-rtl::before {
	display: table;
	content: '';
}
.ant-breadcrumb-rtl::after {
	display: table;
	clear: both;
	content: '';
}
.ant-breadcrumb-rtl > span {
	float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
	margin-right: 4px;
	margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
	margin-right: 4px;
	margin-left: 0;
}
.ant-btn {
	line-height: 1.5715;
	position: relative;
	display: inline-block;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	background-image: none;
	border: 1px solid transparent;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	user-select: none;
	touch-action: manipulation;
	height: 32px;
	padding: 4px 15px;
	font-size: 14px;
	border-radius: 2px;
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
	border-color: #d9d9d9;
}
.ant-btn > .anticon {
	line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
	outline: 0;
}
.ant-btn:not([disabled]):hover {
	text-decoration: none;
}
.ant-btn:not([disabled]):active {
	outline: 0;
	box-shadow: none;
}
.ant-btn[disabled] {
	cursor: not-allowed;
}
.ant-btn[disabled] > * {
	pointer-events: none;
}
.ant-btn-lg {
	height: 40px;
	padding: 6.4px 15px;
	font-size: 16px;
	border-radius: 2px;
}
.ant-btn-sm {
	height: 24px;
	padding: 0px 7px;
	font-size: 14px;
	border-radius: 2px;
}
.ant-btn > a:only-child {
	color: currentColor;
}
.ant-btn > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn:hover,
.ant-btn:focus {
	color: #1b59a8;
	background: #fff;
	border-color: #1b59a8;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
	color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn:active {
	color: #d94f00;
	background: #fff;
	border-color: #d94f00;
}
.ant-btn:active > a:only-child {
	color: currentColor;
}
.ant-btn:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
	text-decoration: none;
	background: #fff;
}
.ant-btn > span {
	display: inline-block;
}
.ant-btn-primary {
	color: #fff;
	background: #1b59a8;
	border-color: #1b59a8;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
	color: currentColor;
}
.ant-btn-primary > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
	color: #fff;
	background: #1b59a8;
	border-color: #1b59a8;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
	color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-primary:active {
	color: #fff;
	background: #d94f00;
	border-color: #d94f00;
}
.ant-btn-primary:active > a:only-child {
	color: currentColor;
}
.ant-btn-primary:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
	border-right-color: #1b59a8;
	border-left-color: #1b59a8;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
	border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
	border-right-color: #1b59a8;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
	border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
	border-left-color: #1b59a8;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
	border-left-color: #d9d9d9;
}
.ant-btn-ghost {
	color: rgba(0, 0, 0, 0.65);
	background: transparent;
	border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
	color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
	color: #1b59a8;
	background: transparent;
	border-color: #1b59a8;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
	color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-ghost:active {
	color: #d94f00;
	background: transparent;
	border-color: #d94f00;
}
.ant-btn-ghost:active > a:only-child {
	color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dashed {
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
	border-color: #d9d9d9;
	border-style: dashed;
}
.ant-btn-dashed > a:only-child {
	color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
	color: #1b59a8;
	background: #fff;
	border-color: #1b59a8;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
	color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dashed:active {
	color: #d94f00;
	background: #fff;
	border-color: #d94f00;
}
.ant-btn-dashed:active > a:only-child {
	color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-danger {
	color: #fff;
	background: #f5222d;
	border-color: #f5222d;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
	color: currentColor;
}
.ant-btn-danger > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
	color: #fff;
	background: #ff4d4f;
	border-color: #ff4d4f;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
	color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-danger:active {
	color: #fff;
	background: #cf1322;
	border-color: #cf1322;
}
.ant-btn-danger:active > a:only-child {
	color: currentColor;
}
.ant-btn-danger:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-link {
	color: #5c987a;
	background: transparent;
	border-color: transparent;
	box-shadow: none;
}
.ant-btn-link > a:only-child {
	color: currentColor;
}
.ant-btn-link > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
	color: #1b59a8;
	background: transparent;
	border-color: #1b59a8;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
	color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-link:active {
	color: #d94f00;
	background: transparent;
	border-color: #d94f00;
}
.ant-btn-link:active > a:only-child {
	color: currentColor;
}
.ant-btn-link:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-link:hover {
	background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
	border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border-color: transparent;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-text {
	color: rgba(0, 0, 0, 0.65);
	background: transparent;
	border-color: transparent;
	box-shadow: none;
}
.ant-btn-text > a:only-child {
	color: currentColor;
}
.ant-btn-text > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
	color: #1b59a8;
	background: transparent;
	border-color: #1b59a8;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
	color: currentColor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-text:active {
	color: #d94f00;
	background: transparent;
	border-color: #d94f00;
}
.ant-btn-text:active > a:only-child {
	color: currentColor;
}
.ant-btn-text:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
	color: rgba(0, 0, 0, 0.65);
	background: rgba(0, 0, 0, 0.018);
	border-color: transparent;
}
.ant-btn-text:active {
	color: rgba(0, 0, 0, 0.65);
	background: rgba(0, 0, 0, 0.028);
	border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border-color: transparent;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous {
	color: #f5222d;
	background: #fff;
	border-color: #f5222d;
}
.ant-btn-dangerous > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
	color: #ff4d4f;
	background: #fff;
	border-color: #ff4d4f;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous:active {
	color: #cf1322;
	background: #fff;
	border-color: #cf1322;
}
.ant-btn-dangerous:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-primary {
	color: #fff;
	background: #f5222d;
	border-color: #f5222d;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
	color: #fff;
	background: #ff4d4f;
	border-color: #ff4d4f;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
	color: #fff;
	background: #cf1322;
	border-color: #cf1322;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-link {
	color: #f5222d;
	background: transparent;
	border-color: transparent;
	box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
	color: #1b59a8;
	background: transparent;
	border-color: #1b59a8;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
	color: #d94f00;
	background: transparent;
	border-color: #d94f00;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
	color: #ff4d4f;
	background: transparent;
	border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
	color: #cf1322;
	background: transparent;
	border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border-color: transparent;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-text {
	color: #f5222d;
	background: transparent;
	border-color: transparent;
	box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
	color: #1b59a8;
	background: transparent;
	border-color: #1b59a8;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
	color: #d94f00;
	background: transparent;
	border-color: #d94f00;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
	color: #ff4d4f;
	background: rgba(0, 0, 0, 0.018);
	border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
	color: #cf1322;
	background: rgba(0, 0, 0, 0.028);
	border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border-color: transparent;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-icon-only {
	width: 32px;
	height: 32px;
	padding: 2.4px 0;
	font-size: 16px;
	border-radius: 2px;
	vertical-align: -1px;
}
.ant-btn-icon-only > * {
	font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
	width: 40px;
	height: 40px;
	padding: 4.9px 0;
	font-size: 18px;
	border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
	font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
	width: 24px;
	height: 24px;
	padding: 0px 0;
	font-size: 14px;
	border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
	font-size: 14px;
}
.ant-btn-round {
	height: 32px;
	padding: 4px 16px;
	font-size: 14px;
	border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
	height: 40px;
	padding: 6.4px 20px;
	font-size: 16px;
	border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
	height: 24px;
	padding: 0px 12px;
	font-size: 14px;
	border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
	width: auto;
}
.ant-btn-circle {
	min-width: 32px;
	padding-right: 0;
	padding-left: 0;
	text-align: center;
	border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
	min-width: 40px;
	border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
	min-width: 24px;
	border-radius: 50%;
}
.ant-btn::before {
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	z-index: 1;
	display: none;
	background: #fff;
	border-radius: inherit;
	opacity: 0.35;
	transition: opacity 0.2s;
	content: '';
	pointer-events: none;
}
.ant-btn .anticon {
	transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
	shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
	position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
	pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
	display: block;
}
.ant-btn > .ant-btn-loading-icon {
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
	padding-right: 8px;
	animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
	animation: loadingCircle 1s infinite linear;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
	padding-right: 0;
}
.ant-btn-group {
	position: relative;
	display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
	position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
	z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
	z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
	font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
	height: 40px;
	padding: 6.4px 15px;
	font-size: 16px;
	border-radius: 0;
}
.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
	width: 40px;
	height: 40px;
	padding-right: 0;
	padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
	height: 24px;
	padding: 0px 7px;
	font-size: 14px;
	border-radius: 0;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
	font-size: 14px;
}
.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
	width: 24px;
	height: 24px;
	padding-right: 0;
	padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
	margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
	border-left-color: transparent;
}
.ant-btn-group .ant-btn {
	border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
	margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
	border-radius: 2px;
}
.ant-btn-group > span:only-child > .ant-btn {
	border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.ant-btn-group-sm > .ant-btn:only-child {
	border-radius: 2px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
	border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.ant-btn-group > .ant-btn-group {
	float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
	border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
	padding-right: 8px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
	padding-left: 8px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
	margin-right: -1px;
	margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
	direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
	border-top-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
	border-top-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 5px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
	border-top-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
	border-top-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 5px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
	position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
	margin-left: 8px;
}
.ant-btn-background-ghost {
	color: #fff;
	background: transparent !important;
	border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
	color: #1b59a8;
	background: transparent;
	border-color: #1b59a8;
	text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
	color: #1b59a8;
	background: transparent;
	border-color: #1b59a8;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
	color: #d94f00;
	background: transparent;
	border-color: #d94f00;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
	color: #f5222d;
	background: transparent;
	border-color: #f5222d;
	text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
	color: #ff4d4f;
	background: transparent;
	border-color: #ff4d4f;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
	color: #cf1322;
	background: transparent;
	border-color: #cf1322;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
	color: #f5222d;
	background: transparent;
	border-color: #f5222d;
	text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
	color: #ff4d4f;
	background: transparent;
	border-color: #ff4d4f;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
	color: #cf1322;
	background: transparent;
	border-color: #cf1322;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
	color: #f5222d;
	background: transparent;
	border-color: transparent;
	text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
	color: #ff4d4f;
	background: transparent;
	border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
	color: #cf1322;
	background: transparent;
	border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
	color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: '';
}
.ant-btn-two-chinese-chars::first-letter {
	letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
	margin-right: -0.34em;
	letter-spacing: 0.34em;
}
.ant-btn-block {
	width: 100%;
}
.ant-btn:empty {
	display: inline-block;
	width: 0;
	visibility: hidden;
	content: '\a0';
}
a.ant-btn {
	padding-top: 0.01px !important;
	line-height: 30px;
}
a.ant-btn-lg {
	line-height: 38px;
}
a.ant-btn-sm {
	line-height: 22px;
}
.ant-btn-rtl {
	direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
	border-right-color: #1b59a8;
	border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
	border-right-color: #d9d9d9;
	border-left-color: #1b59a8;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
	padding-right: 0;
	padding-left: 8px;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
	padding-right: 0;
	padding-left: 0;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
	margin-right: 8px;
	margin-left: 0;
}
.ant-picker-calendar {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	background: #fff;
}
.ant-picker-calendar-header {
	display: flex;
	justify-content: flex-end;
	padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
	min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
	min-width: 70px;
	margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
	margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
	background: #fff;
	border: 0;
	border-top: 1px solid #f0f0f0;
	border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
	width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
	padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
	width: 100%;
}
.ant-picker-calendar-mini {
	border-radius: 2px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
	border-radius: 0 0 5px 5px;
}
.ant-picker-calendar-mini .ant-picker-content {
	height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
	height: auto;
	padding: 0;
	line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel {
	display: block;
	width: 100%;
	text-align: right;
	background: #fff;
	border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
	padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
	height: auto;
	padding: 0 12px 5px 0;
	line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
	display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
	background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
	display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
	background: #fff4e6;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
	color: #1b59a8;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
	display: block;
	width: auto;
	height: auto;
	margin: 0 4px;
	padding: 4px 8px 0;
	border: 0;
	border-top: 2px solid #f0f0f0;
	border-radius: 0;
	transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
	line-height: 24px;
	transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
	position: static;
	width: auto;
	height: 86px;
	overflow-y: auto;
	color: rgba(0, 0, 0, 0.65);
	line-height: 1.5715;
	text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
	border-color: #1b59a8;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
	color: rgba(0, 0, 0, 0.65);
}
@media only screen and (max-width: 480px) {
	.ant-picker-calendar-header {
		display: block;
	}
	.ant-picker-calendar-header .ant-picker-calendar-year-select {
		width: 50%;
	}
	.ant-picker-calendar-header .ant-picker-calendar-month-select {
		width: calc(50% - 8px);
	}
	.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
		width: 100%;
		margin-top: 8px;
		margin-left: 0;
	}
	.ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
		width: 50%;
		text-align: center;
	}
}
.ant-picker-calendar-rtl {
	direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
	margin-right: 8px;
	margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
	margin-right: 8px;
	margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
	text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
	padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
	text-align: right;
}
.ant-card {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	background: #fff;
	border-radius: 2px;
}
.ant-card-rtl {
	direction: rtl;
}
.ant-card-hoverable {
	cursor: pointer;
	transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
	border-color: transparent;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
	border: 1px solid #f0f0f0;
}
.ant-card-head {
	min-height: 48px;
	margin-bottom: -1px;
	padding: 0 24px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	background: transparent;
	border-bottom: 1px solid #f0f0f0;
	border-radius: 5px 5px 0 0;
}
.ant-card-head::before {
	display: table;
	content: '';
}
.ant-card-head::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-head::before {
	display: table;
	content: '';
}
.ant-card-head::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-head-wrapper {
	display: flex;
	align-items: center;
}
.ant-card-head-title {
	display: inline-block;
	flex: 1;
	padding: 16px 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
	left: 0;
	margin-top: 0;
	margin-bottom: 0;
}
.ant-card-head .ant-tabs {
	clear: both;
	margin-bottom: -17px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
	border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
	float: right;
	margin-left: auto;
	padding: 16px 0;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
	margin-right: auto;
	margin-left: 0;
}
.ant-card-body {
	padding: 24px;
}
.ant-card-body::before {
	display: table;
	content: '';
}
.ant-card-body::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-body::before {
	display: table;
	content: '';
}
.ant-card-body::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
	margin: -1px 0 0 -1px;
	padding: 0;
}
.ant-card-grid {
	float: left;
	width: 33.33%;
	padding: 24px;
	border: 0;
	border-radius: 0;
	box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
	transition: all 0.3s;
}
.ant-card-rtl .ant-card-grid {
	float: right;
}
.ant-card-grid-hoverable:hover {
	position: relative;
	z-index: 1;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
	min-height: 32px;
	padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
	padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
	margin-top: -1px;
	margin-right: -1px;
	margin-left: -1px;
}
.ant-card-cover > * {
	display: block;
	width: 100%;
}
.ant-card-cover img {
	border-radius: 5px 5px 0 0;
}
.ant-card-actions {
	margin: 0;
	padding: 0;
	list-style: none;
	background: #fff;
	border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
	display: table;
	content: '';
}
.ant-card-actions::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-actions::before {
	display: table;
	content: '';
}
.ant-card-actions::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-actions > li {
	float: left;
	margin: 12px 0;
	color: rgba(0, 0, 0, 0.45);
	text-align: center;
}
.ant-card-rtl .ant-card-actions > li {
	float: right;
}
.ant-card-actions > li > span {
	position: relative;
	display: block;
	min-width: 32px;
	font-size: 14px;
	line-height: 1.5715;
	cursor: pointer;
}
.ant-card-actions > li > span:hover {
	color: #1b59a8;
	transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
	display: inline-block;
	width: 100%;
	color: rgba(0, 0, 0, 0.45);
	line-height: 22px;
	transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
	color: #1b59a8;
}
.ant-card-actions > li > span > .anticon {
	font-size: 16px;
	line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
	border-right: 1px solid #f0f0f0;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
	border-right: none;
	border-left: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
	padding: 0 24px;
	background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
	padding: 12px 0;
	font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
	padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
	padding: 13.5px 0;
}
.ant-card-meta {
	margin: -4px 0;
}
.ant-card-meta::before {
	display: table;
	content: '';
}
.ant-card-meta::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-meta::before {
	display: table;
	content: '';
}
.ant-card-meta::after {
	display: table;
	clear: both;
	content: '';
}
.ant-card-meta-avatar {
	float: left;
	padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
	float: right;
	padding-right: 0;
	padding-left: 16px;
}
.ant-card-meta-detail {
	overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
	margin-bottom: 8px;
}
.ant-card-meta-title {
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-card-meta-description {
	color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
	overflow: hidden;
}
.ant-card-loading .ant-card-body {
	user-select: none;
}
.ant-card-loading-content p {
	margin: 0;
}
.ant-card-loading-block {
	height: 14px;
	margin: 4px 0;
	background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
	background-size: 600% 600%;
	border-radius: 2px;
	animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
	0%,
	100% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
}
.ant-card-small > .ant-card-head {
	min-height: 36px;
	padding: 0 12px;
	font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
	padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
	padding: 8px 0;
	font-size: 14px;
}
.ant-card-small > .ant-card-body {
	padding: 12px;
}
.ant-carousel {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
.ant-carousel .slick-list:focus {
	outline: none;
}
.ant-carousel .slick-list.dragging {
	cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
	pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
	visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
	pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
	visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
	vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
	touch-action: pan-y;
}
.ant-carousel .slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
	display: table;
	content: '';
}
.ant-carousel .slick-track::after {
	clear: both;
}
.slick-loading .ant-carousel .slick-track {
	visibility: hidden;
}
.ant-carousel .slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}
.ant-carousel .slick-slide img {
	display: block;
}
.ant-carousel .slick-slide.slick-loading img {
	display: none;
}
.ant-carousel .slick-slide.dragging img {
	pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
	display: block;
}
.ant-carousel .slick-loading .slick-slide {
	visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
	display: block;
	height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
	display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	padding: 0;
	color: transparent;
	font-size: 0;
	line-height: 0;
	background: transparent;
	border: 0;
	outline: none;
	cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
	color: transparent;
	background: transparent;
	outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
	opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
	opacity: 0.25;
}
.ant-carousel .slick-prev {
	left: -25px;
}
.ant-carousel .slick-prev::before {
	content: '←';
}
.ant-carousel .slick-next {
	right: -25px;
}
.ant-carousel .slick-next::before {
	content: '→';
}
.ant-carousel .slick-dots {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: flex !important;
	justify-content: center;
	margin-right: 15%;
	margin-left: 15%;
	padding-left: 0;
	list-style: none;
}
.ant-carousel .slick-dots-bottom {
	bottom: 12px;
}
.ant-carousel .slick-dots-top {
	top: 12px;
	bottom: auto;
}
.ant-carousel .slick-dots li {
	position: relative;
	display: inline-block;
	flex: 0 1 auto;
	box-sizing: content-box;
	width: 16px;
	height: 3px;
	margin: 0 2px;
	margin-right: 3px;
	margin-left: 3px;
	padding: 0;
	text-align: center;
	text-indent: -999px;
	vertical-align: top;
	transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
	display: block;
	width: 100%;
	height: 3px;
	padding: 0;
	color: transparent;
	font-size: 0;
	background: #fff;
	border: 0;
	border-radius: 1px;
	outline: none;
	cursor: pointer;
	opacity: 0.3;
	transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
	opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
	width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
	background: #fff;
	opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
	opacity: 1;
}
.ant-carousel-vertical .slick-dots {
	top: 50%;
	bottom: auto;
	flex-direction: column;
	width: 3px;
	height: auto;
	margin: 0;
	transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
	right: auto;
	left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
	right: 12px;
	left: auto;
}
.ant-carousel-vertical .slick-dots li {
	width: 3px;
	height: 16px;
	margin: 4px 2px;
	vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
	width: 3px;
	height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
	width: 3px;
	height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
	width: 3px;
	height: 24px;
}
.ant-carousel-rtl {
	direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
	right: 0;
	left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
	right: -25px;
	left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
	content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
	right: auto;
	left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
	content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
	flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
	flex-direction: column;
}
.ant-cascader {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
}
.ant-cascader-input.ant-input {
	position: static;
	width: 100%;
	padding-right: 24px;
	background-color: transparent !important;
	cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
	position: relative;
}
.ant-cascader-picker {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	background-color: #fff;
	border-radius: 2px;
	outline: 0;
	cursor: pointer;
	transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
	color: transparent;
}
.ant-cascader-picker-disabled {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
	cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-cascader-picker-borderless .ant-cascader-input {
	border-color: transparent !important;
	box-shadow: none !important;
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
	color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 20px;
	margin-top: -10px;
	padding: 0 20px 0 12px;
	overflow: hidden;
	line-height: 20px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
	position: absolute;
	top: 50%;
	right: 12px;
	z-index: 2;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 12px;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
	opacity: 1;
}
.ant-cascader-picker-arrow {
	position: absolute;
	top: 50%;
	right: 12px;
	z-index: 1;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 12px;
}
.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl
	.ant-cascader-picker-label:hover
	+ .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
	right: 8px;
}
.ant-cascader-menus {
	position: absolute;
	z-index: 1050;
	font-size: 14px;
	white-space: nowrap;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
	margin: 0;
	list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
	display: none;
}
.ant-cascader-menus.ant-slide-up-enter.ant-slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.ant-slide-up-appear.ant-slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
	animation-name: antSlideUpIn;
}
.ant-cascader-menus.ant-slide-up-enter.ant-slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.ant-slide-up-appear.ant-slide-up-appear-active.ant-cascader-menus-placement-topLeft {
	animation-name: antSlideDownIn;
}
.ant-cascader-menus.ant-slide-up-leave.ant-slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
	animation-name: antSlideUpOut;
}
.ant-cascader-menus.ant-slide-up-leave.ant-slide-up-leave-active.ant-cascader-menus-placement-topLeft {
	animation-name: antSlideDownOut;
}
.ant-cascader-menu {
	display: inline-block;
	min-width: 111px;
	height: 180px;
	margin: 0;
	padding: 4px 0;
	overflow: auto;
	vertical-align: top;
	list-style: none;
	border-right: 1px solid #f0f0f0;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
	border-radius: 5px 0 0 5px;
}
.ant-cascader-menu:last-child {
	margin-right: -1px;
	border-right-color: transparent;
	border-radius: 0 5px 5px 0;
}
.ant-cascader-menu:only-child {
	border-radius: 2px;
}
.ant-cascader-menu-item {
	padding: 5px 12px;
	overflow: hidden;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
	background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
	background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
	color: rgba(0, 0, 0, 0.25);
	cursor: default;
	pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
	font-weight: 600;
	background-color: #fff4e6;
}
.ant-cascader-menu-item-expand {
	position: relative;
	padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
	position: absolute;
	right: 12px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
	color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
	color: #ff4d4f;
}
.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
	padding-right: 11px;
	padding-left: 24px;
	text-align: right;
}
.ant-cascader-picker-rtl {
	direction: rtl;
}
.ant-cascader-picker-rtl .ant-cascader-picker-label {
	padding: 0 12px 0 20px;
	text-align: right;
}
.ant-cascader-picker-rtl .ant-cascader-picker-clear {
	right: auto;
	left: 12px;
}
.ant-cascader-picker-rtl .ant-cascader-picker-arrow {
	right: auto;
	left: 12px;
}
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow {
	right: auto;
	left: 8px;
}
.ant-cascader-menu-rtl .ant-cascader-menu {
	direction: rtl;
	border-right: none;
	border-left: 1px solid #f0f0f0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
	border-radius: 0 5px 5px 0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
	margin-right: 0;
	margin-left: -1px;
	border-left-color: transparent;
	border-radius: 5px 0 0 5px;
}
.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
	border-radius: 2px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
	padding-right: 12px;
	padding-left: 24px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
	right: auto;
	left: 12px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
	transform: scaleY(-1);
}
@keyframes antCheckboxEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
.ant-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	top: 0.2em;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: #1b59a8;
}
.ant-checkbox-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #1b59a8;
	border-radius: 2px;
	visibility: hidden;
	animation: antCheckboxEffect 0.36s ease-in-out;
	animation-fill-mode: backwards;
	content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
	visibility: visible;
}
.ant-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	direction: ltr;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	transition: all 0.3s;
}
.ant-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 22%;
	display: table;
	width: 5.71428571px;
	height: 9.14285714px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	opacity: 0;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	content: ' ';
}
.ant-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #1b59a8;
	border-color: #1b59a8;
}
.ant-checkbox-disabled {
	cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
	cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}
.ant-checkbox-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
	visibility: hidden;
}
.ant-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-flex;
	align-items: baseline;
	line-height: unset;
	cursor: pointer;
}
.ant-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
	cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
	margin-left: 8px;
}
.ant-checkbox + span {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
}
.ant-checkbox-group-item {
	margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
	margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: #1b59a8;
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
	direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
	margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-left: 8px;
}
.ant-collapse {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-bottom: 0;
	border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
	border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
	border-radius: 0 0 5px 5px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	position: relative;
	padding: 12px 16px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 1.5715;
	cursor: pointer;
	transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
	display: table;
	content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
	display: table;
	clear: both;
	content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
	display: table;
	content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
	display: table;
	clear: both;
	content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	display: inline-block;
	margin-right: 12px;
	font-size: 12px;
	vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
	transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
	float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
	outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
	cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
	cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
	padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
	padding: 12px 16px;
	padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	position: absolute;
	top: 50%;
	right: 16px;
	left: auto;
	margin: 0;
	transform: translateY(-50%);
}
.ant-collapse-content {
	color: rgba(0, 0, 0, 0.65);
	background-color: #fff;
	border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
	padding: 16px;
}
.ant-collapse-content-hidden {
	display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
	border-radius: 0 0 5px 5px;
}
.ant-collapse-borderless {
	background-color: #fafafa;
	border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
	border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
	border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
	background-color: transparent;
	border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding-top: 4px;
}
.ant-collapse-ghost {
	background-color: transparent;
	border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
	border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
	background-color: transparent;
	border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding-top: 12px;
	padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-collapse-rtl {
	direction: rtl;
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	padding: 12px 16px;
	padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
	transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
	float: left;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
	padding-right: 12px;
	padding-left: 0;
}
.ant-comment {
	position: relative;
	background-color: inherit;
}
.ant-comment-inner {
	display: flex;
	padding: 16px 0;
}
.ant-comment-avatar {
	position: relative;
	flex-shrink: 0;
	margin-right: 12px;
	cursor: pointer;
}
.ant-comment-avatar img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}
.ant-comment-content {
	position: relative;
	flex: 1 1 auto;
	min-width: 1px;
	font-size: 14px;
	word-wrap: break-word;
}
.ant-comment-content-author {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-bottom: 4px;
	font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
	padding-right: 8px;
	font-size: 12px;
	line-height: 18px;
}
.ant-comment-content-author-name {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	transition: color 0.3s;
}
.ant-comment-content-author-name > * {
	color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
	color: #ccc;
	white-space: nowrap;
	cursor: auto;
}
.ant-comment-content-detail p {
	margin-bottom: inherit;
	white-space: pre-wrap;
}
.ant-comment-actions {
	margin-top: 12px;
	margin-bottom: inherit;
	padding-left: 0;
}
.ant-comment-actions > li {
	display: inline-block;
	color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
	margin-right: 10px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	cursor: pointer;
	transition: color 0.3s;
	user-select: none;
}
.ant-comment-actions > li > span:hover {
	color: #595959;
}
.ant-comment-nested {
	margin-left: 44px;
}
.ant-comment-rtl {
	direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
	margin-right: 0;
	margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
	padding-right: 0;
	padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
	padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
	margin-right: 0;
	margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
	margin-right: 44px;
	margin-left: 0;
}
.ant-picker {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	padding: 4px 11px 4px;
	position: relative;
	display: inline-flex;
	align-items: center;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-picker-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-picker-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
	color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}
.ant-picker-input {
	position: relative;
	display: inline-flex;
	align-items: center;
	width: 100%;
}
.ant-picker-input > input {
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	flex: auto;
	min-width: 1px;
	height: auto;
	padding: 0;
	background: transparent;
	border: 0;
}
.ant-picker-input > input::-moz-placeholder {
	opacity: 1;
}
.ant-picker-input > input::placeholder {
	color: #bfbfbf;
}
.ant-picker-input > input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker-input > input:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-picker-input > input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-picker-input > input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-picker-input > input.ant-picker-input > input-borderless,
.ant-picker-input > input.ant-picker-input > input-borderless:hover,
.ant-picker-input > input.ant-picker-input > input-borderless:focus,
.ant-picker-input > input.ant-picker-input > input-borderless-focused,
.ant-picker-input > input.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input.ant-picker-input > input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
textarea.ant-picker-input > input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}
.ant-picker-input > input-sm {
	padding: 0px 7px;
}
.ant-picker-input > input-rtl {
	direction: rtl;
}
.ant-picker-input > input:focus {
	box-shadow: none;
}
.ant-picker-input > input[disabled] {
	background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
	opacity: 1;
}
.ant-picker-input-placeholder > input {
	color: #bfbfbf;
}
.ant-picker-large {
	padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
	font-size: 16px;
}
.ant-picker-small {
	padding: 0px 7px 0px;
}
.ant-picker-suffix {
	align-self: center;
	margin-left: 4px;
	color: rgba(0, 0, 0, 0.25);
	line-height: 1;
	pointer-events: none;
}
.ant-picker-suffix > * {
	vertical-align: top;
}
.ant-picker-clear {
	position: absolute;
	top: 50%;
	right: 0;
	color: rgba(0, 0, 0, 0.25);
	line-height: 1;
	background: #fff;
	transform: translateY(-50%);
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
	vertical-align: top;
}
.ant-picker-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
	position: relative;
	display: inline-block;
	width: 1em;
	height: 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 16px;
	vertical-align: top;
	cursor: default;
}
.ant-picker-focused .ant-picker-separator {
	color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
	cursor: not-allowed;
}
.ant-picker-range {
	position: relative;
	display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
	right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
	opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
	bottom: -1px;
	height: 2px;
	margin-left: 11px;
	background: #1b59a8;
	opacity: 0;
	transition: all 0.3s ease-out;
	pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
	opacity: 1;
}
.ant-picker-range-separator {
	align-items: center;
	padding: 0 8px;
	line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
	right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
	margin-left: 7px;
}
.ant-picker-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	z-index: 1050;
}
.ant-picker-dropdown-hidden {
	display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
	top: 1.66666667px;
	display: block;
	transform: rotate(-45deg);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
	bottom: 1.66666667px;
	display: block;
	transform: rotate(135deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
	animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
	animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
	animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
	animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
	padding: 6.66666667px 0;
}
.ant-picker-dropdown-range-hidden {
	display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
	padding-top: 4px;
}
.ant-picker-ranges {
	margin-bottom: 0;
	padding: 4px 12px;
	overflow: hidden;
	line-height: 34px;
	text-align: left;
	list-style: none;
}
.ant-picker-ranges > li {
	display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
	color: #1b59a8;
	background: #fff4e6;
	border-color: #ffbd7a;
	cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
	float: right;
	margin-left: 8px;
}
.ant-picker-range-wrapper {
	display: flex;
}
.ant-picker-range-arrow {
	position: absolute;
	z-index: 1;
	display: none;
	width: 10px;
	height: 10px;
	margin-left: 16.5px;
	box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
	transition: left 0.3s ease-out;
}
.ant-picker-range-arrow::after {
	position: absolute;
	top: 1px;
	right: 1px;
	width: 10px;
	height: 10px;
	border: 5px solid #f0f0f0;
	border-color: #fff #fff transparent transparent;
	content: '';
}
.ant-picker-panel-container {
	overflow: hidden;
	vertical-align: top;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
	display: inline-flex;
	flex-wrap: nowrap;
	direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
	vertical-align: top;
	background: transparent;
	border-width: 0 0 1px 0;
	border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
	text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
	border-color: #f0f0f0;
}
.ant-picker-panel {
	display: inline-flex;
	flex-direction: column;
	text-align: center;
	background: #fff;
	border: 1px solid #f0f0f0;
	border-radius: 2px;
	outline: none;
}
.ant-picker-panel-focused {
	border-color: #1b59a8;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
	display: flex;
	flex-direction: column;
	width: 280px;
}
.ant-picker-header {
	display: flex;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.85);
	border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
	flex: none;
}
.ant-picker-header button {
	padding: 0;
	color: rgba(0, 0, 0, 0.25);
	line-height: 40px;
	background: transparent;
	border: 0;
	cursor: pointer;
	transition: color 0.3s;
}
.ant-picker-header > button {
	min-width: 1.6em;
	font-size: 14px;
}
.ant-picker-header > button:hover {
	color: rgba(0, 0, 0, 0.65);
}
.ant-picker-header-view {
	flex: auto;
	font-weight: 500;
	line-height: 40px;
}
.ant-picker-header-view button {
	color: inherit;
	font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
	margin-left: 8px;
}
.ant-picker-header-view button:hover {
	color: #1b59a8;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
	position: relative;
	display: inline-block;
	width: 7px;
	height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 7px;
	height: 7px;
	border: 0 solid currentColor;
	border-width: 1.5px 0 0 1.5px;
	content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
	position: absolute;
	top: 4px;
	left: 4px;
	display: inline-block;
	width: 7px;
	height: 7px;
	border: 0 solid currentColor;
	border-width: 1.5px 0 0 1.5px;
	content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
	transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
	transform: rotate(135deg);
}
.ant-picker-content {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
	position: relative;
	min-width: 24px;
	font-weight: 400;
}
.ant-picker-content th {
	height: 30px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 30px;
}
.ant-picker-cell {
	padding: 3px 0;
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
}
.ant-picker-cell-in-view {
	color: rgba(0, 0, 0, 0.65);
}
.ant-picker-cell::before {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 1;
	height: 24px;
	transform: translateY(-50%);
	content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
	position: relative;
	z-index: 2;
	display: inline-block;
	min-width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 2px;
	transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
	.ant-picker-cell-inner {
	background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	border: 1px solid #1b59a8;
	border-radius: 2px;
	content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
	position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
	background: #fff4e6;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	color: #fff;
	background: #1b59a8;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
	background: #fff4e6;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
	left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
	right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
	position: absolute;
	top: 50%;
	z-index: 0;
	height: 24px;
	border-top: 1px dashed #ffa266;
	border-bottom: 1px dashed #ffa266;
	transform: translateY(-50%);
	content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
	right: 0;
	left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
	background: #ffd1b3;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
	border-radius: 5px 0 0 5px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
	border-radius: 0 5px 5px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: -1;
	background: #ffd1b3;
	content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
	right: -6px;
	left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
	right: 0;
	left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
	right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
	left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
	left: 6px;
	border-left: 1px dashed #ffa266;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
	right: 6px;
	border-right: 1px dashed #ffa266;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.ant-picker-cell-disabled {
	color: rgba(0, 0, 0, 0.25);
	pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
	background: transparent;
}
.ant-picker-cell-disabled::before {
	background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
	height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
	padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
	height: 56px;
}
.ant-picker-footer {
	width: min-content;
	min-width: 100%;
	line-height: 38px;
	text-align: center;
	border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
	border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
	padding: 0 12px;
	line-height: 38px;
	text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
	border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
	text-align: left;
}
.ant-picker-today-btn {
	color: #5c987a;
}
.ant-picker-today-btn:hover {
	color: #80a691;
}
.ant-picker-today-btn:active {
	color: #40735b;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
	padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
	display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
	padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
	width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
	left: 14px;
	border-left: 1px dashed #ffa266;
	border-radius: 5px 0 0 5px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
	right: 14px;
	border-right: 1px dashed #ffa266;
	border-radius: 0 5px 5px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
	right: 14px;
	border-right: 1px dashed #ffa266;
	border-radius: 0 5px 5px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
	left: 14px;
	border-left: 1px dashed #ffa266;
	border-radius: 5px 0 0 5px;
}
.ant-picker-week-panel .ant-picker-body {
	padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
	background: transparent !important;
}
.ant-picker-week-panel-row td {
	transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
	background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
	background: #1b59a8;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
	color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
	border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
	color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
	padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
	width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
	width: 36px;
}
.ant-picker-datetime-panel {
	display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
	border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
	transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
	opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
	opacity: 1;
}
.ant-picker-time-panel {
	width: auto;
	min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
	display: flex;
	flex: auto;
	height: 224px;
}
.ant-picker-time-panel-column {
	flex: 1 0 auto;
	width: 56px;
	margin: 0;
	padding: 0;
	overflow-y: hidden;
	text-align: left;
	list-style: none;
	transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
	display: block;
	height: 196px;
	content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
	height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
	border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
	background: rgba(255, 244, 230, 0.2);
}
.ant-picker-time-panel-column:hover {
	overflow-y: auto;
}
.ant-picker-time-panel-column > li {
	margin: 0;
	padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
	display: block;
	width: 100%;
	height: 28px;
	margin: 0;
	padding: 0 0 0 14px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 28px;
	border-radius: 0;
	cursor: pointer;
	transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
	background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
	background: #fff4e6;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	cursor: not-allowed;
}
/* stylelint-disable-next-line */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
	padding: 21px 0;
}
.ant-picker-rtl {
	direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
	margin-right: 4px;
	margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
	right: auto;
	left: 0;
}
.ant-picker-rtl .ant-picker-separator {
	transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
	margin-right: 8px;
	margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
	right: auto;
	left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
	margin-right: 11px;
	margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
	margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
	text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
	float: left;
	margin-right: 8px;
	margin-left: 0;
}
.ant-picker-panel-rtl {
	direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
	transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
	transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
	position: relative;
	z-index: 2;
	display: inline-block;
	min-width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 2px;
	transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
	right: 50%;
	left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
	right: 0;
	left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
	right: 50%;
	left: 50%;
}
.ant-picker-panel-rtl
	.ant-picker-date-panel
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
	.ant-picker-cell-inner::after {
	right: 0;
	left: -6px;
}
.ant-picker-panel-rtl
	.ant-picker-date-panel
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
	.ant-picker-cell-inner::after {
	right: -6px;
	left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
	right: 0;
	left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
	right: 50%;
	left: 0;
}
.ant-picker-panel-rtl
	.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
	.ant-picker-cell-inner {
	border-radius: 0 5px 5px 0;
}
.ant-picker-panel-rtl
	.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
	.ant-picker-cell-inner {
	border-radius: 5px 0 0 5px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl
	.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
	right: 6px;
	left: 0;
	border-right: 1px dashed #ffa266;
	border-left: none;
	border-top-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl
	.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
	right: 0;
	left: 6px;
	border-right: none;
	border-left: 1px dashed #ffa266;
	border-top-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 5px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl
	.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl
	.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl
	tr
	> .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl
	tr
	> .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
	right: 6px;
	left: 6px;
	border-right: 1px dashed #ffa266;
	border-left: 1px dashed #ffa266;
	border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
	direction: rtl;
	text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
	direction: ltr;
}
.ant-descriptions-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.ant-descriptions-title {
	flex: auto;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: bold;
	font-size: 16px;
	line-height: 1.5715;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-descriptions-extra {
	margin-left: auto;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
}
.ant-descriptions-view {
	width: 100%;
	overflow: hidden;
	border-radius: 2px;
}
.ant-descriptions-view table {
	width: 100%;
	table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
	padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
	border-bottom: none;
}
.ant-descriptions-item-label {
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	line-height: 1.5715;
	text-align: start;
}
.ant-descriptions-item-label::after {
	content: ':';
	position: relative;
	top: -0.5px;
	margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
	content: ' ';
}
.ant-descriptions-item-no-label::after {
	margin: 0;
	content: '';
}
.ant-descriptions-item-content {
	display: table-cell;
	flex: 1;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	word-break: break-word;
	overflow-wrap: break-word;
}
.ant-descriptions-item {
	padding-bottom: 0;
	vertical-align: top;
}
.ant-descriptions-item-container {
	display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
	display: inline-flex;
	align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
	padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
	padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
	border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
	table-layout: auto;
	border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
	padding: 16px 24px;
	border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
	border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
	background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
	display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
	border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
	border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
	padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
	padding: 8px 16px;
}
.ant-descriptions-rtl {
	direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
	margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
	border-right: none;
	border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
	border-left: none;
}
.ant-divider {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
	position: relative;
	top: -0.06em;
	display: inline-block;
	height: 0.9em;
	margin: 0 8px;
	vertical-align: middle;
	border-top: 0;
	border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
	display: flex;
	clear: both;
	width: 100%;
	min-width: 100%;
	margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
	display: flex;
	margin: 16px 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	white-space: nowrap;
	text-align: center;
	border-top: 0;
	border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
	position: relative;
	top: 50%;
	width: 50%;
	border-top: 1px solid transparent;
	border-top-color: inherit;
	border-bottom: 0;
	transform: translateY(50%);
	content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
	top: 50%;
	width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
	top: 50%;
	width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
	top: 50%;
	width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
	top: 50%;
	width: 5%;
}
.ant-divider-inner-text {
	display: inline-block;
	padding: 0 1em;
}
.ant-divider-dashed {
	background: none;
	border-color: rgba(0, 0, 0, 0.06);
	border-style: dashed;
	border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
	border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
	border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
	border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
}
.ant-divider-rtl {
	direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
	width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
	width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
	width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
	width: 95%;
}
.ant-drawer {
	position: fixed;
	z-index: 1000;
	width: 0%;
	height: 100%;
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}
.ant-drawer > * {
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
}
.ant-drawer .ant-drawer-content {
	width: 100%;
	height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
	top: 0;
	width: 0%;
	height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
	height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
	width: 100%;
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
	left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
	left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
	right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
	right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
	right: 1px;
	transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
	left: 0;
	width: 100%;
	height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
	width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
	height: 100%;
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
	top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
	bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
	bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
	bottom: 1px;
	transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
	height: 100%;
	opacity: 1;
	transition: none;
	animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	pointer-events: auto;
}
.ant-drawer-title {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
}
.ant-drawer-content {
	position: relative;
	z-index: 1;
	overflow: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
}
.ant-drawer-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	display: block;
	padding: 20px;
	color: rgba(0, 0, 0, 0.45);
	font-weight: 700;
	font-size: 16px;
	font-style: normal;
	line-height: 1;
	text-align: center;
	text-transform: none;
	text-decoration: none;
	background: transparent;
	border: 0;
	outline: 0;
	cursor: pointer;
	transition: color 0.3s;
	text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
	color: rgba(0, 0, 0, 0.75);
	text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
	margin-right: var(--scroll-bar);
	/* stylelint-disable-next-line function-calc-no-invalid */
	padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
	position: relative;
	padding: 16px 24px;
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
	border-bottom: 1px solid #f0f0f0;
	border-radius: 5px 5px 0 0;
}
.ant-drawer-header-no-title {
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
}
.ant-drawer-wrapper-body {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
}
.ant-drawer-body {
	flex-grow: 1;
	padding: 24px;
	overflow: auto;
	font-size: 14px;
	line-height: 1.5715;
	word-wrap: break-word;
}
.ant-drawer-footer {
	flex-shrink: 0;
	padding: 10px 16px;
	border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	background-color: rgba(0, 0, 0, 0.45);
	opacity: 0;
	filter: alpha(opacity=45);
	transition: opacity 0.3s linear, height 0s ease 0.3s;
	pointer-events: none;
}
.ant-drawer-open-content {
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
	background: #fff;
}
@keyframes antdDrawerFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.ant-drawer-rtl {
	direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
	right: auto;
	left: 0;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
	color: #f5222d;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
	color: #fff;
	background-color: #f5222d;
}
.ant-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	display: block;
}
.ant-dropdown::before {
	position: absolute;
	top: -4px;
	right: 0;
	bottom: -4px;
	left: -7px;
	z-index: -9999;
	opacity: 0.0001;
	content: ' ';
}
.ant-dropdown-wrap {
	position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
	font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
	transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
	transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
	display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
	padding-bottom: 10px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
	padding-top: 10px;
}
.ant-dropdown-arrow {
	position: absolute;
	z-index: 1;
	display: block;
	width: 8.48528137px;
	height: 8.48528137px;
	background: transparent;
	border-style: solid;
	border-width: 4.24264069px;
	transform: rotate(45deg);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
	bottom: 6.2px;
	border-top-color: transparent;
	border-right-color: #fff;
	border-bottom-color: #fff;
	border-left-color: transparent;
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
	left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
	right: 16px;
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
	top: 6px;
	border-top-color: #fff;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: #fff;
	box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
	left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
	right: 16px;
}
.ant-dropdown-menu {
	position: relative;
	margin: 0;
	padding: 4px 0;
	text-align: left;
	list-style-type: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-dropdown-menu-item-group-title {
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.45);
	transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
	position: absolute;
	z-index: 1050;
	background: transparent;
	box-shadow: none;
	transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
	list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
	margin-right: 0.3em;
	margin-left: 0.3em;
}
.ant-dropdown-menu-item {
	position: relative;
	display: flex;
	align-items: center;
}
.ant-dropdown-menu-item-icon {
	min-width: 12px;
	margin-right: 8px;
	font-size: 12px;
}
.ant-dropdown-menu-title-content {
	flex: auto;
}
.ant-dropdown-menu-title-content > a {
	color: inherit;
	transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
	color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
	clear: both;
	margin: 0;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
	color: #1b59a8;
	background-color: #fff4e6;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
	background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title-disabled a {
	pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
	height: 1px;
	margin: 4px 0;
	overflow: hidden;
	line-height: 0;
	background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
	position: absolute;
	right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
	margin-right: 0 !important;
	color: rgba(0, 0, 0, 0.45);
	font-size: 10px;
	font-style: normal;
}
.ant-dropdown-menu-item-group-list {
	margin: 0 8px;
	padding: 0;
	list-style: none;
}
.ant-dropdown-menu-submenu-title {
	padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
	position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
	position: absolute;
	top: 0;
	left: 100%;
	min-width: 100%;
	margin-left: 4px;
	transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
	color: #1b59a8;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
	animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
	animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
	animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
	animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
	font-size: 10px;
	vertical-align: baseline;
}
.ant-dropdown-button {
	white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
	background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
	color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
	color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
	color: #fff;
	background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
	color: #fff;
	background: #1b59a8;
}
.ant-dropdown-rtl {
	direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
	right: -7px;
	left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
	direction: rtl;
	text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
	direction: rtl;
	text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
	transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
	text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
	text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
	margin-right: 0;
	margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
	right: auto;
	left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
	margin-left: 0 !important;
	transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
	padding-right: 12px;
	padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
	right: 100%;
	left: 0;
	margin-right: 4px;
	margin-left: 0;
}
.ant-empty {
	margin: 0 8px;
	font-size: 14px;
	line-height: 1.5715;
	text-align: center;
}
.ant-empty-image {
	height: 100px;
	margin-bottom: 8px;
}
.ant-empty-image img {
	height: 100%;
}
.ant-empty-image svg {
	height: 100%;
	margin: auto;
}
.ant-empty-footer {
	margin-top: 16px;
}
.ant-empty-normal {
	margin: 32px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
	height: 40px;
}
.ant-empty-small {
	margin: 8px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
	height: 35px;
}
.ant-empty-img-default-ellipse {
	fill: #f5f5f5;
	fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
	fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
	fill: url(#linearGradient-1);
}
.ant-empty-img-default-path-3 {
	fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
	fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
	fill: #dce0e6;
}
.ant-empty-img-default-g {
	fill: #fff;
}
.ant-empty-img-simple-ellipse {
	fill: #f5f5f5;
}
.ant-empty-img-simple-g {
	stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
	fill: #fafafa;
}
.ant-empty-rtl {
	direction: rtl;
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
	height: auto;
}
.ant-form-item .ant-upload {
	background: transparent;
}
.ant-form-item .ant-upload.ant-upload-drag {
	background: #fafafa;
}
.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
	width: 14px;
	height: 14px;
}
.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
	display: inline-block;
	margin-left: 8px;
	font-weight: normal;
	vertical-align: middle;
	cursor: pointer;
}
.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
	margin-left: 0;
}
.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
	display: block;
}
.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
	margin-left: 0;
}
.ant-form-item .ant-input-number + .ant-form-text {
	margin-left: 8px;
}
.ant-form-item .ant-input-number-handler-wrap {
	z-index: 2;
}
.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
	width: 100%;
}
.ant-form-item .ant-picker-calendar-year-select,
.ant-form-item .ant-picker-calendar-month-select,
.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
	width: auto;
}
.ant-form-inline {
	display: flex;
	flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
	flex: none;
	flex-wrap: nowrap;
	margin-right: 16px;
	margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
	margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
	display: inline-block;
	vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
	flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
	display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
	display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
	flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
	flex: 1 1 0;
}
.ant-form-vertical .ant-form-item {
	flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
	height: auto;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
	padding: 0 0 8px;
	line-height: 1.5715;
	white-space: initial;
	text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
	margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
	display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
	text-align: right;
}
@media (max-width: 575px) {
	.ant-form-item .ant-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-form-item .ant-form-item-label > label {
		margin: 0;
	}
	.ant-form-item .ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-form-item .ant-form-item-label {
		text-align: right;
	}
	.ant-form .ant-form-item {
		flex-wrap: wrap;
	}
	.ant-form .ant-form-item .ant-form-item-label,
	.ant-form .ant-form-item .ant-form-item-control {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.ant-col-xs-24.ant-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-xs-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-xs-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-xs-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 767px) {
	.ant-col-sm-24.ant-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-sm-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-sm-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-sm-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 991px) {
	.ant-col-md-24.ant-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-md-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-md-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-md-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 1199px) {
	.ant-col-lg-24.ant-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-lg-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-lg-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-lg-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 1599px) {
	.ant-col-xl-24.ant-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-xl-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-xl-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
		text-align: right;
	}
}
.ant-form-item {
	/* Some non-status related component style is in `components.less` */
	/* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain.ant-form-item-explain-error {
	color: #f5222d;
}
.ant-form-item-explain.ant-form-item-explain-warning {
	color: #faad14;
}
.ant-form-item-has-feedback .ant-input {
	padding-right: 24px;
}
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
	padding-right: 18px;
}
.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
	right: 28px;
}
.ant-form-item-has-feedback .ant-switch {
	margin: 2px 0 4px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
	right: 32px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
	padding-right: 42px;
}
.ant-form-item-has-feedback .ant-cascader-picker-arrow {
	margin-right: 19px;
}
.ant-form-item-has-feedback .ant-cascader-picker-clear {
	right: 32px;
}
.ant-form-item-has-feedback .ant-picker {
	padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-large {
	padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-small {
	padding-right: 25.2px;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;
	width: 32px;
	height: 20px;
	margin-top: -10px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	visibility: visible;
	animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	pointer-events: none;
}
.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
	color: #52c41a;
	animation-name: diffZoomIn1 !important;
}
.ant-form-item-has-warning .ant-form-item-split {
	color: #faad14;
}
.ant-form-item-has-warning :not(.ant-input-disabled).ant-input,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-warning :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
	background-color: #fff;
	border-color: #faad14;
}
.ant-form-item-has-warning :not(.ant-input-disabled).ant-input:focus,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
.ant-form-item-has-warning :not(.ant-input-disabled).ant-input-focused,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	/* box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); */
}
.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	/* box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); */
}
.ant-form-item-has-warning .ant-input-prefix {
	color: #faad14;
}
.ant-form-item-has-warning .ant-input-group-addon {
	color: #faad14;
	border-color: #faad14;
}
.ant-form-item-has-warning .has-feedback {
	color: #faad14;
}
.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
	color: #faad14;
	animation-name: diffZoomIn3 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
	background-color: #fff;
	border-color: #faad14 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
	background-color: #fff;
	border-color: #faad14;
}
.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
	background-color: #fff;
	border-color: #faad14;
}
.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	/* box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); */
}
.ant-form-item-has-error .ant-form-item-split {
	color: #f5222d;
}
.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
	background-color: #fff;
	border-color: #f5222d;
}
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	outline: 0;
	/* box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); */
}
.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.ant-form-item-has-error .ant-input-prefix {
	color: #f5222d;
}
.ant-form-item-has-error .ant-input-group-addon {
	color: #f5222d;
	border-color: #f5222d;
}
.ant-form-item-has-error .has-feedback {
	color: #f5222d;
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
	color: #f5222d;
	animation-name: diffZoomIn2 !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
	background-color: #fff;
	border-color: #f5222d !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background-color: inherit;
	border: 0;
	box-shadow: none;
}
.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
	border-color: #f5222d;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
	background-color: #fff;
	border-color: #f5222d;
}
.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
	background-color: #fff;
	border-color: #f5222d;
}
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
	background-color: #fff;
	border-color: #f5222d;
}
.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.ant-form-item-has-error .ant-cascader-picker:hover .ant-cascader-picker-label:hover + .ant-cascader-input.ant-input {
	border-color: #f5222d;
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
	background-color: #fff;
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.ant-form-item-has-error .ant-transfer-list {
	border-color: #f5222d;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
	border-color: #d9d9d9;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper {
	border-color: #f5222d !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
	background-color: #f5222d;
}
.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
	display: inline-block;
	color: #1b59a8;
}
.ant-form {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
}
.ant-form legend {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	padding: 0;
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px;
	line-height: inherit;
	border: 0;
	border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
	font-size: 14px;
}
.ant-form input[type='search'] {
	box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
	line-height: normal;
}
.ant-form input[type='file'] {
	display: block;
}
.ant-form input[type='range'] {
	display: block;
	width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
	height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.ant-form output {
	display: block;
	padding-top: 15px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
}
.ant-form .ant-form-text {
	display: inline-block;
	padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
	height: 24px;
}
.ant-form-small .ant-form-item-control-input {
	min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
	height: 40px;
}
.ant-form-large .ant-form-item-control-input {
	min-height: 40px;
}
.ant-form-item {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	margin-bottom: 24px;
	vertical-align: top;
}
.ant-form-item-with-help {
	margin-bottom: 0;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
	display: none;
}
.ant-form-item-label {
	display: inline-block;
	flex-grow: 0;
	overflow: hidden;
	white-space: nowrap;
	text-align: right;
	vertical-align: middle;
}
.ant-form-item-label-left {
	text-align: left;
}
.ant-form-item-label > label {
	position: relative;
	display: inline-flex;
	align-items: center;
	height: 32px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
}
.ant-form-item-label > label > .anticon {
	font-size: 14px;
	vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: inline-block;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	/* font-family: SimSun, sans-serif; */
	line-height: 1;
	content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	/* display: none; */
}
.ant-form-item-label > label .ant-form-item-optional {
	display: inline-block;
	margin-left: 4px;
	color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
	display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
	color: rgba(0, 0, 0, 0.45);
	cursor: help;
	writing-mode: horizontal-tb;
	margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
	content: ':';
	position: relative;
	top: -0.5px;
	margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
	content: ' ';
}
.ant-form-item-control {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
	width: 100%;
}
.ant-form-item-control-input {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 32px;
}
.ant-form-item-control-input-content {
	flex: auto;
	max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
	clear: both;
	min-height: 24px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item .ant-input-textarea-show-count::after {
	margin-bottom: -22px;
}
.ant-show-help-enter,
.ant-show-help-appear {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-show-help-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-show-help-enter.ant-show-help-enter-active,
.ant-show-help-appear.ant-show-help-appear-active {
	animation-name: antShowHelpIn;
	animation-play-state: running;
}
.ant-show-help-leave.ant-show-help-leave-active {
	animation-name: antShowHelpOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-show-help-enter,
.ant-show-help-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-leave {
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
	0% {
		transform: translateY(-5px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes antShowHelpOut {
	to {
		transform: translateY(-5px);
		opacity: 0;
	}
}
@keyframes diffZoomIn1 {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes diffZoomIn2 {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes diffZoomIn3 {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
.ant-form-rtl {
	direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
	text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
	margin-right: 0;
	margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
	margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
	margin-right: 4px;
	margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
	width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
	padding-right: 11px;
	padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
	padding-right: 11px;
	padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
	padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
	right: auto;
	left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
	padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
	right: auto;
	left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
	padding-right: 0;
	padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
	margin-right: 0;
	margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
	right: auto;
	left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
	padding-right: 11px;
	padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
	padding-right: 11px;
	padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
	padding-right: 7px;
	padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
	right: auto;
	left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
	margin-right: 0;
	margin-left: 16px;
}
.ant-row {
	display: flex;
	flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
	display: flex;
}
.ant-row-no-wrap {
	flex-wrap: nowrap;
}
.ant-row-start {
	justify-content: flex-start;
}
.ant-row-center {
	justify-content: center;
}
.ant-row-end {
	justify-content: flex-end;
}
.ant-row-space-between {
	justify-content: space-between;
}
.ant-row-space-around {
	justify-content: space-around;
}
.ant-row-top {
	align-items: flex-start;
}
.ant-row-middle {
	align-items: center;
}
.ant-row-bottom {
	align-items: flex-end;
}
.ant-col {
	position: relative;
	max-width: 100%;
	min-height: 1px;
}
.ant-col-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}
.ant-col-push-24 {
	left: 100%;
}
.ant-col-pull-24 {
	right: 100%;
}
.ant-col-offset-24 {
	margin-left: 100%;
}
.ant-col-order-24 {
	order: 24;
}
.ant-col-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}
.ant-col-push-23 {
	left: 95.83333333%;
}
.ant-col-pull-23 {
	right: 95.83333333%;
}
.ant-col-offset-23 {
	margin-left: 95.83333333%;
}
.ant-col-order-23 {
	order: 23;
}
.ant-col-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}
.ant-col-push-22 {
	left: 91.66666667%;
}
.ant-col-pull-22 {
	right: 91.66666667%;
}
.ant-col-offset-22 {
	margin-left: 91.66666667%;
}
.ant-col-order-22 {
	order: 22;
}
.ant-col-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}
.ant-col-push-21 {
	left: 87.5%;
}
.ant-col-pull-21 {
	right: 87.5%;
}
.ant-col-offset-21 {
	margin-left: 87.5%;
}
.ant-col-order-21 {
	order: 21;
}
.ant-col-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}
.ant-col-push-20 {
	left: 83.33333333%;
}
.ant-col-pull-20 {
	right: 83.33333333%;
}
.ant-col-offset-20 {
	margin-left: 83.33333333%;
}
.ant-col-order-20 {
	order: 20;
}
.ant-col-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}
.ant-col-push-19 {
	left: 79.16666667%;
}
.ant-col-pull-19 {
	right: 79.16666667%;
}
.ant-col-offset-19 {
	margin-left: 79.16666667%;
}
.ant-col-order-19 {
	order: 19;
}
.ant-col-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}
.ant-col-push-18 {
	left: 75%;
}
.ant-col-pull-18 {
	right: 75%;
}
.ant-col-offset-18 {
	margin-left: 75%;
}
.ant-col-order-18 {
	order: 18;
}
.ant-col-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}
.ant-col-push-17 {
	left: 70.83333333%;
}
.ant-col-pull-17 {
	right: 70.83333333%;
}
.ant-col-offset-17 {
	margin-left: 70.83333333%;
}
.ant-col-order-17 {
	order: 17;
}
.ant-col-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}
.ant-col-push-16 {
	left: 66.66666667%;
}
.ant-col-pull-16 {
	right: 66.66666667%;
}
.ant-col-offset-16 {
	margin-left: 66.66666667%;
}
.ant-col-order-16 {
	order: 16;
}
.ant-col-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}
.ant-col-push-15 {
	left: 62.5%;
}
.ant-col-pull-15 {
	right: 62.5%;
}
.ant-col-offset-15 {
	margin-left: 62.5%;
}
.ant-col-order-15 {
	order: 15;
}
.ant-col-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}
.ant-col-push-14 {
	left: 58.33333333%;
}
.ant-col-pull-14 {
	right: 58.33333333%;
}
.ant-col-offset-14 {
	margin-left: 58.33333333%;
}
.ant-col-order-14 {
	order: 14;
}
.ant-col-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}
.ant-col-push-13 {
	left: 54.16666667%;
}
.ant-col-pull-13 {
	right: 54.16666667%;
}
.ant-col-offset-13 {
	margin-left: 54.16666667%;
}
.ant-col-order-13 {
	order: 13;
}
.ant-col-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}
.ant-col-push-12 {
	left: 50%;
}
.ant-col-pull-12 {
	right: 50%;
}
.ant-col-offset-12 {
	margin-left: 50%;
}
.ant-col-order-12 {
	order: 12;
}
.ant-col-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}
.ant-col-push-11 {
	left: 45.83333333%;
}
.ant-col-pull-11 {
	right: 45.83333333%;
}
.ant-col-offset-11 {
	margin-left: 45.83333333%;
}
.ant-col-order-11 {
	order: 11;
}
.ant-col-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}
.ant-col-push-10 {
	left: 41.66666667%;
}
.ant-col-pull-10 {
	right: 41.66666667%;
}
.ant-col-offset-10 {
	margin-left: 41.66666667%;
}
.ant-col-order-10 {
	order: 10;
}
.ant-col-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}
.ant-col-push-9 {
	left: 37.5%;
}
.ant-col-pull-9 {
	right: 37.5%;
}
.ant-col-offset-9 {
	margin-left: 37.5%;
}
.ant-col-order-9 {
	order: 9;
}
.ant-col-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}
.ant-col-push-8 {
	left: 33.33333333%;
}
.ant-col-pull-8 {
	right: 33.33333333%;
}
.ant-col-offset-8 {
	margin-left: 33.33333333%;
}
.ant-col-order-8 {
	order: 8;
}
.ant-col-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}
.ant-col-push-7 {
	left: 29.16666667%;
}
.ant-col-pull-7 {
	right: 29.16666667%;
}
.ant-col-offset-7 {
	margin-left: 29.16666667%;
}
.ant-col-order-7 {
	order: 7;
}
.ant-col-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}
.ant-col-push-6 {
	left: 25%;
}
.ant-col-pull-6 {
	right: 25%;
}
.ant-col-offset-6 {
	margin-left: 25%;
}
.ant-col-order-6 {
	order: 6;
}
.ant-col-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}
.ant-col-push-5 {
	left: 20.83333333%;
}
.ant-col-pull-5 {
	right: 20.83333333%;
}
.ant-col-offset-5 {
	margin-left: 20.83333333%;
}
.ant-col-order-5 {
	order: 5;
}
.ant-col-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}
.ant-col-push-4 {
	left: 16.66666667%;
}
.ant-col-pull-4 {
	right: 16.66666667%;
}
.ant-col-offset-4 {
	margin-left: 16.66666667%;
}
.ant-col-order-4 {
	order: 4;
}
.ant-col-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}
.ant-col-push-3 {
	left: 12.5%;
}
.ant-col-pull-3 {
	right: 12.5%;
}
.ant-col-offset-3 {
	margin-left: 12.5%;
}
.ant-col-order-3 {
	order: 3;
}
.ant-col-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}
.ant-col-push-2 {
	left: 8.33333333%;
}
.ant-col-pull-2 {
	right: 8.33333333%;
}
.ant-col-offset-2 {
	margin-left: 8.33333333%;
}
.ant-col-order-2 {
	order: 2;
}
.ant-col-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}
.ant-col-push-1 {
	left: 4.16666667%;
}
.ant-col-pull-1 {
	right: 4.16666667%;
}
.ant-col-offset-1 {
	margin-left: 4.16666667%;
}
.ant-col-order-1 {
	order: 1;
}
.ant-col-0 {
	display: none;
}
.ant-col-push-0 {
	left: auto;
}
.ant-col-pull-0 {
	right: auto;
}
.ant-col-push-0 {
	left: auto;
}
.ant-col-pull-0 {
	right: auto;
}
.ant-col-offset-0 {
	margin-left: 0;
}
.ant-col-order-0 {
	order: 0;
}
.ant-col-push-0.ant-col-rtl {
	right: auto;
}
.ant-col-pull-0.ant-col-rtl {
	left: auto;
}
.ant-col-push-0.ant-col-rtl {
	right: auto;
}
.ant-col-pull-0.ant-col-rtl {
	left: auto;
}
.ant-col-offset-0.ant-col-rtl {
	margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
	right: 4.16666667%;
	left: auto;
}
.ant-col-pull-1.ant-col-rtl {
	right: auto;
	left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
	margin-right: 4.16666667%;
	margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
	right: 8.33333333%;
	left: auto;
}
.ant-col-pull-2.ant-col-rtl {
	right: auto;
	left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
	margin-right: 8.33333333%;
	margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
	right: 12.5%;
	left: auto;
}
.ant-col-pull-3.ant-col-rtl {
	right: auto;
	left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
	margin-right: 12.5%;
	margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
	right: 16.66666667%;
	left: auto;
}
.ant-col-pull-4.ant-col-rtl {
	right: auto;
	left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
	margin-right: 16.66666667%;
	margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
	right: 20.83333333%;
	left: auto;
}
.ant-col-pull-5.ant-col-rtl {
	right: auto;
	left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
	margin-right: 20.83333333%;
	margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
	right: 25%;
	left: auto;
}
.ant-col-pull-6.ant-col-rtl {
	right: auto;
	left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
	margin-right: 25%;
	margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
	right: 29.16666667%;
	left: auto;
}
.ant-col-pull-7.ant-col-rtl {
	right: auto;
	left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
	margin-right: 29.16666667%;
	margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
	right: 33.33333333%;
	left: auto;
}
.ant-col-pull-8.ant-col-rtl {
	right: auto;
	left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
	margin-right: 33.33333333%;
	margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
	right: 37.5%;
	left: auto;
}
.ant-col-pull-9.ant-col-rtl {
	right: auto;
	left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
	margin-right: 37.5%;
	margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
	right: 41.66666667%;
	left: auto;
}
.ant-col-pull-10.ant-col-rtl {
	right: auto;
	left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
	margin-right: 41.66666667%;
	margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
	right: 45.83333333%;
	left: auto;
}
.ant-col-pull-11.ant-col-rtl {
	right: auto;
	left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
	margin-right: 45.83333333%;
	margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
	right: 50%;
	left: auto;
}
.ant-col-pull-12.ant-col-rtl {
	right: auto;
	left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
	margin-right: 50%;
	margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
	right: 54.16666667%;
	left: auto;
}
.ant-col-pull-13.ant-col-rtl {
	right: auto;
	left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
	margin-right: 54.16666667%;
	margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
	right: 58.33333333%;
	left: auto;
}
.ant-col-pull-14.ant-col-rtl {
	right: auto;
	left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
	margin-right: 58.33333333%;
	margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
	right: 62.5%;
	left: auto;
}
.ant-col-pull-15.ant-col-rtl {
	right: auto;
	left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
	margin-right: 62.5%;
	margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
	right: 66.66666667%;
	left: auto;
}
.ant-col-pull-16.ant-col-rtl {
	right: auto;
	left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
	margin-right: 66.66666667%;
	margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
	right: 70.83333333%;
	left: auto;
}
.ant-col-pull-17.ant-col-rtl {
	right: auto;
	left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
	margin-right: 70.83333333%;
	margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
	right: 75%;
	left: auto;
}
.ant-col-pull-18.ant-col-rtl {
	right: auto;
	left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
	margin-right: 75%;
	margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
	right: 79.16666667%;
	left: auto;
}
.ant-col-pull-19.ant-col-rtl {
	right: auto;
	left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
	margin-right: 79.16666667%;
	margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
	right: 83.33333333%;
	left: auto;
}
.ant-col-pull-20.ant-col-rtl {
	right: auto;
	left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
	margin-right: 83.33333333%;
	margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
	right: 87.5%;
	left: auto;
}
.ant-col-pull-21.ant-col-rtl {
	right: auto;
	left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
	margin-right: 87.5%;
	margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
	right: 91.66666667%;
	left: auto;
}
.ant-col-pull-22.ant-col-rtl {
	right: auto;
	left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
	margin-right: 91.66666667%;
	margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
	right: 95.83333333%;
	left: auto;
}
.ant-col-pull-23.ant-col-rtl {
	right: auto;
	left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
	margin-right: 95.83333333%;
	margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
	right: 100%;
	left: auto;
}
.ant-col-pull-24.ant-col-rtl {
	right: auto;
	left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
	margin-right: 100%;
	margin-left: 0;
}
.ant-col-xs-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}
.ant-col-xs-push-24 {
	left: 100%;
}
.ant-col-xs-pull-24 {
	right: 100%;
}
.ant-col-xs-offset-24 {
	margin-left: 100%;
}
.ant-col-xs-order-24 {
	order: 24;
}
.ant-col-xs-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
	left: 95.83333333%;
}
.ant-col-xs-pull-23 {
	right: 95.83333333%;
}
.ant-col-xs-offset-23 {
	margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
	order: 23;
}
.ant-col-xs-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
	left: 91.66666667%;
}
.ant-col-xs-pull-22 {
	right: 91.66666667%;
}
.ant-col-xs-offset-22 {
	margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
	order: 22;
}
.ant-col-xs-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}
.ant-col-xs-push-21 {
	left: 87.5%;
}
.ant-col-xs-pull-21 {
	right: 87.5%;
}
.ant-col-xs-offset-21 {
	margin-left: 87.5%;
}
.ant-col-xs-order-21 {
	order: 21;
}
.ant-col-xs-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
	left: 83.33333333%;
}
.ant-col-xs-pull-20 {
	right: 83.33333333%;
}
.ant-col-xs-offset-20 {
	margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
	order: 20;
}
.ant-col-xs-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
	left: 79.16666667%;
}
.ant-col-xs-pull-19 {
	right: 79.16666667%;
}
.ant-col-xs-offset-19 {
	margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
	order: 19;
}
.ant-col-xs-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}
.ant-col-xs-push-18 {
	left: 75%;
}
.ant-col-xs-pull-18 {
	right: 75%;
}
.ant-col-xs-offset-18 {
	margin-left: 75%;
}
.ant-col-xs-order-18 {
	order: 18;
}
.ant-col-xs-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
	left: 70.83333333%;
}
.ant-col-xs-pull-17 {
	right: 70.83333333%;
}
.ant-col-xs-offset-17 {
	margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
	order: 17;
}
.ant-col-xs-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
	left: 66.66666667%;
}
.ant-col-xs-pull-16 {
	right: 66.66666667%;
}
.ant-col-xs-offset-16 {
	margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
	order: 16;
}
.ant-col-xs-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}
.ant-col-xs-push-15 {
	left: 62.5%;
}
.ant-col-xs-pull-15 {
	right: 62.5%;
}
.ant-col-xs-offset-15 {
	margin-left: 62.5%;
}
.ant-col-xs-order-15 {
	order: 15;
}
.ant-col-xs-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
	left: 58.33333333%;
}
.ant-col-xs-pull-14 {
	right: 58.33333333%;
}
.ant-col-xs-offset-14 {
	margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
	order: 14;
}
.ant-col-xs-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
	left: 54.16666667%;
}
.ant-col-xs-pull-13 {
	right: 54.16666667%;
}
.ant-col-xs-offset-13 {
	margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
	order: 13;
}
.ant-col-xs-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}
.ant-col-xs-push-12 {
	left: 50%;
}
.ant-col-xs-pull-12 {
	right: 50%;
}
.ant-col-xs-offset-12 {
	margin-left: 50%;
}
.ant-col-xs-order-12 {
	order: 12;
}
.ant-col-xs-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
	left: 45.83333333%;
}
.ant-col-xs-pull-11 {
	right: 45.83333333%;
}
.ant-col-xs-offset-11 {
	margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
	order: 11;
}
.ant-col-xs-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
	left: 41.66666667%;
}
.ant-col-xs-pull-10 {
	right: 41.66666667%;
}
.ant-col-xs-offset-10 {
	margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
	order: 10;
}
.ant-col-xs-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}
.ant-col-xs-push-9 {
	left: 37.5%;
}
.ant-col-xs-pull-9 {
	right: 37.5%;
}
.ant-col-xs-offset-9 {
	margin-left: 37.5%;
}
.ant-col-xs-order-9 {
	order: 9;
}
.ant-col-xs-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
	left: 33.33333333%;
}
.ant-col-xs-pull-8 {
	right: 33.33333333%;
}
.ant-col-xs-offset-8 {
	margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
	order: 8;
}
.ant-col-xs-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
	left: 29.16666667%;
}
.ant-col-xs-pull-7 {
	right: 29.16666667%;
}
.ant-col-xs-offset-7 {
	margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
	order: 7;
}
.ant-col-xs-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}
.ant-col-xs-push-6 {
	left: 25%;
}
.ant-col-xs-pull-6 {
	right: 25%;
}
.ant-col-xs-offset-6 {
	margin-left: 25%;
}
.ant-col-xs-order-6 {
	order: 6;
}
.ant-col-xs-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
	left: 20.83333333%;
}
.ant-col-xs-pull-5 {
	right: 20.83333333%;
}
.ant-col-xs-offset-5 {
	margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
	order: 5;
}
.ant-col-xs-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
	left: 16.66666667%;
}
.ant-col-xs-pull-4 {
	right: 16.66666667%;
}
.ant-col-xs-offset-4 {
	margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
	order: 4;
}
.ant-col-xs-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}
.ant-col-xs-push-3 {
	left: 12.5%;
}
.ant-col-xs-pull-3 {
	right: 12.5%;
}
.ant-col-xs-offset-3 {
	margin-left: 12.5%;
}
.ant-col-xs-order-3 {
	order: 3;
}
.ant-col-xs-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
	left: 8.33333333%;
}
.ant-col-xs-pull-2 {
	right: 8.33333333%;
}
.ant-col-xs-offset-2 {
	margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
	order: 2;
}
.ant-col-xs-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
	left: 4.16666667%;
}
.ant-col-xs-pull-1 {
	right: 4.16666667%;
}
.ant-col-xs-offset-1 {
	margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
	order: 1;
}
.ant-col-xs-0 {
	display: none;
}
.ant-col-push-0 {
	left: auto;
}
.ant-col-pull-0 {
	right: auto;
}
.ant-col-xs-push-0 {
	left: auto;
}
.ant-col-xs-pull-0 {
	right: auto;
}
.ant-col-xs-offset-0 {
	margin-left: 0;
}
.ant-col-xs-order-0 {
	order: 0;
}
.ant-col-push-0.ant-col-rtl {
	right: auto;
}
.ant-col-pull-0.ant-col-rtl {
	left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
	right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
	left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
	margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
	right: 4.16666667%;
	left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
	right: auto;
	left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
	margin-right: 4.16666667%;
	margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
	right: 8.33333333%;
	left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
	right: auto;
	left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
	margin-right: 8.33333333%;
	margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
	right: 12.5%;
	left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
	right: auto;
	left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
	margin-right: 12.5%;
	margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
	right: 16.66666667%;
	left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
	right: auto;
	left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
	margin-right: 16.66666667%;
	margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
	right: 20.83333333%;
	left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
	right: auto;
	left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
	margin-right: 20.83333333%;
	margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
	right: 25%;
	left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
	right: auto;
	left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
	margin-right: 25%;
	margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
	right: 29.16666667%;
	left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
	right: auto;
	left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
	margin-right: 29.16666667%;
	margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
	right: 33.33333333%;
	left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
	right: auto;
	left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
	margin-right: 33.33333333%;
	margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
	right: 37.5%;
	left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
	right: auto;
	left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
	margin-right: 37.5%;
	margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
	right: 41.66666667%;
	left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
	right: auto;
	left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
	margin-right: 41.66666667%;
	margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
	right: 45.83333333%;
	left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
	right: auto;
	left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
	margin-right: 45.83333333%;
	margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
	right: 50%;
	left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
	right: auto;
	left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
	margin-right: 50%;
	margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
	right: 54.16666667%;
	left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
	right: auto;
	left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
	margin-right: 54.16666667%;
	margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
	right: 58.33333333%;
	left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
	right: auto;
	left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
	margin-right: 58.33333333%;
	margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
	right: 62.5%;
	left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
	right: auto;
	left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
	margin-right: 62.5%;
	margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
	right: 66.66666667%;
	left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
	right: auto;
	left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
	margin-right: 66.66666667%;
	margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
	right: 70.83333333%;
	left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
	right: auto;
	left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
	margin-right: 70.83333333%;
	margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
	right: 75%;
	left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
	right: auto;
	left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
	margin-right: 75%;
	margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
	right: 79.16666667%;
	left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
	right: auto;
	left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
	margin-right: 79.16666667%;
	margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
	right: 83.33333333%;
	left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
	right: auto;
	left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
	margin-right: 83.33333333%;
	margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
	right: 87.5%;
	left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
	right: auto;
	left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
	margin-right: 87.5%;
	margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
	right: 91.66666667%;
	left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
	right: auto;
	left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
	margin-right: 91.66666667%;
	margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
	right: 95.83333333%;
	left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
	right: auto;
	left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
	margin-right: 95.83333333%;
	margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
	right: 100%;
	left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
	right: auto;
	left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
	margin-right: 100%;
	margin-left: 0;
}
@media (min-width: 576px) {
	.ant-col-sm-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.ant-col-sm-push-24 {
		left: 100%;
	}
	.ant-col-sm-pull-24 {
		right: 100%;
	}
	.ant-col-sm-offset-24 {
		margin-left: 100%;
	}
	.ant-col-sm-order-24 {
		order: 24;
	}
	.ant-col-sm-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}
	.ant-col-sm-push-23 {
		left: 95.83333333%;
	}
	.ant-col-sm-pull-23 {
		right: 95.83333333%;
	}
	.ant-col-sm-offset-23 {
		margin-left: 95.83333333%;
	}
	.ant-col-sm-order-23 {
		order: 23;
	}
	.ant-col-sm-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}
	.ant-col-sm-push-22 {
		left: 91.66666667%;
	}
	.ant-col-sm-pull-22 {
		right: 91.66666667%;
	}
	.ant-col-sm-offset-22 {
		margin-left: 91.66666667%;
	}
	.ant-col-sm-order-22 {
		order: 22;
	}
	.ant-col-sm-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}
	.ant-col-sm-push-21 {
		left: 87.5%;
	}
	.ant-col-sm-pull-21 {
		right: 87.5%;
	}
	.ant-col-sm-offset-21 {
		margin-left: 87.5%;
	}
	.ant-col-sm-order-21 {
		order: 21;
	}
	.ant-col-sm-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}
	.ant-col-sm-push-20 {
		left: 83.33333333%;
	}
	.ant-col-sm-pull-20 {
		right: 83.33333333%;
	}
	.ant-col-sm-offset-20 {
		margin-left: 83.33333333%;
	}
	.ant-col-sm-order-20 {
		order: 20;
	}
	.ant-col-sm-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}
	.ant-col-sm-push-19 {
		left: 79.16666667%;
	}
	.ant-col-sm-pull-19 {
		right: 79.16666667%;
	}
	.ant-col-sm-offset-19 {
		margin-left: 79.16666667%;
	}
	.ant-col-sm-order-19 {
		order: 19;
	}
	.ant-col-sm-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.ant-col-sm-push-18 {
		left: 75%;
	}
	.ant-col-sm-pull-18 {
		right: 75%;
	}
	.ant-col-sm-offset-18 {
		margin-left: 75%;
	}
	.ant-col-sm-order-18 {
		order: 18;
	}
	.ant-col-sm-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}
	.ant-col-sm-push-17 {
		left: 70.83333333%;
	}
	.ant-col-sm-pull-17 {
		right: 70.83333333%;
	}
	.ant-col-sm-offset-17 {
		margin-left: 70.83333333%;
	}
	.ant-col-sm-order-17 {
		order: 17;
	}
	.ant-col-sm-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}
	.ant-col-sm-push-16 {
		left: 66.66666667%;
	}
	.ant-col-sm-pull-16 {
		right: 66.66666667%;
	}
	.ant-col-sm-offset-16 {
		margin-left: 66.66666667%;
	}
	.ant-col-sm-order-16 {
		order: 16;
	}
	.ant-col-sm-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}
	.ant-col-sm-push-15 {
		left: 62.5%;
	}
	.ant-col-sm-pull-15 {
		right: 62.5%;
	}
	.ant-col-sm-offset-15 {
		margin-left: 62.5%;
	}
	.ant-col-sm-order-15 {
		order: 15;
	}
	.ant-col-sm-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}
	.ant-col-sm-push-14 {
		left: 58.33333333%;
	}
	.ant-col-sm-pull-14 {
		right: 58.33333333%;
	}
	.ant-col-sm-offset-14 {
		margin-left: 58.33333333%;
	}
	.ant-col-sm-order-14 {
		order: 14;
	}
	.ant-col-sm-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}
	.ant-col-sm-push-13 {
		left: 54.16666667%;
	}
	.ant-col-sm-pull-13 {
		right: 54.16666667%;
	}
	.ant-col-sm-offset-13 {
		margin-left: 54.16666667%;
	}
	.ant-col-sm-order-13 {
		order: 13;
	}
	.ant-col-sm-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.ant-col-sm-push-12 {
		left: 50%;
	}
	.ant-col-sm-pull-12 {
		right: 50%;
	}
	.ant-col-sm-offset-12 {
		margin-left: 50%;
	}
	.ant-col-sm-order-12 {
		order: 12;
	}
	.ant-col-sm-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}
	.ant-col-sm-push-11 {
		left: 45.83333333%;
	}
	.ant-col-sm-pull-11 {
		right: 45.83333333%;
	}
	.ant-col-sm-offset-11 {
		margin-left: 45.83333333%;
	}
	.ant-col-sm-order-11 {
		order: 11;
	}
	.ant-col-sm-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}
	.ant-col-sm-push-10 {
		left: 41.66666667%;
	}
	.ant-col-sm-pull-10 {
		right: 41.66666667%;
	}
	.ant-col-sm-offset-10 {
		margin-left: 41.66666667%;
	}
	.ant-col-sm-order-10 {
		order: 10;
	}
	.ant-col-sm-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}
	.ant-col-sm-push-9 {
		left: 37.5%;
	}
	.ant-col-sm-pull-9 {
		right: 37.5%;
	}
	.ant-col-sm-offset-9 {
		margin-left: 37.5%;
	}
	.ant-col-sm-order-9 {
		order: 9;
	}
	.ant-col-sm-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}
	.ant-col-sm-push-8 {
		left: 33.33333333%;
	}
	.ant-col-sm-pull-8 {
		right: 33.33333333%;
	}
	.ant-col-sm-offset-8 {
		margin-left: 33.33333333%;
	}
	.ant-col-sm-order-8 {
		order: 8;
	}
	.ant-col-sm-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}
	.ant-col-sm-push-7 {
		left: 29.16666667%;
	}
	.ant-col-sm-pull-7 {
		right: 29.16666667%;
	}
	.ant-col-sm-offset-7 {
		margin-left: 29.16666667%;
	}
	.ant-col-sm-order-7 {
		order: 7;
	}
	.ant-col-sm-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.ant-col-sm-push-6 {
		left: 25%;
	}
	.ant-col-sm-pull-6 {
		right: 25%;
	}
	.ant-col-sm-offset-6 {
		margin-left: 25%;
	}
	.ant-col-sm-order-6 {
		order: 6;
	}
	.ant-col-sm-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}
	.ant-col-sm-push-5 {
		left: 20.83333333%;
	}
	.ant-col-sm-pull-5 {
		right: 20.83333333%;
	}
	.ant-col-sm-offset-5 {
		margin-left: 20.83333333%;
	}
	.ant-col-sm-order-5 {
		order: 5;
	}
	.ant-col-sm-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}
	.ant-col-sm-push-4 {
		left: 16.66666667%;
	}
	.ant-col-sm-pull-4 {
		right: 16.66666667%;
	}
	.ant-col-sm-offset-4 {
		margin-left: 16.66666667%;
	}
	.ant-col-sm-order-4 {
		order: 4;
	}
	.ant-col-sm-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
	.ant-col-sm-push-3 {
		left: 12.5%;
	}
	.ant-col-sm-pull-3 {
		right: 12.5%;
	}
	.ant-col-sm-offset-3 {
		margin-left: 12.5%;
	}
	.ant-col-sm-order-3 {
		order: 3;
	}
	.ant-col-sm-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}
	.ant-col-sm-push-2 {
		left: 8.33333333%;
	}
	.ant-col-sm-pull-2 {
		right: 8.33333333%;
	}
	.ant-col-sm-offset-2 {
		margin-left: 8.33333333%;
	}
	.ant-col-sm-order-2 {
		order: 2;
	}
	.ant-col-sm-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}
	.ant-col-sm-push-1 {
		left: 4.16666667%;
	}
	.ant-col-sm-pull-1 {
		right: 4.16666667%;
	}
	.ant-col-sm-offset-1 {
		margin-left: 4.16666667%;
	}
	.ant-col-sm-order-1 {
		order: 1;
	}
	.ant-col-sm-0 {
		display: none;
	}
	.ant-col-push-0 {
		left: auto;
	}
	.ant-col-pull-0 {
		right: auto;
	}
	.ant-col-sm-push-0 {
		left: auto;
	}
	.ant-col-sm-pull-0 {
		right: auto;
	}
	.ant-col-sm-offset-0 {
		margin-left: 0;
	}
	.ant-col-sm-order-0 {
		order: 0;
	}
	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-sm-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-sm-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-sm-offset-0.ant-col-rtl {
		margin-right: 0;
	}
	.ant-col-sm-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}
	.ant-col-sm-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}
	.ant-col-sm-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}
	.ant-col-sm-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}
	.ant-col-sm-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}
	.ant-col-sm-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}
	.ant-col-sm-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}
	.ant-col-sm-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}
	.ant-col-sm-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}
	.ant-col-sm-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}
	.ant-col-sm-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}
	.ant-col-sm-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}
	.ant-col-sm-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}
	.ant-col-sm-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}
	.ant-col-sm-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}
	.ant-col-sm-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}
	.ant-col-sm-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}
	.ant-col-sm-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}
	.ant-col-sm-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}
	.ant-col-sm-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}
	.ant-col-sm-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}
	.ant-col-sm-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}
	.ant-col-sm-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}
	.ant-col-sm-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}
	.ant-col-sm-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}
	.ant-col-sm-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}
	.ant-col-sm-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}
	.ant-col-sm-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}
	.ant-col-sm-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}
	.ant-col-sm-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}
	.ant-col-sm-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}
	.ant-col-sm-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}
	.ant-col-sm-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}
	.ant-col-sm-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}
	.ant-col-sm-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}
	.ant-col-sm-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}
	.ant-col-sm-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}
	.ant-col-sm-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}
	.ant-col-sm-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}
	.ant-col-sm-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}
	.ant-col-sm-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}
	.ant-col-sm-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}
	.ant-col-sm-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}
	.ant-col-sm-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}
	.ant-col-sm-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}
	.ant-col-sm-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}
	.ant-col-sm-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}
	.ant-col-sm-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}
	.ant-col-sm-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}
	.ant-col-sm-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}
	.ant-col-sm-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}
	.ant-col-sm-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}
	.ant-col-sm-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}
	.ant-col-sm-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}
	.ant-col-sm-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}
	.ant-col-sm-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}
	.ant-col-sm-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}
	.ant-col-sm-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}
@media (min-width: 768px) {
	.ant-col-md-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.ant-col-md-push-24 {
		left: 100%;
	}
	.ant-col-md-pull-24 {
		right: 100%;
	}
	.ant-col-md-offset-24 {
		margin-left: 100%;
	}
	.ant-col-md-order-24 {
		order: 24;
	}
	.ant-col-md-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}
	.ant-col-md-push-23 {
		left: 95.83333333%;
	}
	.ant-col-md-pull-23 {
		right: 95.83333333%;
	}
	.ant-col-md-offset-23 {
		margin-left: 95.83333333%;
	}
	.ant-col-md-order-23 {
		order: 23;
	}
	.ant-col-md-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}
	.ant-col-md-push-22 {
		left: 91.66666667%;
	}
	.ant-col-md-pull-22 {
		right: 91.66666667%;
	}
	.ant-col-md-offset-22 {
		margin-left: 91.66666667%;
	}
	.ant-col-md-order-22 {
		order: 22;
	}
	.ant-col-md-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}
	.ant-col-md-push-21 {
		left: 87.5%;
	}
	.ant-col-md-pull-21 {
		right: 87.5%;
	}
	.ant-col-md-offset-21 {
		margin-left: 87.5%;
	}
	.ant-col-md-order-21 {
		order: 21;
	}
	.ant-col-md-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}
	.ant-col-md-push-20 {
		left: 83.33333333%;
	}
	.ant-col-md-pull-20 {
		right: 83.33333333%;
	}
	.ant-col-md-offset-20 {
		margin-left: 83.33333333%;
	}
	.ant-col-md-order-20 {
		order: 20;
	}
	.ant-col-md-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}
	.ant-col-md-push-19 {
		left: 79.16666667%;
	}
	.ant-col-md-pull-19 {
		right: 79.16666667%;
	}
	.ant-col-md-offset-19 {
		margin-left: 79.16666667%;
	}
	.ant-col-md-order-19 {
		order: 19;
	}
	.ant-col-md-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.ant-col-md-push-18 {
		left: 75%;
	}
	.ant-col-md-pull-18 {
		right: 75%;
	}
	.ant-col-md-offset-18 {
		margin-left: 75%;
	}
	.ant-col-md-order-18 {
		order: 18;
	}
	.ant-col-md-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}
	.ant-col-md-push-17 {
		left: 70.83333333%;
	}
	.ant-col-md-pull-17 {
		right: 70.83333333%;
	}
	.ant-col-md-offset-17 {
		margin-left: 70.83333333%;
	}
	.ant-col-md-order-17 {
		order: 17;
	}
	.ant-col-md-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}
	.ant-col-md-push-16 {
		left: 66.66666667%;
	}
	.ant-col-md-pull-16 {
		right: 66.66666667%;
	}
	.ant-col-md-offset-16 {
		margin-left: 66.66666667%;
	}
	.ant-col-md-order-16 {
		order: 16;
	}
	.ant-col-md-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}
	.ant-col-md-push-15 {
		left: 62.5%;
	}
	.ant-col-md-pull-15 {
		right: 62.5%;
	}
	.ant-col-md-offset-15 {
		margin-left: 62.5%;
	}
	.ant-col-md-order-15 {
		order: 15;
	}
	.ant-col-md-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}
	.ant-col-md-push-14 {
		left: 58.33333333%;
	}
	.ant-col-md-pull-14 {
		right: 58.33333333%;
	}
	.ant-col-md-offset-14 {
		margin-left: 58.33333333%;
	}
	.ant-col-md-order-14 {
		order: 14;
	}
	.ant-col-md-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}
	.ant-col-md-push-13 {
		left: 54.16666667%;
	}
	.ant-col-md-pull-13 {
		right: 54.16666667%;
	}
	.ant-col-md-offset-13 {
		margin-left: 54.16666667%;
	}
	.ant-col-md-order-13 {
		order: 13;
	}
	.ant-col-md-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.ant-col-md-push-12 {
		left: 50%;
	}
	.ant-col-md-pull-12 {
		right: 50%;
	}
	.ant-col-md-offset-12 {
		margin-left: 50%;
	}
	.ant-col-md-order-12 {
		order: 12;
	}
	.ant-col-md-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}
	.ant-col-md-push-11 {
		left: 45.83333333%;
	}
	.ant-col-md-pull-11 {
		right: 45.83333333%;
	}
	.ant-col-md-offset-11 {
		margin-left: 45.83333333%;
	}
	.ant-col-md-order-11 {
		order: 11;
	}
	.ant-col-md-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}
	.ant-col-md-push-10 {
		left: 41.66666667%;
	}
	.ant-col-md-pull-10 {
		right: 41.66666667%;
	}
	.ant-col-md-offset-10 {
		margin-left: 41.66666667%;
	}
	.ant-col-md-order-10 {
		order: 10;
	}
	.ant-col-md-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}
	.ant-col-md-push-9 {
		left: 37.5%;
	}
	.ant-col-md-pull-9 {
		right: 37.5%;
	}
	.ant-col-md-offset-9 {
		margin-left: 37.5%;
	}
	.ant-col-md-order-9 {
		order: 9;
	}
	.ant-col-md-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}
	.ant-col-md-push-8 {
		left: 33.33333333%;
	}
	.ant-col-md-pull-8 {
		right: 33.33333333%;
	}
	.ant-col-md-offset-8 {
		margin-left: 33.33333333%;
	}
	.ant-col-md-order-8 {
		order: 8;
	}
	.ant-col-md-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}
	.ant-col-md-push-7 {
		left: 29.16666667%;
	}
	.ant-col-md-pull-7 {
		right: 29.16666667%;
	}
	.ant-col-md-offset-7 {
		margin-left: 29.16666667%;
	}
	.ant-col-md-order-7 {
		order: 7;
	}
	.ant-col-md-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.ant-col-md-push-6 {
		left: 25%;
	}
	.ant-col-md-pull-6 {
		right: 25%;
	}
	.ant-col-md-offset-6 {
		margin-left: 25%;
	}
	.ant-col-md-order-6 {
		order: 6;
	}
	.ant-col-md-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}
	.ant-col-md-push-5 {
		left: 20.83333333%;
	}
	.ant-col-md-pull-5 {
		right: 20.83333333%;
	}
	.ant-col-md-offset-5 {
		margin-left: 20.83333333%;
	}
	.ant-col-md-order-5 {
		order: 5;
	}
	.ant-col-md-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}
	.ant-col-md-push-4 {
		left: 16.66666667%;
	}
	.ant-col-md-pull-4 {
		right: 16.66666667%;
	}
	.ant-col-md-offset-4 {
		margin-left: 16.66666667%;
	}
	.ant-col-md-order-4 {
		order: 4;
	}
	.ant-col-md-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
	.ant-col-md-push-3 {
		left: 12.5%;
	}
	.ant-col-md-pull-3 {
		right: 12.5%;
	}
	.ant-col-md-offset-3 {
		margin-left: 12.5%;
	}
	.ant-col-md-order-3 {
		order: 3;
	}
	.ant-col-md-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}
	.ant-col-md-push-2 {
		left: 8.33333333%;
	}
	.ant-col-md-pull-2 {
		right: 8.33333333%;
	}
	.ant-col-md-offset-2 {
		margin-left: 8.33333333%;
	}
	.ant-col-md-order-2 {
		order: 2;
	}
	.ant-col-md-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}
	.ant-col-md-push-1 {
		left: 4.16666667%;
	}
	.ant-col-md-pull-1 {
		right: 4.16666667%;
	}
	.ant-col-md-offset-1 {
		margin-left: 4.16666667%;
	}
	.ant-col-md-order-1 {
		order: 1;
	}
	.ant-col-md-0 {
		display: none;
	}
	.ant-col-push-0 {
		left: auto;
	}
	.ant-col-pull-0 {
		right: auto;
	}
	.ant-col-md-push-0 {
		left: auto;
	}
	.ant-col-md-pull-0 {
		right: auto;
	}
	.ant-col-md-offset-0 {
		margin-left: 0;
	}
	.ant-col-md-order-0 {
		order: 0;
	}
	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-md-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-md-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-md-offset-0.ant-col-rtl {
		margin-right: 0;
	}
	.ant-col-md-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}
	.ant-col-md-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}
	.ant-col-md-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}
	.ant-col-md-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}
	.ant-col-md-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}
	.ant-col-md-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}
	.ant-col-md-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}
	.ant-col-md-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}
	.ant-col-md-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}
	.ant-col-md-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}
	.ant-col-md-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}
	.ant-col-md-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}
	.ant-col-md-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}
	.ant-col-md-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}
	.ant-col-md-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}
	.ant-col-md-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}
	.ant-col-md-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}
	.ant-col-md-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}
	.ant-col-md-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}
	.ant-col-md-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}
	.ant-col-md-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}
	.ant-col-md-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}
	.ant-col-md-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}
	.ant-col-md-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}
	.ant-col-md-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}
	.ant-col-md-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}
	.ant-col-md-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}
	.ant-col-md-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}
	.ant-col-md-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}
	.ant-col-md-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}
	.ant-col-md-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}
	.ant-col-md-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}
	.ant-col-md-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}
	.ant-col-md-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}
	.ant-col-md-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}
	.ant-col-md-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}
	.ant-col-md-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}
	.ant-col-md-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}
	.ant-col-md-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}
	.ant-col-md-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}
	.ant-col-md-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}
	.ant-col-md-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}
	.ant-col-md-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}
	.ant-col-md-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}
	.ant-col-md-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}
	.ant-col-md-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}
	.ant-col-md-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}
	.ant-col-md-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}
	.ant-col-md-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}
	.ant-col-md-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}
	.ant-col-md-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}
	.ant-col-md-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}
	.ant-col-md-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}
	.ant-col-md-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}
	.ant-col-md-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}
	.ant-col-md-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}
	.ant-col-md-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}
	.ant-col-md-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}
	.ant-col-md-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}
	.ant-col-md-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}
	.ant-col-md-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}
	.ant-col-md-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}
	.ant-col-md-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}
	.ant-col-md-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}
	.ant-col-md-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}
	.ant-col-md-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}
	.ant-col-md-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}
	.ant-col-md-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}
	.ant-col-md-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}
	.ant-col-md-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}
	.ant-col-md-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}
	.ant-col-md-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}
@media (min-width: 992px) {
	.ant-col-lg-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.ant-col-lg-push-24 {
		left: 100%;
	}
	.ant-col-lg-pull-24 {
		right: 100%;
	}
	.ant-col-lg-offset-24 {
		margin-left: 100%;
	}
	.ant-col-lg-order-24 {
		order: 24;
	}
	.ant-col-lg-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}
	.ant-col-lg-push-23 {
		left: 95.83333333%;
	}
	.ant-col-lg-pull-23 {
		right: 95.83333333%;
	}
	.ant-col-lg-offset-23 {
		margin-left: 95.83333333%;
	}
	.ant-col-lg-order-23 {
		order: 23;
	}
	.ant-col-lg-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}
	.ant-col-lg-push-22 {
		left: 91.66666667%;
	}
	.ant-col-lg-pull-22 {
		right: 91.66666667%;
	}
	.ant-col-lg-offset-22 {
		margin-left: 91.66666667%;
	}
	.ant-col-lg-order-22 {
		order: 22;
	}
	.ant-col-lg-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}
	.ant-col-lg-push-21 {
		left: 87.5%;
	}
	.ant-col-lg-pull-21 {
		right: 87.5%;
	}
	.ant-col-lg-offset-21 {
		margin-left: 87.5%;
	}
	.ant-col-lg-order-21 {
		order: 21;
	}
	.ant-col-lg-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}
	.ant-col-lg-push-20 {
		left: 83.33333333%;
	}
	.ant-col-lg-pull-20 {
		right: 83.33333333%;
	}
	.ant-col-lg-offset-20 {
		margin-left: 83.33333333%;
	}
	.ant-col-lg-order-20 {
		order: 20;
	}
	.ant-col-lg-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}
	.ant-col-lg-push-19 {
		left: 79.16666667%;
	}
	.ant-col-lg-pull-19 {
		right: 79.16666667%;
	}
	.ant-col-lg-offset-19 {
		margin-left: 79.16666667%;
	}
	.ant-col-lg-order-19 {
		order: 19;
	}
	.ant-col-lg-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.ant-col-lg-push-18 {
		left: 75%;
	}
	.ant-col-lg-pull-18 {
		right: 75%;
	}
	.ant-col-lg-offset-18 {
		margin-left: 75%;
	}
	.ant-col-lg-order-18 {
		order: 18;
	}
	.ant-col-lg-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}
	.ant-col-lg-push-17 {
		left: 70.83333333%;
	}
	.ant-col-lg-pull-17 {
		right: 70.83333333%;
	}
	.ant-col-lg-offset-17 {
		margin-left: 70.83333333%;
	}
	.ant-col-lg-order-17 {
		order: 17;
	}
	.ant-col-lg-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}
	.ant-col-lg-push-16 {
		left: 66.66666667%;
	}
	.ant-col-lg-pull-16 {
		right: 66.66666667%;
	}
	.ant-col-lg-offset-16 {
		margin-left: 66.66666667%;
	}
	.ant-col-lg-order-16 {
		order: 16;
	}
	.ant-col-lg-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}
	.ant-col-lg-push-15 {
		left: 62.5%;
	}
	.ant-col-lg-pull-15 {
		right: 62.5%;
	}
	.ant-col-lg-offset-15 {
		margin-left: 62.5%;
	}
	.ant-col-lg-order-15 {
		order: 15;
	}
	.ant-col-lg-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}
	.ant-col-lg-push-14 {
		left: 58.33333333%;
	}
	.ant-col-lg-pull-14 {
		right: 58.33333333%;
	}
	.ant-col-lg-offset-14 {
		margin-left: 58.33333333%;
	}
	.ant-col-lg-order-14 {
		order: 14;
	}
	.ant-col-lg-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}
	.ant-col-lg-push-13 {
		left: 54.16666667%;
	}
	.ant-col-lg-pull-13 {
		right: 54.16666667%;
	}
	.ant-col-lg-offset-13 {
		margin-left: 54.16666667%;
	}
	.ant-col-lg-order-13 {
		order: 13;
	}
	.ant-col-lg-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.ant-col-lg-push-12 {
		left: 50%;
	}
	.ant-col-lg-pull-12 {
		right: 50%;
	}
	.ant-col-lg-offset-12 {
		margin-left: 50%;
	}
	.ant-col-lg-order-12 {
		order: 12;
	}
	.ant-col-lg-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}
	.ant-col-lg-push-11 {
		left: 45.83333333%;
	}
	.ant-col-lg-pull-11 {
		right: 45.83333333%;
	}
	.ant-col-lg-offset-11 {
		margin-left: 45.83333333%;
	}
	.ant-col-lg-order-11 {
		order: 11;
	}
	.ant-col-lg-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}
	.ant-col-lg-push-10 {
		left: 41.66666667%;
	}
	.ant-col-lg-pull-10 {
		right: 41.66666667%;
	}
	.ant-col-lg-offset-10 {
		margin-left: 41.66666667%;
	}
	.ant-col-lg-order-10 {
		order: 10;
	}
	.ant-col-lg-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}
	.ant-col-lg-push-9 {
		left: 37.5%;
	}
	.ant-col-lg-pull-9 {
		right: 37.5%;
	}
	.ant-col-lg-offset-9 {
		margin-left: 37.5%;
	}
	.ant-col-lg-order-9 {
		order: 9;
	}
	.ant-col-lg-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}
	.ant-col-lg-push-8 {
		left: 33.33333333%;
	}
	.ant-col-lg-pull-8 {
		right: 33.33333333%;
	}
	.ant-col-lg-offset-8 {
		margin-left: 33.33333333%;
	}
	.ant-col-lg-order-8 {
		order: 8;
	}
	.ant-col-lg-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}
	.ant-col-lg-push-7 {
		left: 29.16666667%;
	}
	.ant-col-lg-pull-7 {
		right: 29.16666667%;
	}
	.ant-col-lg-offset-7 {
		margin-left: 29.16666667%;
	}
	.ant-col-lg-order-7 {
		order: 7;
	}
	.ant-col-lg-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.ant-col-lg-push-6 {
		left: 25%;
	}
	.ant-col-lg-pull-6 {
		right: 25%;
	}
	.ant-col-lg-offset-6 {
		margin-left: 25%;
	}
	.ant-col-lg-order-6 {
		order: 6;
	}
	.ant-col-lg-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}
	.ant-col-lg-push-5 {
		left: 20.83333333%;
	}
	.ant-col-lg-pull-5 {
		right: 20.83333333%;
	}
	.ant-col-lg-offset-5 {
		margin-left: 20.83333333%;
	}
	.ant-col-lg-order-5 {
		order: 5;
	}
	.ant-col-lg-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}
	.ant-col-lg-push-4 {
		left: 16.66666667%;
	}
	.ant-col-lg-pull-4 {
		right: 16.66666667%;
	}
	.ant-col-lg-offset-4 {
		margin-left: 16.66666667%;
	}
	.ant-col-lg-order-4 {
		order: 4;
	}
	.ant-col-lg-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
	.ant-col-lg-push-3 {
		left: 12.5%;
	}
	.ant-col-lg-pull-3 {
		right: 12.5%;
	}
	.ant-col-lg-offset-3 {
		margin-left: 12.5%;
	}
	.ant-col-lg-order-3 {
		order: 3;
	}
	.ant-col-lg-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}
	.ant-col-lg-push-2 {
		left: 8.33333333%;
	}
	.ant-col-lg-pull-2 {
		right: 8.33333333%;
	}
	.ant-col-lg-offset-2 {
		margin-left: 8.33333333%;
	}
	.ant-col-lg-order-2 {
		order: 2;
	}
	.ant-col-lg-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}
	.ant-col-lg-push-1 {
		left: 4.16666667%;
	}
	.ant-col-lg-pull-1 {
		right: 4.16666667%;
	}
	.ant-col-lg-offset-1 {
		margin-left: 4.16666667%;
	}
	.ant-col-lg-order-1 {
		order: 1;
	}
	.ant-col-lg-0 {
		display: none;
	}
	.ant-col-push-0 {
		left: auto;
	}
	.ant-col-pull-0 {
		right: auto;
	}
	.ant-col-lg-push-0 {
		left: auto;
	}
	.ant-col-lg-pull-0 {
		right: auto;
	}
	.ant-col-lg-offset-0 {
		margin-left: 0;
	}
	.ant-col-lg-order-0 {
		order: 0;
	}
	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-lg-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-lg-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-lg-offset-0.ant-col-rtl {
		margin-right: 0;
	}
	.ant-col-lg-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}
	.ant-col-lg-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}
	.ant-col-lg-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}
	.ant-col-lg-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}
	.ant-col-lg-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}
	.ant-col-lg-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}
	.ant-col-lg-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}
	.ant-col-lg-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}
	.ant-col-lg-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}
	.ant-col-lg-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}
	.ant-col-lg-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}
	.ant-col-lg-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}
	.ant-col-lg-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}
	.ant-col-lg-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}
	.ant-col-lg-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}
	.ant-col-lg-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}
	.ant-col-lg-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}
	.ant-col-lg-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}
	.ant-col-lg-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}
	.ant-col-lg-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}
	.ant-col-lg-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}
	.ant-col-lg-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}
	.ant-col-lg-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}
	.ant-col-lg-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}
	.ant-col-lg-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}
	.ant-col-lg-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}
	.ant-col-lg-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}
	.ant-col-lg-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}
	.ant-col-lg-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}
	.ant-col-lg-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}
	.ant-col-lg-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}
	.ant-col-lg-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}
	.ant-col-lg-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}
	.ant-col-lg-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}
	.ant-col-lg-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}
	.ant-col-lg-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}
	.ant-col-lg-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}
	.ant-col-lg-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}
	.ant-col-lg-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}
	.ant-col-lg-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}
	.ant-col-lg-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}
	.ant-col-lg-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}
	.ant-col-lg-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}
	.ant-col-lg-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}
	.ant-col-lg-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}
	.ant-col-lg-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}
	.ant-col-lg-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}
	.ant-col-lg-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}
	.ant-col-lg-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}
	.ant-col-lg-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}
	.ant-col-lg-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}
	.ant-col-lg-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}
	.ant-col-lg-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}
	.ant-col-lg-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}
	.ant-col-lg-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}
	.ant-col-lg-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}
	.ant-col-lg-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}
	.ant-col-lg-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}
@media (min-width: 1200px) {
	.ant-col-xl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.ant-col-xl-push-24 {
		left: 100%;
	}
	.ant-col-xl-pull-24 {
		right: 100%;
	}
	.ant-col-xl-offset-24 {
		margin-left: 100%;
	}
	.ant-col-xl-order-24 {
		order: 24;
	}
	.ant-col-xl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}
	.ant-col-xl-push-23 {
		left: 95.83333333%;
	}
	.ant-col-xl-pull-23 {
		right: 95.83333333%;
	}
	.ant-col-xl-offset-23 {
		margin-left: 95.83333333%;
	}
	.ant-col-xl-order-23 {
		order: 23;
	}
	.ant-col-xl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}
	.ant-col-xl-push-22 {
		left: 91.66666667%;
	}
	.ant-col-xl-pull-22 {
		right: 91.66666667%;
	}
	.ant-col-xl-offset-22 {
		margin-left: 91.66666667%;
	}
	.ant-col-xl-order-22 {
		order: 22;
	}
	.ant-col-xl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}
	.ant-col-xl-push-21 {
		left: 87.5%;
	}
	.ant-col-xl-pull-21 {
		right: 87.5%;
	}
	.ant-col-xl-offset-21 {
		margin-left: 87.5%;
	}
	.ant-col-xl-order-21 {
		order: 21;
	}
	.ant-col-xl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}
	.ant-col-xl-push-20 {
		left: 83.33333333%;
	}
	.ant-col-xl-pull-20 {
		right: 83.33333333%;
	}
	.ant-col-xl-offset-20 {
		margin-left: 83.33333333%;
	}
	.ant-col-xl-order-20 {
		order: 20;
	}
	.ant-col-xl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}
	.ant-col-xl-push-19 {
		left: 79.16666667%;
	}
	.ant-col-xl-pull-19 {
		right: 79.16666667%;
	}
	.ant-col-xl-offset-19 {
		margin-left: 79.16666667%;
	}
	.ant-col-xl-order-19 {
		order: 19;
	}
	.ant-col-xl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.ant-col-xl-push-18 {
		left: 75%;
	}
	.ant-col-xl-pull-18 {
		right: 75%;
	}
	.ant-col-xl-offset-18 {
		margin-left: 75%;
	}
	.ant-col-xl-order-18 {
		order: 18;
	}
	.ant-col-xl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}
	.ant-col-xl-push-17 {
		left: 70.83333333%;
	}
	.ant-col-xl-pull-17 {
		right: 70.83333333%;
	}
	.ant-col-xl-offset-17 {
		margin-left: 70.83333333%;
	}
	.ant-col-xl-order-17 {
		order: 17;
	}
	.ant-col-xl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}
	.ant-col-xl-push-16 {
		left: 66.66666667%;
	}
	.ant-col-xl-pull-16 {
		right: 66.66666667%;
	}
	.ant-col-xl-offset-16 {
		margin-left: 66.66666667%;
	}
	.ant-col-xl-order-16 {
		order: 16;
	}
	.ant-col-xl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}
	.ant-col-xl-push-15 {
		left: 62.5%;
	}
	.ant-col-xl-pull-15 {
		right: 62.5%;
	}
	.ant-col-xl-offset-15 {
		margin-left: 62.5%;
	}
	.ant-col-xl-order-15 {
		order: 15;
	}
	.ant-col-xl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}
	.ant-col-xl-push-14 {
		left: 58.33333333%;
	}
	.ant-col-xl-pull-14 {
		right: 58.33333333%;
	}
	.ant-col-xl-offset-14 {
		margin-left: 58.33333333%;
	}
	.ant-col-xl-order-14 {
		order: 14;
	}
	.ant-col-xl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}
	.ant-col-xl-push-13 {
		left: 54.16666667%;
	}
	.ant-col-xl-pull-13 {
		right: 54.16666667%;
	}
	.ant-col-xl-offset-13 {
		margin-left: 54.16666667%;
	}
	.ant-col-xl-order-13 {
		order: 13;
	}
	.ant-col-xl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.ant-col-xl-push-12 {
		left: 50%;
	}
	.ant-col-xl-pull-12 {
		right: 50%;
	}
	.ant-col-xl-offset-12 {
		margin-left: 50%;
	}
	.ant-col-xl-order-12 {
		order: 12;
	}
	.ant-col-xl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}
	.ant-col-xl-push-11 {
		left: 45.83333333%;
	}
	.ant-col-xl-pull-11 {
		right: 45.83333333%;
	}
	.ant-col-xl-offset-11 {
		margin-left: 45.83333333%;
	}
	.ant-col-xl-order-11 {
		order: 11;
	}
	.ant-col-xl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}
	.ant-col-xl-push-10 {
		left: 41.66666667%;
	}
	.ant-col-xl-pull-10 {
		right: 41.66666667%;
	}
	.ant-col-xl-offset-10 {
		margin-left: 41.66666667%;
	}
	.ant-col-xl-order-10 {
		order: 10;
	}
	.ant-col-xl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}
	.ant-col-xl-push-9 {
		left: 37.5%;
	}
	.ant-col-xl-pull-9 {
		right: 37.5%;
	}
	.ant-col-xl-offset-9 {
		margin-left: 37.5%;
	}
	.ant-col-xl-order-9 {
		order: 9;
	}
	.ant-col-xl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}
	.ant-col-xl-push-8 {
		left: 33.33333333%;
	}
	.ant-col-xl-pull-8 {
		right: 33.33333333%;
	}
	.ant-col-xl-offset-8 {
		margin-left: 33.33333333%;
	}
	.ant-col-xl-order-8 {
		order: 8;
	}
	.ant-col-xl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}
	.ant-col-xl-push-7 {
		left: 29.16666667%;
	}
	.ant-col-xl-pull-7 {
		right: 29.16666667%;
	}
	.ant-col-xl-offset-7 {
		margin-left: 29.16666667%;
	}
	.ant-col-xl-order-7 {
		order: 7;
	}
	.ant-col-xl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.ant-col-xl-push-6 {
		left: 25%;
	}
	.ant-col-xl-pull-6 {
		right: 25%;
	}
	.ant-col-xl-offset-6 {
		margin-left: 25%;
	}
	.ant-col-xl-order-6 {
		order: 6;
	}
	.ant-col-xl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}
	.ant-col-xl-push-5 {
		left: 20.83333333%;
	}
	.ant-col-xl-pull-5 {
		right: 20.83333333%;
	}
	.ant-col-xl-offset-5 {
		margin-left: 20.83333333%;
	}
	.ant-col-xl-order-5 {
		order: 5;
	}
	.ant-col-xl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}
	.ant-col-xl-push-4 {
		left: 16.66666667%;
	}
	.ant-col-xl-pull-4 {
		right: 16.66666667%;
	}
	.ant-col-xl-offset-4 {
		margin-left: 16.66666667%;
	}
	.ant-col-xl-order-4 {
		order: 4;
	}
	.ant-col-xl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
	.ant-col-xl-push-3 {
		left: 12.5%;
	}
	.ant-col-xl-pull-3 {
		right: 12.5%;
	}
	.ant-col-xl-offset-3 {
		margin-left: 12.5%;
	}
	.ant-col-xl-order-3 {
		order: 3;
	}
	.ant-col-xl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}
	.ant-col-xl-push-2 {
		left: 8.33333333%;
	}
	.ant-col-xl-pull-2 {
		right: 8.33333333%;
	}
	.ant-col-xl-offset-2 {
		margin-left: 8.33333333%;
	}
	.ant-col-xl-order-2 {
		order: 2;
	}
	.ant-col-xl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}
	.ant-col-xl-push-1 {
		left: 4.16666667%;
	}
	.ant-col-xl-pull-1 {
		right: 4.16666667%;
	}
	.ant-col-xl-offset-1 {
		margin-left: 4.16666667%;
	}
	.ant-col-xl-order-1 {
		order: 1;
	}
	.ant-col-xl-0 {
		display: none;
	}
	.ant-col-push-0 {
		left: auto;
	}
	.ant-col-pull-0 {
		right: auto;
	}
	.ant-col-xl-push-0 {
		left: auto;
	}
	.ant-col-xl-pull-0 {
		right: auto;
	}
	.ant-col-xl-offset-0 {
		margin-left: 0;
	}
	.ant-col-xl-order-0 {
		order: 0;
	}
	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-xl-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-xl-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-xl-offset-0.ant-col-rtl {
		margin-right: 0;
	}
	.ant-col-xl-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}
	.ant-col-xl-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}
	.ant-col-xl-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}
	.ant-col-xl-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}
	.ant-col-xl-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}
	.ant-col-xl-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}
	.ant-col-xl-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}
	.ant-col-xl-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}
	.ant-col-xl-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}
	.ant-col-xl-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}
	.ant-col-xl-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}
	.ant-col-xl-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}
	.ant-col-xl-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}
	.ant-col-xl-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}
	.ant-col-xl-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}
	.ant-col-xl-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}
	.ant-col-xl-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}
	.ant-col-xl-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}
	.ant-col-xl-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}
	.ant-col-xl-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}
	.ant-col-xl-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}
	.ant-col-xl-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}
	.ant-col-xl-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}
	.ant-col-xl-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}
	.ant-col-xl-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}
	.ant-col-xl-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}
	.ant-col-xl-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}
	.ant-col-xl-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}
	.ant-col-xl-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}
	.ant-col-xl-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}
	.ant-col-xl-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}
	.ant-col-xl-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}
	.ant-col-xl-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}
	.ant-col-xl-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}
	.ant-col-xl-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}
	.ant-col-xl-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}
	.ant-col-xl-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}
	.ant-col-xl-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}
	.ant-col-xl-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}
	.ant-col-xl-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}
	.ant-col-xl-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}
	.ant-col-xl-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}
	.ant-col-xl-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}
	.ant-col-xl-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}
	.ant-col-xl-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}
	.ant-col-xl-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}
	.ant-col-xl-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}
	.ant-col-xl-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}
	.ant-col-xl-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}
	.ant-col-xl-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}
	.ant-col-xl-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}
	.ant-col-xl-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}
	.ant-col-xl-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}
	.ant-col-xl-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}
	.ant-col-xl-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}
	.ant-col-xl-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}
	.ant-col-xl-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}
	.ant-col-xl-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}
@media (min-width: 1600px) {
	.ant-col-xxl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.ant-col-xxl-push-24 {
		left: 100%;
	}
	.ant-col-xxl-pull-24 {
		right: 100%;
	}
	.ant-col-xxl-offset-24 {
		margin-left: 100%;
	}
	.ant-col-xxl-order-24 {
		order: 24;
	}
	.ant-col-xxl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}
	.ant-col-xxl-push-23 {
		left: 95.83333333%;
	}
	.ant-col-xxl-pull-23 {
		right: 95.83333333%;
	}
	.ant-col-xxl-offset-23 {
		margin-left: 95.83333333%;
	}
	.ant-col-xxl-order-23 {
		order: 23;
	}
	.ant-col-xxl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}
	.ant-col-xxl-push-22 {
		left: 91.66666667%;
	}
	.ant-col-xxl-pull-22 {
		right: 91.66666667%;
	}
	.ant-col-xxl-offset-22 {
		margin-left: 91.66666667%;
	}
	.ant-col-xxl-order-22 {
		order: 22;
	}
	.ant-col-xxl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}
	.ant-col-xxl-push-21 {
		left: 87.5%;
	}
	.ant-col-xxl-pull-21 {
		right: 87.5%;
	}
	.ant-col-xxl-offset-21 {
		margin-left: 87.5%;
	}
	.ant-col-xxl-order-21 {
		order: 21;
	}
	.ant-col-xxl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}
	.ant-col-xxl-push-20 {
		left: 83.33333333%;
	}
	.ant-col-xxl-pull-20 {
		right: 83.33333333%;
	}
	.ant-col-xxl-offset-20 {
		margin-left: 83.33333333%;
	}
	.ant-col-xxl-order-20 {
		order: 20;
	}
	.ant-col-xxl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}
	.ant-col-xxl-push-19 {
		left: 79.16666667%;
	}
	.ant-col-xxl-pull-19 {
		right: 79.16666667%;
	}
	.ant-col-xxl-offset-19 {
		margin-left: 79.16666667%;
	}
	.ant-col-xxl-order-19 {
		order: 19;
	}
	.ant-col-xxl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.ant-col-xxl-push-18 {
		left: 75%;
	}
	.ant-col-xxl-pull-18 {
		right: 75%;
	}
	.ant-col-xxl-offset-18 {
		margin-left: 75%;
	}
	.ant-col-xxl-order-18 {
		order: 18;
	}
	.ant-col-xxl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}
	.ant-col-xxl-push-17 {
		left: 70.83333333%;
	}
	.ant-col-xxl-pull-17 {
		right: 70.83333333%;
	}
	.ant-col-xxl-offset-17 {
		margin-left: 70.83333333%;
	}
	.ant-col-xxl-order-17 {
		order: 17;
	}
	.ant-col-xxl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}
	.ant-col-xxl-push-16 {
		left: 66.66666667%;
	}
	.ant-col-xxl-pull-16 {
		right: 66.66666667%;
	}
	.ant-col-xxl-offset-16 {
		margin-left: 66.66666667%;
	}
	.ant-col-xxl-order-16 {
		order: 16;
	}
	.ant-col-xxl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}
	.ant-col-xxl-push-15 {
		left: 62.5%;
	}
	.ant-col-xxl-pull-15 {
		right: 62.5%;
	}
	.ant-col-xxl-offset-15 {
		margin-left: 62.5%;
	}
	.ant-col-xxl-order-15 {
		order: 15;
	}
	.ant-col-xxl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}
	.ant-col-xxl-push-14 {
		left: 58.33333333%;
	}
	.ant-col-xxl-pull-14 {
		right: 58.33333333%;
	}
	.ant-col-xxl-offset-14 {
		margin-left: 58.33333333%;
	}
	.ant-col-xxl-order-14 {
		order: 14;
	}
	.ant-col-xxl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}
	.ant-col-xxl-push-13 {
		left: 54.16666667%;
	}
	.ant-col-xxl-pull-13 {
		right: 54.16666667%;
	}
	.ant-col-xxl-offset-13 {
		margin-left: 54.16666667%;
	}
	.ant-col-xxl-order-13 {
		order: 13;
	}
	.ant-col-xxl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.ant-col-xxl-push-12 {
		left: 50%;
	}
	.ant-col-xxl-pull-12 {
		right: 50%;
	}
	.ant-col-xxl-offset-12 {
		margin-left: 50%;
	}
	.ant-col-xxl-order-12 {
		order: 12;
	}
	.ant-col-xxl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}
	.ant-col-xxl-push-11 {
		left: 45.83333333%;
	}
	.ant-col-xxl-pull-11 {
		right: 45.83333333%;
	}
	.ant-col-xxl-offset-11 {
		margin-left: 45.83333333%;
	}
	.ant-col-xxl-order-11 {
		order: 11;
	}
	.ant-col-xxl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}
	.ant-col-xxl-push-10 {
		left: 41.66666667%;
	}
	.ant-col-xxl-pull-10 {
		right: 41.66666667%;
	}
	.ant-col-xxl-offset-10 {
		margin-left: 41.66666667%;
	}
	.ant-col-xxl-order-10 {
		order: 10;
	}
	.ant-col-xxl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}
	.ant-col-xxl-push-9 {
		left: 37.5%;
	}
	.ant-col-xxl-pull-9 {
		right: 37.5%;
	}
	.ant-col-xxl-offset-9 {
		margin-left: 37.5%;
	}
	.ant-col-xxl-order-9 {
		order: 9;
	}
	.ant-col-xxl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}
	.ant-col-xxl-push-8 {
		left: 33.33333333%;
	}
	.ant-col-xxl-pull-8 {
		right: 33.33333333%;
	}
	.ant-col-xxl-offset-8 {
		margin-left: 33.33333333%;
	}
	.ant-col-xxl-order-8 {
		order: 8;
	}
	.ant-col-xxl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}
	.ant-col-xxl-push-7 {
		left: 29.16666667%;
	}
	.ant-col-xxl-pull-7 {
		right: 29.16666667%;
	}
	.ant-col-xxl-offset-7 {
		margin-left: 29.16666667%;
	}
	.ant-col-xxl-order-7 {
		order: 7;
	}
	.ant-col-xxl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.ant-col-xxl-push-6 {
		left: 25%;
	}
	.ant-col-xxl-pull-6 {
		right: 25%;
	}
	.ant-col-xxl-offset-6 {
		margin-left: 25%;
	}
	.ant-col-xxl-order-6 {
		order: 6;
	}
	.ant-col-xxl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}
	.ant-col-xxl-push-5 {
		left: 20.83333333%;
	}
	.ant-col-xxl-pull-5 {
		right: 20.83333333%;
	}
	.ant-col-xxl-offset-5 {
		margin-left: 20.83333333%;
	}
	.ant-col-xxl-order-5 {
		order: 5;
	}
	.ant-col-xxl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}
	.ant-col-xxl-push-4 {
		left: 16.66666667%;
	}
	.ant-col-xxl-pull-4 {
		right: 16.66666667%;
	}
	.ant-col-xxl-offset-4 {
		margin-left: 16.66666667%;
	}
	.ant-col-xxl-order-4 {
		order: 4;
	}
	.ant-col-xxl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
	.ant-col-xxl-push-3 {
		left: 12.5%;
	}
	.ant-col-xxl-pull-3 {
		right: 12.5%;
	}
	.ant-col-xxl-offset-3 {
		margin-left: 12.5%;
	}
	.ant-col-xxl-order-3 {
		order: 3;
	}
	.ant-col-xxl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}
	.ant-col-xxl-push-2 {
		left: 8.33333333%;
	}
	.ant-col-xxl-pull-2 {
		right: 8.33333333%;
	}
	.ant-col-xxl-offset-2 {
		margin-left: 8.33333333%;
	}
	.ant-col-xxl-order-2 {
		order: 2;
	}
	.ant-col-xxl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}
	.ant-col-xxl-push-1 {
		left: 4.16666667%;
	}
	.ant-col-xxl-pull-1 {
		right: 4.16666667%;
	}
	.ant-col-xxl-offset-1 {
		margin-left: 4.16666667%;
	}
	.ant-col-xxl-order-1 {
		order: 1;
	}
	.ant-col-xxl-0 {
		display: none;
	}
	.ant-col-push-0 {
		left: auto;
	}
	.ant-col-pull-0 {
		right: auto;
	}
	.ant-col-xxl-push-0 {
		left: auto;
	}
	.ant-col-xxl-pull-0 {
		right: auto;
	}
	.ant-col-xxl-offset-0 {
		margin-left: 0;
	}
	.ant-col-xxl-order-0 {
		order: 0;
	}
	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-xxl-push-0.ant-col-rtl {
		right: auto;
	}
	.ant-col-xxl-pull-0.ant-col-rtl {
		left: auto;
	}
	.ant-col-xxl-offset-0.ant-col-rtl {
		margin-right: 0;
	}
	.ant-col-xxl-push-1.ant-col-rtl {
		right: 4.16666667%;
		left: auto;
	}
	.ant-col-xxl-pull-1.ant-col-rtl {
		right: auto;
		left: 4.16666667%;
	}
	.ant-col-xxl-offset-1.ant-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-2.ant-col-rtl {
		right: 8.33333333%;
		left: auto;
	}
	.ant-col-xxl-pull-2.ant-col-rtl {
		right: auto;
		left: 8.33333333%;
	}
	.ant-col-xxl-offset-2.ant-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-3.ant-col-rtl {
		right: 12.5%;
		left: auto;
	}
	.ant-col-xxl-pull-3.ant-col-rtl {
		right: auto;
		left: 12.5%;
	}
	.ant-col-xxl-offset-3.ant-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}
	.ant-col-xxl-push-4.ant-col-rtl {
		right: 16.66666667%;
		left: auto;
	}
	.ant-col-xxl-pull-4.ant-col-rtl {
		right: auto;
		left: 16.66666667%;
	}
	.ant-col-xxl-offset-4.ant-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-5.ant-col-rtl {
		right: 20.83333333%;
		left: auto;
	}
	.ant-col-xxl-pull-5.ant-col-rtl {
		right: auto;
		left: 20.83333333%;
	}
	.ant-col-xxl-offset-5.ant-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-6.ant-col-rtl {
		right: 25%;
		left: auto;
	}
	.ant-col-xxl-pull-6.ant-col-rtl {
		right: auto;
		left: 25%;
	}
	.ant-col-xxl-offset-6.ant-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}
	.ant-col-xxl-push-7.ant-col-rtl {
		right: 29.16666667%;
		left: auto;
	}
	.ant-col-xxl-pull-7.ant-col-rtl {
		right: auto;
		left: 29.16666667%;
	}
	.ant-col-xxl-offset-7.ant-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-8.ant-col-rtl {
		right: 33.33333333%;
		left: auto;
	}
	.ant-col-xxl-pull-8.ant-col-rtl {
		right: auto;
		left: 33.33333333%;
	}
	.ant-col-xxl-offset-8.ant-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-9.ant-col-rtl {
		right: 37.5%;
		left: auto;
	}
	.ant-col-xxl-pull-9.ant-col-rtl {
		right: auto;
		left: 37.5%;
	}
	.ant-col-xxl-offset-9.ant-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}
	.ant-col-xxl-push-10.ant-col-rtl {
		right: 41.66666667%;
		left: auto;
	}
	.ant-col-xxl-pull-10.ant-col-rtl {
		right: auto;
		left: 41.66666667%;
	}
	.ant-col-xxl-offset-10.ant-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-11.ant-col-rtl {
		right: 45.83333333%;
		left: auto;
	}
	.ant-col-xxl-pull-11.ant-col-rtl {
		right: auto;
		left: 45.83333333%;
	}
	.ant-col-xxl-offset-11.ant-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-12.ant-col-rtl {
		right: 50%;
		left: auto;
	}
	.ant-col-xxl-pull-12.ant-col-rtl {
		right: auto;
		left: 50%;
	}
	.ant-col-xxl-offset-12.ant-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}
	.ant-col-xxl-push-13.ant-col-rtl {
		right: 54.16666667%;
		left: auto;
	}
	.ant-col-xxl-pull-13.ant-col-rtl {
		right: auto;
		left: 54.16666667%;
	}
	.ant-col-xxl-offset-13.ant-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-14.ant-col-rtl {
		right: 58.33333333%;
		left: auto;
	}
	.ant-col-xxl-pull-14.ant-col-rtl {
		right: auto;
		left: 58.33333333%;
	}
	.ant-col-xxl-offset-14.ant-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-15.ant-col-rtl {
		right: 62.5%;
		left: auto;
	}
	.ant-col-xxl-pull-15.ant-col-rtl {
		right: auto;
		left: 62.5%;
	}
	.ant-col-xxl-offset-15.ant-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}
	.ant-col-xxl-push-16.ant-col-rtl {
		right: 66.66666667%;
		left: auto;
	}
	.ant-col-xxl-pull-16.ant-col-rtl {
		right: auto;
		left: 66.66666667%;
	}
	.ant-col-xxl-offset-16.ant-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-17.ant-col-rtl {
		right: 70.83333333%;
		left: auto;
	}
	.ant-col-xxl-pull-17.ant-col-rtl {
		right: auto;
		left: 70.83333333%;
	}
	.ant-col-xxl-offset-17.ant-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-18.ant-col-rtl {
		right: 75%;
		left: auto;
	}
	.ant-col-xxl-pull-18.ant-col-rtl {
		right: auto;
		left: 75%;
	}
	.ant-col-xxl-offset-18.ant-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}
	.ant-col-xxl-push-19.ant-col-rtl {
		right: 79.16666667%;
		left: auto;
	}
	.ant-col-xxl-pull-19.ant-col-rtl {
		right: auto;
		left: 79.16666667%;
	}
	.ant-col-xxl-offset-19.ant-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-20.ant-col-rtl {
		right: 83.33333333%;
		left: auto;
	}
	.ant-col-xxl-pull-20.ant-col-rtl {
		right: auto;
		left: 83.33333333%;
	}
	.ant-col-xxl-offset-20.ant-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-21.ant-col-rtl {
		right: 87.5%;
		left: auto;
	}
	.ant-col-xxl-pull-21.ant-col-rtl {
		right: auto;
		left: 87.5%;
	}
	.ant-col-xxl-offset-21.ant-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}
	.ant-col-xxl-push-22.ant-col-rtl {
		right: 91.66666667%;
		left: auto;
	}
	.ant-col-xxl-pull-22.ant-col-rtl {
		right: auto;
		left: 91.66666667%;
	}
	.ant-col-xxl-offset-22.ant-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}
	.ant-col-xxl-push-23.ant-col-rtl {
		right: 95.83333333%;
		left: auto;
	}
	.ant-col-xxl-pull-23.ant-col-rtl {
		right: auto;
		left: 95.83333333%;
	}
	.ant-col-xxl-offset-23.ant-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}
	.ant-col-xxl-push-24.ant-col-rtl {
		right: 100%;
		left: auto;
	}
	.ant-col-xxl-pull-24.ant-col-rtl {
		right: auto;
		left: 100%;
	}
	.ant-col-xxl-offset-24.ant-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}
.ant-row-rtl {
	direction: rtl;
}
.ant-image {
	position: relative;
	display: inline-block;
}
.ant-image-img {
	display: block;
	width: 100%;
	height: auto;
}
.ant-image-img-placeholder {
	background-color: #f5f5f5;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 30%;
}
.ant-image-mask {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.3s;
}
.ant-image-mask-info .anticon {
	margin-inline-end: 4px;
}
.ant-image-mask:hover {
	opacity: 1;
}
.ant-image-placeholder {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.ant-image-preview {
	pointer-events: none;
	height: 100%;
	text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.antzoom-appear {
	transform: none;
	opacity: 0;
	animation-duration: 0.3s;
	user-select: none;
}
.ant-image-preview-mask {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
	display: none;
}
.ant-image-preview-wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	outline: 0;
	-webkit-overflow-scrolling: touch;
}
.ant-image-preview-body {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
}
.ant-image-preview-img {
	max-width: 100%;
	max-height: 100%;
	vertical-align: middle;
	transform: scale3d(1, 1, 1);
	cursor: grab;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	user-select: none;
	pointer-events: auto;
}
.ant-image-preview-img-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
	display: inline-block;
	width: 1px;
	height: 50%;
	margin-right: -1px;
	content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
	cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
	transition-duration: 0s;
}
.ant-image-preview-wrap {
	z-index: 1080;
}
.ant-image-preview-operations {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: 'tnum';
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	width: 100%;
	color: rgba(255, 255, 255, 0.85);
	list-style: none;
	background: rgba(0, 0, 0, 0.1);
	pointer-events: auto;
}
.ant-image-preview-operations-operation {
	margin-left: 12px;
	padding: 12px;
	cursor: pointer;
}
.ant-image-preview-operations-operation-disabled {
	color: rgba(255, 255, 255, 0.25);
	pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
	margin-left: 0;
}
.ant-image-preview-operations-icon {
	font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
	position: absolute;
	top: 50%;
	right: 10px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	margin-top: -22px;
	color: rgba(255, 255, 255, 0.85);
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	cursor: pointer;
	pointer-events: auto;
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
	color: rgba(255, 255, 255, 0.25);
	cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon {
	cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
	font-size: 18px;
}
.ant-image-preview-switch-left {
	left: 10px;
}
.ant-image-preview-switch-right {
	right: 10px;
}
.ant-input-affix-wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	display: inline-flex;
}
.ant-input-affix-wrapper::-moz-placeholder {
	opacity: 1;
}
.ant-input-affix-wrapper::placeholder {
	color: #bfbfbf;
}
.ant-input-affix-wrapper:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	/* box-shadow: 0 0 0 2px rgb(24 144 255 / 20%); */
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-input-affix-wrapper[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
textarea.ant-input-affix-wrapper {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}
.ant-input-affix-wrapper-sm {
	padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
	direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
	z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
	background: transparent;
}
.ant-input-affix-wrapper > input.ant-input {
	padding: 0;
	border: none;
	outline: none;
}
.ant-input-affix-wrapper > input.ant-input:focus {
	/* box-shadow: inset 0 0 0 1px #303030, 0 0 0 1px rgb(255 255 255 / 40%), 0 0 0 4px rgb(31 117 203 / 48%) !important; */
}
.ant-input-affix-wrapper::before {
	width: 0;
	visibility: hidden;
	content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
	display: flex;
	flex: none;
	align-items: center;
}
.ant-input-prefix {
	margin-right: 4px;
}
.ant-input-suffix {
	margin-left: 4px;
}
.anticon.ant-input-clear-icon {
	margin: 0 4px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	vertical-align: -1px;
	cursor: pointer;
	transition: color 0.3s;
}
.anticon.ant-input-clear-icon:hover {
	color: rgba(0, 0, 0, 0.45);
}
.anticon.ant-input-clear-icon:active {
	color: rgba(0, 0, 0, 0.65);
}
.anticon.ant-input-clear-icon-hidden {
	visibility: hidden;
}
.anticon.ant-input-clear-icon:last-child {
	margin-right: 0;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
	padding: 0 !important;
	border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 1;
}
.ant-input {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
}
.ant-input::-moz-placeholder {
	opacity: 1;
}
.ant-input::placeholder {
	color: #bfbfbf;
}
.ant-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-input:hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-input:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	/* box-shadow: 0 0 0 2px rgb(24 144 255 / 20%); */
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-input.ant-input-borderless,
.ant-input.ant-input-borderless:hover,
.ant-input.ant-input-borderless:focus,
.ant-input.ant-input-borderless-focused,
.ant-input.ant-input-borderless-disabled,
.ant-input.ant-input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
textarea.ant-input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}
.ant-input-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}
.ant-input-sm {
	padding: 0px 7px;
}
.ant-input-rtl {
	direction: rtl;
}
.ant-input-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: table;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}
.ant-input-group[class*='col-'] {
	float: none;
	padding-right: 0;
	padding-left: 0;
}
.ant-input-group > [class*='col-'] {
	padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
	padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
	display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
	border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
	width: 1px;
	white-space: nowrap;
	vertical-align: middle;
}
.ant-input-group-wrap > * {
	display: block !important;
}
.ant-input-group .ant-input {
	float: left;
	width: 100%;
	margin-bottom: 0;
	text-align: inherit;
}
.ant-input-group .ant-input:focus {
	z-index: 1;
	border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
	z-index: 1;
	border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
	z-index: 0;
}
.ant-input-group-addon {
	position: relative;
	padding: 0 11px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
	text-align: center;
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
	margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background-color: inherit;
	border: 1px solid transparent;
	box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
	color: #1b59a8;
}
.ant-input-group-addon .ant-cascader-picker {
	margin: -9px -12px;
	background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
	text-align: left;
	border: 0;
	box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
	border-right: 0;
}
.ant-input-group-addon:last-child {
	border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
	padding: 6.5px 11px;
	font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
	padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
	height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
	height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.ant-input-group.ant-input-group-compact {
	display: block;
}
.ant-input-group.ant-input-group-compact::before {
	display: table;
	content: '';
}
.ant-input-group.ant-input-group-compact::after {
	display: table;
	clear: both;
	content: '';
}
.ant-input-group.ant-input-group-compact::before {
	display: table;
	content: '';
}
.ant-input-group.ant-input-group-compact::after {
	display: table;
	clear: both;
	content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
	border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
	display: inline-block;
	float: none;
	vertical-align: top;
	border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
	display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
	display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
	margin-right: -1px;
	border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
	float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
	border-right-width: 1px;
	border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
	border-right-width: 1px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
	vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
	margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
	border-radius: 0;
}
.ant-input-group.ant-input-group-compact
	.ant-input-group-wrapper:not(:last-child).ant-input-search
	> .ant-input-group
	> .ant-input-group-addon
	> .ant-input-search-button {
	border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
	border-radius: 5px 0 0 5px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
	border-radius: 0 5px 5px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
	border-right: 1px solid #d9d9d9;
	border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
	border-right: 0;
	border-left: 1px solid #d9d9d9;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
	border-radius: 5px 0 0 5px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
	border-radius: 5px 0 0 5px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
	border-radius: 0 5px 5px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
	margin-right: 0;
	margin-left: -1px;
	border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
	border-radius: 0 5px 5px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
	border-left-width: 1px;
	border-radius: 5px 0 0 5px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
	margin-right: -1px;
	margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
	border-radius: 0 5px 5px 0;
}
.ant-input-group-wrapper {
	display: inline-block;
	width: 100%;
	text-align: start;
	vertical-align: top;
}
.ant-input-password-icon {
	color: rgba(0, 0, 0, 0.45);
	cursor: pointer;
	transition: all 0.3s;
}
.ant-input-password-icon:hover {
	color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
	height: 32px;
}
.ant-input[type='color'].ant-input-lg {
	height: 40px;
}
.ant-input[type='color'].ant-input-sm {
	height: 24px;
	padding-top: 3px;
	padding-bottom: 3px;
}
.ant-input-textarea-show-count::after {
	float: right;
	color: rgba(0, 0, 0, 0.45);
	white-space: nowrap;
	content: attr(data-count);
	pointer-events: none;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
	border-color: #1b59a8;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
	border-left-color: #1b59a8;
}
.ant-input-search .ant-input-affix-wrapper {
	border-radius: 0;
}
.ant-input-search .ant-input-lg {
	line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
	left: -1px;
	padding: 0;
	border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
	padding-top: 0;
	padding-bottom: 0;
	border-radius: 0 5px 5px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
	color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.ant-input-search-button {
	height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
	z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
	height: 40px;
}
.ant-input-search-small .ant-input-search-button {
	height: 24px;
}
.ant-input-group-wrapper-rtl {
	direction: rtl;
}
.ant-input-group-rtl {
	direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
	border: none;
	outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
	margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
	margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
	direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
	text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
	margin-right: 4px;
	margin-left: 0;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
	right: auto;
	left: 8px;
}
.ant-input-search-rtl {
	direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
	border-right-color: #1b59a8;
	border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
	border-right-color: #1b59a8;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
	right: -1px;
	left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
	border-radius: 5px 0 0 5px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ant-input {
		height: 32px;
	}
	.ant-input-lg {
		height: 40px;
	}
	.ant-input-sm {
		height: 24px;
	}
	.ant-input-affix-wrapper > input.ant-input {
		height: auto;
	}
}
.ant-input-number {
	box-sizing: border-box;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	transition: all 0.3s;
	display: inline-block;
	width: 90px;
	margin: 0;
	padding: 0;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}
.ant-input-number::-moz-placeholder {
	opacity: 1;
}
.ant-input-number::placeholder {
	color: #bfbfbf;
}
.ant-input-number:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-input-number:hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-number:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input-number-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input-number-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-input-number[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input-number[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-input-number.ant-input-number-borderless,
.ant-input-number.ant-input-number-borderless:hover,
.ant-input-number.ant-input-number-borderless:focus,
.ant-input-number.ant-input-number-borderless-focused,
.ant-input-number.ant-input-number-borderless-disabled,
.ant-input-number.ant-input-number-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
textarea.ant-input-number {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}
.ant-input-number-sm {
	padding: 0px 7px;
}
.ant-input-number-rtl {
	direction: rtl;
}
.ant-input-number-handler {
	position: relative;
	display: block;
	width: 100%;
	height: 50%;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.45);
	font-weight: bold;
	line-height: 0;
	text-align: center;
	border-left: 1px solid #d9d9d9;
	transition: all 0.1s linear;
}
.ant-input-number-handler:active {
	background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
	color: #1b59a8;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	right: 4px;
	width: 12px;
	height: 12px;
	color: rgba(0, 0, 0, 0.45);
	line-height: 12px;
	transition: all 0.1s linear;
	/* user-select: none; */
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
	line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
	display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
	display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
	display: block;
}
.ant-input-number:hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-number:hover + .ant-form-item-children-icon {
	opacity: 0;
	transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-input-number-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-input-number-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input-number-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
	cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
	display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
	display: none;
}
.ant-input-number-input {
	width: 100%;
	height: 30px;
	padding: 0 11px;
	text-align: left;
	background-color: transparent;
	border: 0;
	border-radius: 2px;
	outline: 0;
	transition: all 0.3s linear;
	/* -moz-appearance: textfield !important; */
}
.ant-input-number-input::-moz-placeholder {
	opacity: 1;
}
.ant-input-number-input::placeholder {
	color: #bfbfbf;
}
.ant-input-number-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
	margin: 0;
	-webkit-appearance: none;
}
.ant-input-number-lg {
	padding: 0;
	font-size: 16px;
}
.ant-input-number-lg input {
	height: 38px;
}
.ant-input-number-sm {
	padding: 0;
}
.ant-input-number-sm input {
	height: 22px;
	padding: 0 7px;
}
.ant-input-number-handler-wrap {
	position: absolute;
	top: 0;
	right: 0;
	width: 22px;
	height: 100%;
	background: #fff;
	border-radius: 0 5px 5px 0;
	opacity: 0;
	transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
	min-width: auto;
	margin-right: 0;
	font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
	border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
	height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
	opacity: 1;
}
.ant-input-number-handler-up {
	border-top-right-radius: 5px;
	cursor: pointer;
}
.ant-input-number-handler-up-inner {
	top: 50%;
	margin-top: -5px;
	text-align: center;
}
.ant-input-number-handler-up:hover {
	height: 60% !important;
}
.ant-input-number-handler-down {
	top: 0;
	border-top: 1px solid #d9d9d9;
	border-bottom-right-radius: 5px;
	cursor: pointer;
}
.ant-input-number-handler-down-inner {
	top: 50%;
	text-align: center;
	transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
	height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
	border-top-width: 0;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
	cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
	color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
	box-shadow: none;
}
.ant-input-number-out-of-range input {
	color: #f5222d;
}
.ant-input-number-rtl {
	direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
	right: auto;
	left: 0;
	border-right: 1px solid #d9d9d9;
	border-left: 0;
	border-radius: 5px 0 0 5px;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
	border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-input {
	direction: ltr;
	text-align: right;
}
.ant-layout {
	display: flex;
	flex: auto;
	flex-direction: column;
	/* fix firefox can't set height smaller than content on flex item */
	min-height: 0;
	background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
	box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
	flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
	width: 0;
}
.ant-layout-header,
.ant-layout-footer {
	flex: 0 0 auto;
}
.ant-layout-header {
	height: 64px;
	padding: 0 50px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 64px;
	background: #001529;
}
.ant-layout-footer {
	padding: 24px 50px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	background: #f0f2f5;
}
.ant-layout-content {
	flex: auto;
	/* fix firefox can't set height smaller than content on flex item */
	min-height: 0;
}
.ant-layout-sider {
	position: relative;
	/* fix firefox can't set width smaller than content on flex item */
	min-width: 0;
	background: #fff;
	transition: all 0.2s;
}
.ant-layout-sider-children {
	height: 100%;
	margin-top: -0.1px;
	padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
	width: auto;
}
.ant-layout-sider-has-trigger {
	padding-bottom: 48px;
}
.ant-layout-sider-right {
	order: 1;
}
.ant-layout-sider-trigger {
	position: fixed;
	bottom: 0;
	z-index: 1;
	height: 48px;
	color: #fff;
	line-height: 48px;
	text-align: center;
	background: #002140;
	cursor: pointer;
	transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
	overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
	position: absolute;
	top: 64px;
	right: -36px;
	z-index: 1;
	width: 36px;
	height: 42px;
	color: #fff;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	background: #001529;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	transition: all 0.3s;
	content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
	background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
	left: -36px;
	border-radius: 5px 0 0 5px;
}
.ant-layout-sider-light {
	background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
}
.ant-layout-rtl {
	direction: rtl;
}
.ant-list {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
}
.ant-list * {
	outline: none;
}
.ant-list-pagination {
	margin-top: 24px;
	text-align: right;
}
.ant-list-pagination .ant-pagination-options {
	text-align: left;
}
.ant-list-more {
	margin-top: 12px;
	text-align: center;
}
.ant-list-more button {
	padding-right: 32px;
	padding-left: 32px;
}
.ant-list-spin {
	min-height: 40px;
	text-align: center;
}
.ant-list-empty-text {
	padding: 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	text-align: center;
}
.ant-list-items {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;
	color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta {
	display: flex;
	flex: 1;
	align-items: flex-start;
	max-width: 100%;
}
.ant-list-item-meta-avatar {
	margin-right: 16px;
}
.ant-list-item-meta-content {
	flex: 1 0;
	width: 0;
	color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta-title {
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
}
.ant-list-item-meta-title > a {
	color: rgba(0, 0, 0, 0.65);
	transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
	color: #1b59a8;
}
.ant-list-item-meta-description {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
}
.ant-list-item-action {
	flex: 0 0 auto;
	margin-left: 48px;
	padding: 0;
	font-size: 0;
	list-style: none;
}
.ant-list-item-action > li {
	position: relative;
	display: inline-block;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	text-align: center;
}
.ant-list-item-action > li:first-child {
	padding-left: 0;
}
.ant-list-item-action-split {
	position: absolute;
	top: 50%;
	right: 0;
	width: 1px;
	height: 14px;
	margin-top: -7px;
	background-color: #f0f0f0;
}
.ant-list-header {
	background: transparent;
}
.ant-list-footer {
	background: transparent;
}
.ant-list-header,
.ant-list-footer {
	padding-top: 12px;
	padding-bottom: 12px;
}
.ant-list-empty {
	padding: 16px 0;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	text-align: center;
}
.ant-list-split .ant-list-item {
	border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
	border-bottom: none;
}
.ant-list-split .ant-list-header {
	border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
	border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
	min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
	border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
	padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
	padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
	align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
	display: block;
	flex: 1;
}
.ant-list-vertical .ant-list-item-extra {
	margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
	margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
	margin-bottom: 12px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
	margin-top: 16px;
	margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
	padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
	padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
	display: block;
	max-width: 100%;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: none;
}
.ant-list-item-no-flex {
	display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
	float: right;
}
.ant-list-bordered {
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}
.ant-list-bordered .ant-list-header {
	padding-right: 24px;
	padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
	padding-right: 24px;
	padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
	padding-right: 24px;
	padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
	margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
	padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
	padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
	padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
	padding: 16px 24px;
}
@media screen and (max-width: 768px) {
	.ant-list-item-action {
		margin-left: 24px;
	}
	.ant-list-vertical .ant-list-item-extra {
		margin-left: 24px;
	}
}
@media screen and (max-width: 576px) {
	.ant-list-item {
		flex-wrap: wrap;
	}
	.ant-list-item-action {
		margin-left: 12px;
	}
	.ant-list-vertical .ant-list-item {
		flex-wrap: wrap-reverse;
	}
	.ant-list-vertical .ant-list-item-main {
		min-width: 220px;
	}
	.ant-list-vertical .ant-list-item-extra {
		margin: auto auto 16px;
	}
}
.ant-list-rtl {
	direction: rtl;
	text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
	direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
	text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
	margin-right: 0;
	margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
	margin-right: 48px;
	margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
	padding-right: 0;
	padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
	right: auto;
	left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
	margin-right: 40px;
	margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
	margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
	padding-right: 0;
	padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
	float: left;
}
@media screen and (max-width: 768px) {
	.ant-list-rtl .ant-list-item-action {
		margin-right: 24px;
		margin-left: 0;
	}
	.ant-list-rtl .ant-list-vertical .ant-list-item-extra {
		margin-right: 24px;
		margin-left: 0;
	}
}
@media screen and (max-width: 576px) {
	.ant-list-rtl .ant-list-item-action {
		margin-right: 22px;
		margin-left: 0;
	}
	.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
		margin: auto auto 16px;
	}
}
.ant-mentions {
	box-sizing: border-box;
	margin: 0;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	position: relative;
	display: inline-block;
	height: auto;
	padding: 0;
	overflow: hidden;
	line-height: 1.5715;
	white-space: pre-wrap;
	vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
	opacity: 1;
}
.ant-mentions::placeholder {
	color: #bfbfbf;
}
.ant-mentions:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-mentions:hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-mentions:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-mentions-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-mentions-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-mentions[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-mentions[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-mentions.ant-mentions-borderless,
.ant-mentions.ant-mentions-borderless:hover,
.ant-mentions.ant-mentions-borderless:focus,
.ant-mentions.ant-mentions-borderless-focused,
.ant-mentions.ant-mentions-borderless-disabled,
.ant-mentions.ant-mentions-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
textarea.ant-mentions {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}
.ant-mentions-sm {
	padding: 0px 7px;
}
.ant-mentions-rtl {
	direction: rtl;
}
.ant-mentions-disabled > textarea {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-mentions-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-mentions-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
	min-height: 30px;
	margin: 0;
	padding: 4px 11px;
	overflow: inherit;
	overflow-x: hidden;
	overflow-y: auto;
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
	font-style: inherit;
	font-variant: inherit;
	font-size-adjust: inherit;
	font-stretch: inherit;
	line-height: inherit;
	direction: inherit;
	letter-spacing: inherit;
	white-space: inherit;
	text-align: inherit;
	vertical-align: top;
	word-wrap: break-word;
	word-break: inherit;
	tab-size: inherit;
}
.ant-mentions > textarea {
	width: 100%;
	border: none;
	outline: none;
	resize: none;
}
.ant-mentions > textarea::-moz-placeholder {
	opacity: 1;
}
.ant-mentions > textarea::placeholder {
	color: #bfbfbf;
}
.ant-mentions > textarea:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-mentions-measure {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	color: transparent;
	pointer-events: none;
}
.ant-mentions-measure > span {
	display: inline-block;
	min-height: 1em;
}
.ant-mentions-dropdown {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	box-sizing: border-box;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mentions-dropdown-hidden {
	display: none;
}
.ant-mentions-dropdown-menu {
	max-height: 250px;
	margin-bottom: 0;
	padding-left: 0;
	overflow: auto;
	list-style: none;
	outline: none;
}
.ant-mentions-dropdown-menu-item {
	position: relative;
	display: block;
	min-width: 100px;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	line-height: 1.5715;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
	background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
	border-radius: 5px 5px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
	border-radius: 0 0 5px 5px;
}
.ant-mentions-dropdown-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
	color: rgba(0, 0, 0, 0.65);
	font-weight: 600;
	background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
	background-color: #f5f5f5;
}
.ant-mentions-rtl {
	direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
	color: #f5222d;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
	color: #f5222d;
}
.ant-menu-item-danger.ant-menu-item:active {
	background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
	color: #f5222d;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
	color: #f5222d;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
	background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
	border-right-color: #f5222d;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
	color: #f5222d;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
	color: #fff;
	background-color: #f5222d;
}
.ant-menu {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: 'tnum';
	margin-bottom: 0;
	padding-left: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 0;
	text-align: left;
	list-style: none;
	background: #fff;
	outline: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
	display: table;
	content: '';
}
.ant-menu::after {
	display: table;
	clear: both;
	content: '';
}
.ant-menu::before {
	display: table;
	content: '';
}
.ant-menu::after {
	display: table;
	clear: both;
	content: '';
}
.ant-menu.ant-menu-root:focus-visible {
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-menu ul,
.ant-menu ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-menu-overflow {
	display: flex;
}
.ant-menu-overflow-item {
	flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
	display: none;
}
.ant-menu-item-group-title {
	height: 1.5715;
	padding: 8px 16px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
	color: #1b59a8;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
	background: #fff4e6;
}
.ant-menu-submenu .ant-menu-sub {
	cursor: initial;
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
	transition: color 0.3s;
}
.ant-menu-item a {
	color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item a:hover {
	color: #1b59a8;
}
.ant-menu-item a::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: transparent;
	content: '';
}
.ant-menu-item > .ant-badge a {
	color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > .ant-badge a:hover {
	color: #1b59a8;
}
.ant-menu-item-divider {
	height: 1px;
	overflow: hidden;
	line-height: 0;
	background-color: #f0f0f0;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
	margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
	background-color: transparent;
}
.ant-menu-item-selected {
	color: #1b59a8;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: #1b59a8;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #fff4e6;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
	border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
	min-width: 160px;
	max-height: calc(100vh - 100px);
	padding: 0;
	overflow: hidden;
	border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
	overflow-x: hidden;
	overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
	left: 0;
	margin-left: 0;
	border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
	border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
	transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
	min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
	transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
	position: relative;
	display: block;
	margin: 0;
	padding: 0 20px;
	white-space: nowrap;
	cursor: pointer;
	transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
	min-width: 14px;
	font-size: 14px;
	transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
	margin-left: 10px;
	opacity: 1;
	transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
	vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
	margin-right: 0;
}
.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-menu > .ant-menu-item-divider {
	height: 1px;
	margin: 1px 0;
	padding: 0;
	overflow: hidden;
	line-height: 0;
	background-color: #f0f0f0;
}
.ant-menu-submenu-popup {
	position: absolute;
	z-index: 1050;
	background: transparent;
	border-radius: 2px;
	box-shadow: none;
	transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
	position: absolute;
	top: -7px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	opacity: 0.0001;
	content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
	top: 0;
	left: -7px;
}
.ant-menu-submenu > .ant-menu {
	background-color: #fff;
	border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
	background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
	position: absolute;
	top: 50%;
	right: 16px;
	width: 10px;
	color: rgba(0, 0, 0, 0.65);
	transform: translateY(-50%);
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
	position: absolute;
	width: 6px;
	height: 1.5px;
	background-color: currentColor;
	border-radius: 2px;
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	content: '';
}
.ant-menu-submenu-arrow::before {
	transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
	transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
	color: #1b59a8;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
	transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
	transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
	display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
	transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
	transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
	transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
	color: #1b59a8;
}
.ant-menu-horizontal {
	line-height: 46px;
	border: 0;
	border-bottom: 1px solid #f0f0f0;
	box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
	margin-top: -1px;
	margin-bottom: 0;
	padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
	color: #1b59a8;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
	border-bottom: 2px solid #1b59a8;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
	position: relative;
	top: 1px;
	display: inline-block;
	vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
	position: absolute;
	right: 20px;
	bottom: 0;
	left: 20px;
	border-bottom: 2px solid transparent;
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
	padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
	color: rgba(0, 0, 0, 0.65);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
	color: #1b59a8;
}
.ant-menu-horizontal > .ant-menu-item a::before {
	bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
	color: #1b59a8;
}
.ant-menu-horizontal::after {
	display: block;
	clear: both;
	height: 0;
	content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
	position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	/* border-right: 3px solid #1b59a8; */
	border: none;
	transform: scaleY(0.0001);
	opacity: 0;
	transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
	content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
	height: 40px;
	margin-top: 4px;
	margin-bottom: 4px;
	padding: 0 16px;
	overflow: hidden;
	line-height: 40px;
	text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
	padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
	margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
	/* height: 40px;
	line-height: 40px; */
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
	padding-right: 34px;
}
.ant-menu-inline {
	width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
	transform: scaleY(1);
	opacity: 1;
	transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
	width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
	padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
	display: flex;
	align-items: center;
	transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
	flex: auto;
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
	flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
	width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
	left: 0;
	padding: 0 calc(50% - 16px / 2);
	text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed
	> .ant-menu-item-group
	> .ant-menu-item-group-list
	> .ant-menu-submenu
	> .ant-menu-submenu-title
	.ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
	opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
	> .ant-menu-item-group
	> .ant-menu-item-group-list
	> .ant-menu-submenu
	> .ant-menu-submenu-title
	.ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
	margin: 0;
	font-size: 16px;
	line-height: 40px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed
	> .ant-menu-item-group
	> .ant-menu-item-group-list
	> .ant-menu-submenu
	> .ant-menu-submenu-title
	.ant-menu-item-icon
	+ span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
	display: inline-block;
	opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
	display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
	pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
	display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
	color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
	padding-right: 4px;
	padding-left: 4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-menu-item-group-list {
	margin: 0;
	padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
	padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
	box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
	font-size: 16px;
	text-align: center;
}
.ant-menu-sub.ant-menu-inline {
	padding: 0;
	background: #fafafa;
	border: 0;
	border-radius: 0;
	box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
	height: 40px;
	line-height: 40px;
	list-style-position: inside;
	list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
	padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
	color: rgba(0, 0, 0, 0.25) !important;
	background: none;
	cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
	border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
	color: rgba(0, 0, 0, 0.25) !important;
	pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
	color: rgba(0, 0, 0, 0.25) !important;
	cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
	background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
	line-height: inherit;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
	color: #1b59a8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
	color: rgba(255, 255, 255, 0.65);
	background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
	opacity: 0.45;
	transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
	background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
	background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
	background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
	border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
	top: 0;
	margin-top: 0;
	padding: 0 20px;
	border-color: #001529;
	border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
	background-color: #1b59a8;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
	bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
	color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
	border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
	left: 0;
	margin-left: 0;
	border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
	border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
	width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
	color: #fff;
	background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
	color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
	opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
	background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
	background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #1b59a8;
}
.ant-menu-dark .ant-menu-item-selected {
	color: #fff;
	border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
	border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
	color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
	color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
	color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
	background-color: #1b59a8;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
	color: rgba(255, 255, 255, 0.35) !important;
	opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
	color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
	background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
	direction: rtl;
	text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
	text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
	border-right: none;
	border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
	border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
	transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
	margin-right: auto;
	margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
	margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
	transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
	right: auto;
	left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
	transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
	transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
	right: auto;
	left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
	text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
	padding-right: 0;
	padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
	padding-right: 16px;
	padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
	padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
	padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
	border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
	padding-right: 32px;
	padding-left: 0;
}
.ant-message {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: fixed;
	top: 8px;
	left: 0;
	z-index: 1010;
	width: 100%;
	pointer-events: none;
}
.ant-message-notice {
	padding: 8px;
	text-align: center;
}
.ant-message-notice-content {
	display: inline-block;
	padding: 10px 16px;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	pointer-events: all;
}
.ant-message-success .anticon {
	color: #52c41a;
}
.ant-message-error .anticon {
	color: #f5222d;
}
.ant-message-warning .anticon {
	color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
	color: #1b59a8;
}
.ant-message .anticon {
	position: relative;
	top: 1px;
	margin-right: 8px;
	font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
	animation-name: MessageMoveOut;
	animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
	0% {
		max-height: 150px;
		padding: 8px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		padding: 0;
		opacity: 0;
	}
}
.ant-message-rtl {
	direction: rtl;
}
.ant-message-rtl span {
	direction: rtl;
}
.ant-message-rtl .anticon {
	margin-right: 0;
	margin-left: 8px;
}
.ant-modal {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	pointer-events: none;
	position: relative;
	top: 100px;
	width: auto;
	max-width: calc(100vw - 32px);
	margin: 0 auto;
	padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.antzoom-appear {
	transform: none;
	opacity: 0;
	animation-duration: 0.3s;
	user-select: none;
}
.ant-modal-mask {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
	display: none;
}
.ant-modal-wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	outline: 0;
	-webkit-overflow-scrolling: touch;
}
.ant-modal-wrap {
	z-index: 1000;
}
.ant-modal-title {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	word-wrap: break-word;
}
.ant-modal-content {
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	pointer-events: auto;
}
.ant-modal-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	padding: 0;
	color: rgba(0, 0, 0, 0.45);
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	background: transparent;
	border: 0;
	outline: 0;
	cursor: pointer;
	transition: color 0.3s;
}
.ant-modal-close-x {
	display: block;
	width: 56px;
	height: 56px;
	font-size: 16px;
	font-style: normal;
	line-height: 56px;
	text-align: center;
	text-transform: none;
	text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
	color: rgba(0, 0, 0, 0.75);
	text-decoration: none;
}
.ant-modal-header {
	padding: 16px 24px;
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
	border-bottom: 1px solid #f0f0f0;
	border-radius: 5px 5px 0 0;
}
.ant-modal-body {
	padding: 24px;
	font-size: 14px;
	line-height: 1.5715;
	word-wrap: break-word;
}
.ant-modal-footer {
	padding: 10px 16px;
	text-align: right;
	background: transparent;
	border-top: 1px solid #f0f0f0;
	border-radius: 0 0 5px 5px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
	margin-bottom: 0;
	margin-left: 8px;
}
.ant-modal-open {
	overflow: hidden;
}
.ant-modal-centered {
	text-align: center;
}
.ant-modal-centered::before {
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
	content: '';
}
.ant-modal-centered .ant-modal {
	top: 0;
	display: inline-block;
	text-align: left;
	vertical-align: middle;
}
@media (max-width: 767px) {
	.ant-modal {
		max-width: calc(100vw - 16px);
		margin: 8px auto;
	}
	.ant-modal-centered .ant-modal {
		flex: 1;
	}
}
.ant-modal-confirm .ant-modal-header {
	display: none;
}
.ant-modal-confirm .ant-modal-body {
	padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
	display: table;
	content: '';
}
.ant-modal-confirm-body-wrapper::after {
	display: table;
	clear: both;
	content: '';
}
.ant-modal-confirm-body-wrapper::before {
	display: table;
	content: '';
}
.ant-modal-confirm-body-wrapper::after {
	display: table;
	clear: both;
	content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
	display: block;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
	margin-top: 8px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
	float: left;
	margin-right: 16px;
	font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
	margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
	float: right;
	margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
	margin-bottom: 0;
	margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
	color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
	color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
	color: #1b59a8;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
	color: #52c41a;
}
.ant-modal-wrap-rtl {
	direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
	right: initial;
	left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
	text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
	margin-right: 8px;
	margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
	direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
	float: right;
	margin-right: 0;
	margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
	margin-right: 38px;
	margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
	float: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
	margin-right: 8px;
	margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
	text-align: right;
}
.ant-notification {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: fixed;
	z-index: 1010;
	margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
	margin-right: 0;
	margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
	animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
	font-size: 14px;
	cursor: pointer;
}
.ant-notification-hook-holder {
	position: relative;
}
.ant-notification-notice {
	position: relative;
	width: 384px;
	max-width: calc(100vw - 24px * 2);
	margin-bottom: 16px;
	margin-left: auto;
	padding: 16px 24px;
	overflow: hidden;
	line-height: 1.5715;
	word-wrap: break-word;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
	margin-right: auto;
	margin-left: 0;
}
.ant-notification-notice-message {
	margin-bottom: 8px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
	display: block;
	width: calc(384px - 24px * 2 - 24px - 48px - 100%);
	max-width: 4px;
	background-color: transparent;
	pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
	display: block;
	content: '';
}
.ant-notification-notice-description {
	font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
	padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
	margin-bottom: 4px;
	margin-left: 48px;
	font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
	margin-left: 48px;
	font-size: 14px;
}
.ant-notification-notice-icon {
	position: absolute;
	margin-left: 4px;
	font-size: 24px;
	line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
	color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
	color: #1b59a8;
}
.anticon.ant-notification-notice-icon-warning {
	color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
	color: #f5222d;
}
.ant-notification-notice-close {
	position: absolute;
	top: 16px;
	right: 22px;
	color: rgba(0, 0, 0, 0.45);
	outline: none;
}
.ant-notification-notice-close:hover {
	color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
	float: right;
	margin-top: 16px;
}
.ant-notification .notification-fade-effect {
	animation-duration: 0.24s;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
	animation-duration: 0.24s;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-fill-mode: both;
	opacity: 0;
	animation-play-state: paused;
}
.ant-notification-fade-leave {
	animation-duration: 0.24s;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-fill-mode: both;
	animation-duration: 0.2s;
	animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
	animation-name: NotificationFadeIn;
	animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
	animation-name: NotificationFadeOut;
	animation-play-state: running;
}
@keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}
@keyframes NotificationLeftFadeIn {
	0% {
		right: 384px;
		opacity: 0;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}
@keyframes NotificationFadeOut {
	0% {
		max-height: 150px;
		margin-bottom: 16px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		opacity: 0;
	}
}
.ant-notification-rtl {
	direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
	padding-right: 0;
	padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
	margin-right: 48px;
	margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
	margin-right: 48px;
	margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
	margin-right: 4px;
	margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
	right: auto;
	left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
	float: left;
}
.ant-page-header {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	padding: 16px 24px;
	background-color: #fff;
}
.ant-page-header-ghost {
	background-color: inherit;
}
.ant-page-header.has-breadcrumb {
	padding-top: 12px;
}
.ant-page-header.has-footer {
	padding-bottom: 0;
}
.ant-page-header-back {
	margin-right: 16px;
	font-size: 16px;
	line-height: 1;
}
.ant-page-header-back-button {
	color: #5c987a;
	text-decoration: none;
	outline: none;
	transition: color 0.3s;
	color: #000;
	cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
	color: #80a691;
}
.ant-page-header-back-button:active {
	color: #40735b;
}
.ant-page-header .ant-divider-vertical {
	height: 14px;
	margin: 0 12px;
	vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
	margin-top: 8px;
}
.ant-page-header-heading {
	display: flex;
	justify-content: space-between;
}
.ant-page-header-heading-left {
	display: flex;
	align-items: center;
	margin: 4px 0;
	overflow: hidden;
}
.ant-page-header-heading-title {
	margin-right: 12px;
	margin-bottom: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
	margin-right: 12px;
}
.ant-page-header-heading-sub-title {
	margin-right: 12px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
	margin: 4px 0;
	white-space: nowrap;
}
.ant-page-header-heading-extra > * {
	margin-left: 12px;
	white-space: unset;
}
.ant-page-header-heading-extra > *:first-child {
	margin-left: 0;
}
.ant-page-header-content {
	padding-top: 12px;
}
.ant-page-header-footer {
	margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
	margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
	border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
	flex-wrap: wrap;
}
.ant-page-header-rtl {
	direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
	float: right;
	margin-right: 0;
	margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
	margin-right: 0;
	margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
	margin-right: 0;
	margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
	float: right;
	margin-right: 0;
	margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
	float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
	float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
	margin-right: 12px;
	margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
	margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
	float: right;
}
.ant-pagination {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
}
.ant-pagination ul,
.ant-pagination ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-pagination::after {
	display: block;
	clear: both;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	content: ' ';
}
.ant-pagination-total-text {
	display: inline-block;
	height: 32px;
	margin-right: 8px;
	line-height: 30px;
	vertical-align: middle;
}
.ant-pagination-item {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	margin-right: 8px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: 0;
	cursor: pointer;
	user-select: none;
}
.ant-pagination-item a {
	display: block;
	padding: 0 6px;
	color: rgba(0, 0, 0, 0.65);
	transition: none;
}
.ant-pagination-item a:hover {
	text-decoration: none;
}
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
	border-color: #1b59a8;
	transition: all 0.3s;
}
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
	color: #1b59a8;
}
.ant-pagination-item-active {
	font-weight: 500;
	background: #fff;
	border-color: #1b59a8;
}
.ant-pagination-item-active a {
	color: #1b59a8;
}
.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
	border-color: #1b59a8;
}
.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
	color: #1b59a8;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
	position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
	color: #1b59a8;
	font-size: 12px;
	letter-spacing: -1px;
	opacity: 0;
	transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	margin: auto;
	color: rgba(0, 0, 0, 0.25);
	/* font-family: Arial, Helvetica, sans-serif; */
	letter-spacing: 2px;
	text-align: center;
	text-indent: 0.13em;
	opacity: 1;
	transition: all 0.2s;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
	opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
	opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 32px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
	/* font-family: Arial, Helvetica, sans-serif; */
	outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
	color: rgba(0, 0, 0, 0.65);
	cursor: pointer;
	user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
	border-color: #1b59a8;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	display: block;
	width: 100%;
	height: 100%;
	padding: 0;
	font-size: 12px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: none;
	transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
	color: #1b59a8;
	border-color: #1b59a8;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus-visible {
	cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-pagination-slash {
	margin: 0 10px 0 5px;
}
.ant-pagination-options {
	display: inline-block;
	margin-left: 16px;
	vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
	.ant-pagination-options *::-ms-backdrop,
	.ant-pagination-options {
		vertical-align: top;
	}
}
.ant-pagination-options-size-changer.ant-select {
	display: inline-block;
	width: auto;
}
.ant-pagination-options-quick-jumper {
	display: inline-block;
	height: 32px;
	margin-left: 8px;
	line-height: 32px;
	vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	width: 50px;
	height: 32px;
	margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
	opacity: 1;
}
.ant-pagination-options-quick-jumper input::placeholder {
	color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input.ant-pagination-options-quick-jumper input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
	padding: 0px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
	direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
	height: 24px;
	line-height: 24px;
	vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
	height: 24px;
	background-color: transparent;
	border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
	height: 24px;
	line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
	display: inline-block;
	height: 24px;
	margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
	box-sizing: border-box;
	height: 100%;
	margin-right: 8px;
	padding: 0 6px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: none;
	transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
	border-color: #1b59a8;
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
	height: 24px;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
	min-width: 24px;
	height: 24px;
	margin: 0;
	line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
	background: transparent;
	border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
	min-width: 24px;
	height: 24px;
	margin: 0;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
	background: transparent;
	border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
	height: 24px;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
	height: 24px;
	margin-right: 0;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
	margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-size-changer {
	top: 0px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
	height: 24px;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
	padding: 0px 7px;
	width: 44px;
	height: 24px;
}
.ant-pagination.ant-pagination-disabled {
	cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border: none;
	cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
	background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
	color: rgba(0, 0, 0, 0.25);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
	background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
	opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
	opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
	color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
	.ant-pagination-item-after-jump-prev,
	.ant-pagination-item-before-jump-next {
		display: none;
	}
}
@media only screen and (max-width: 576px) {
	.ant-pagination-options {
		display: none;
	}
}
.ant-pagination-rtl .ant-pagination-total-text {
	margin-right: 0;
	margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
	margin-right: 0;
	margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
	margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
	margin-right: 16px;
	margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
	margin-right: 0;
	margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
	margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
	margin-right: 0;
	margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
	margin-right: 0;
	margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
	margin-right: 2px;
	margin-left: 0;
}
.ant-popconfirm {
	z-index: 1060;
}
.ant-popover {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1030;
	font-weight: normal;
	white-space: normal;
	text-align: left;
	cursor: auto;
	user-select: text;
}
.ant-popover::after {
	position: absolute;
	background: rgba(255, 255, 255, 0.01);
	content: '';
}
.ant-popover-hidden {
	display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
	padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
	padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
	padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
	padding-right: 10px;
}
.ant-popover-inner {
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ant-popover {
		/* IE10+ */
	}
	.ant-popover-inner {
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	}
}
.ant-popover-title {
	min-width: 177px;
	min-height: 32px;
	margin: 0;
	padding: 5px 16px 4px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
	padding: 12px 16px;
	color: rgba(0, 0, 0, 0.65);
}
.ant-popover-message {
	position: relative;
	padding: 4px 0 12px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
}
.ant-popover-message > .anticon {
	position: absolute;
	top: 8.0005px;
	color: #faad14;
	font-size: 14px;
}
.ant-popover-message-title {
	padding-left: 22px;
}
.ant-popover-buttons {
	margin-bottom: 4px;
	text-align: right;
}
.ant-popover-buttons button {
	margin-left: 8px;
}
.ant-popover-arrow {
	position: absolute;
	display: block;
	width: 8.48528137px;
	height: 8.48528137px;
	overflow: hidden;
	background: transparent;
	pointer-events: none;
}
.ant-popover-arrow-content {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 6px;
	height: 6px;
	margin: auto;
	background-color: #fff;
	content: '';
	pointer-events: auto;
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
	bottom: 1.51471863px;
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
	transform: translateY(-4.24264069px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
	left: 50%;
	transform: translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
	left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
	right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
	left: 1.51471863px;
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
	box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
	transform: translateX(4.24264069px) rotate(45deg);
}
.ant-popover-placement-right .ant-popover-arrow {
	top: 50%;
	transform: translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
	top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
	bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
	top: 1.51471863px;
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
	box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
	transform: translateY(4.24264069px) rotate(45deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
	left: 50%;
	transform: translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
	left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
	right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
	right: 1.51471863px;
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
	box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
	transform: translateX(-4.24264069px) rotate(45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
	top: 50%;
	transform: translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
	top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
	bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
	background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
	background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
	background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
	background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
	background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
	background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
	background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
	background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
	background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
	background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
	background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
	background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
	background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
	background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
	background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
	background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
	background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
	background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
	background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
	background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
	background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
	background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
	background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
	background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
	background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
	background-color: #722ed1;
}
.ant-popover-rtl {
	direction: rtl;
	text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
	padding-right: 22px;
	padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
	text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
	margin-right: 8px;
	margin-left: 0;
}
.ant-progress {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
}
.ant-progress-line {
	position: relative;
	width: 100%;
	font-size: 14px;
}
.ant-progress-steps {
	display: inline-block;
}
.ant-progress-steps-outer {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.ant-progress-steps-item {
	flex-shrink: 0;
	min-width: 2px;
	margin-right: 2px;
	background: #f3f3f3;
	transition: all 0.3s;
}
.ant-progress-steps-item-active {
	background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
	font-size: 12px;
}
.ant-progress-outer {
	display: inline-block;
	width: 100%;
	margin-right: 0;
	padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
	margin-right: calc(-2em - 8px);
	padding-right: calc(2em + 8px);
}
.ant-progress-inner {
	position: relative;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	vertical-align: middle;
	background-color: #f5f5f5;
	border-radius: 100px;
}
.ant-progress-circle-trail {
	stroke: #f5f5f5;
}
.ant-progress-circle-path {
	animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
	stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
	position: relative;
	background-color: #1890ff;
	border-radius: 100px;
	transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #52c41a;
}
.ant-progress-text {
	display: inline-block;
	width: 2em;
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 1em;
	line-height: 1;
	white-space: nowrap;
	text-align: left;
	vertical-align: middle;
	word-break: normal;
}
.ant-progress-text .anticon {
	font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #fff;
	border-radius: 10px;
	opacity: 0;
	animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
	content: '';
}
.ant-progress-status-exception .ant-progress-bg {
	background-color: #f5222d;
}
.ant-progress-status-exception .ant-progress-text {
	color: #f5222d;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
	stroke: #f5222d;
}
.ant-progress-status-success .ant-progress-bg {
	background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
	color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
	stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
	position: relative;
	line-height: 1;
	background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 1em;
	line-height: 1;
	white-space: normal;
	text-align: center;
	transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
	font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
	color: #f5222d;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
	color: #52c41a;
}
@keyframes ant-progress-active {
	0% {
		transform: translateX(-100%) scaleX(0);
		opacity: 0.1;
	}
	20% {
		transform: translateX(-100%) scaleX(0);
		opacity: 0.5;
	}
	100% {
		transform: translateX(0) scaleX(1);
		opacity: 0;
	}
}
.ant-progress-rtl {
	direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
	margin-right: 0;
	margin-left: calc(-2em - 8px);
	padding-right: 0;
	padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
	right: 0;
	left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
	margin-right: 8px;
	margin-left: 0;
	text-align: right;
}
.ant-radio-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
	font-size: 0;
}
.ant-radio-group .ant-badge-count {
	z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
	border-left: none;
}
.ant-radio-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-flex;
	align-items: baseline;
	margin-right: 8px;
	cursor: pointer;
}
.ant-radio-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-radio {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	top: 0.2em;
	display: inline-block;
	outline: none;
	cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: #1b59a8;
}
.ant-radio-input:focus + .ant-radio-inner {
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-radio-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #1b59a8;
	border-radius: 50%;
	visibility: hidden;
	animation: antRadioEffect 0.36s ease-in-out;
	animation-fill-mode: both;
	content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
	visibility: visible;
}
.ant-radio-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}
.ant-radio-inner::after {
	background-color: #1b59a8;
}
.ant-radio-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
	border-color: #1b59a8;
}
.ant-radio-disabled {
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
	background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
	cursor: not-allowed;
}
.ant-radio-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
span.ant-radio + * {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-radio-button-wrapper {
	position: relative;
	display: inline-block;
	height: 32px;
	margin: 0;
	padding: 0 15px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 30px;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-top-width: 1.02px;
	border-left-width: 0;
	cursor: pointer;
	transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
	color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
	height: 40px;
	font-size: 16px;
	line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
	height: 24px;
	padding: 0 7px;
	line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
	position: absolute;
	top: -1px;
	left: -1px;
	display: block;
	box-sizing: content-box;
	width: 1px;
	height: 100%;
	padding: 1px 0;
	background-color: #d9d9d9;
	transition: background-color 0.3s;
	content: '';
}
.ant-radio-button-wrapper:first-child {
	border-left: 1px solid #d9d9d9;
	border-radius: 5px 0 0 5px;
}
.ant-radio-button-wrapper:last-child {
	border-radius: 0 5px 5px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
	border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
	position: relative;
	color: #1b59a8;
}
.ant-radio-button-wrapper:focus-within {
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	z-index: 1;
	color: #1b59a8;
	background: #fff;
	border-color: #1b59a8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background-color: #1b59a8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	border-color: #1b59a8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #1b59a8;
	border-color: #1b59a8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
	background-color: #1b59a8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #d94f00;
	border-color: #d94f00;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
	background-color: #d94f00;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #fff;
	background: #1b59a8;
	border-color: #1b59a8;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #fff;
	background: #1b59a8;
	border-color: #1b59a8;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #fff;
	background: #d94f00;
	border-color: #d94f00;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-radio-button-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
	border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
	color: rgba(0, 0, 0, 0.25);
	background-color: #e6e6e6;
	border-color: #d9d9d9;
	box-shadow: none;
}
@keyframes antRadioEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
.ant-radio-group.ant-radio-group-rtl {
	direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
	margin-right: 0;
	margin-left: 8px;
	direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
	border-right-width: 0;
	border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
	right: -1px;
	left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
	border-right: 1px solid #d9d9d9;
	border-radius: 0 5px 5px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	border-right-color: #1b59a8;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
	border-radius: 5px 0 0 5px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
	border-right-color: #d9d9d9;
}
.ant-rate {
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: 'tnum';
	display: inline-block;
	margin: 0;
	padding: 0;
	color: #fadb14;
	font-size: 20px;
	line-height: unset;
	list-style: none;
	outline: none;
}
.ant-rate-disabled .ant-rate-star {
	cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
	transform: scale(1);
}
.ant-rate-star {
	position: relative;
	display: inline-block;
	color: inherit;
	cursor: pointer;
}
.ant-rate-star:not(:last-child) {
	margin-right: 8px;
}
.ant-rate-star > div {
	transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus-visible {
	transform: scale(1.1);
}
.ant-rate-star > div:focus {
	outline: 0;
}
.ant-rate-star > div:focus-visible {
	outline: 1px dashed #fadb14;
}
.ant-rate-star-first,
.ant-rate-star-second {
	color: #f0f0f0;
	transition: all 0.3s;
	user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
	vertical-align: middle;
}
.ant-rate-star-first {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	overflow: hidden;
	opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
	opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
	color: inherit;
}
.ant-rate-text {
	display: inline-block;
	margin: 0 8px;
	font-size: 14px;
}
.ant-rate-rtl {
	direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
	margin-right: 0;
	margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
	right: 0;
	left: auto;
}
.ant-result {
	padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
	color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
	color: #f5222d;
}
.ant-result-info .ant-result-icon > .anticon {
	color: #1b59a8;
}
.ant-result-warning .ant-result-icon > .anticon {
	color: #faad14;
}
.ant-result-image {
	width: 250px;
	height: 295px;
	margin: auto;
}
.ant-result-icon {
	margin-bottom: 24px;
	text-align: center;
}
.ant-result-icon > .anticon {
	font-size: 72px;
}
.ant-result-title {
	color: rgba(0, 0, 0, 0.85);
	font-size: 24px;
	line-height: 1.8;
	text-align: center;
}
.ant-result-subtitle {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.6;
	text-align: center;
}
.ant-result-extra {
	margin: 24px 0 0 0;
	text-align: center;
}
.ant-result-extra > * {
	margin-right: 8px;
}
.ant-result-extra > *:last-child {
	margin-right: 0;
}
.ant-result-content {
	margin-top: 24px;
	padding: 24px 40px;
	background-color: #fafafa;
}
.ant-result-rtl {
	direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
	margin-right: 0;
	margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
	margin-left: 0;
}
.ant-select-single .ant-select-selector {
	display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
	position: absolute;
	top: 0;
	right: 11px;
	bottom: 0;
	left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
	width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	padding: 0;
	line-height: 30px;
	transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 30px;
	}
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
	position: relative;
	user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
	display: inline-block;
	width: 0;
	visibility: hidden;
	content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
	color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	width: 100%;
	height: 32px;
	padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
	line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
	display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
	position: static;
	width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
	position: absolute;
	right: 0;
	left: 0;
	padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
	display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
	line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
	height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
	height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
	line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
	height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
	right: 7px;
	left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
	right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
	position: relative;
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	max-width: 100%;
}
.ant-select-selection-overflow-item {
	flex: none;
	align-self: center;
	max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
	cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
	display: inline-block;
	width: 0;
	margin: 2px 0;
	line-height: 24px;
	content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
	padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
	position: relative;
	display: flex;
	flex: none;
	box-sizing: border-box;
	max-width: 100%;
	height: 24px;
	margin-top: 2px;
	margin-bottom: 2px;
	line-height: 22px;
	background: #f5f5f5;
	border: 1px solid #f0f0f0;
	border-radius: 2px;
	cursor: default;
	transition: font-size 0.3s, line-height 0.3s, height 0.3s;
	user-select: none;
	margin-inline-end: 4px;
	padding-inline-start: 8px;
	padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
	color: #bfbfbf;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
	display: inline-block;
	margin-right: 4px;
	overflow: hidden;
	white-space: pre;
	text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;
	color: rgba(0, 0, 0, 0.45);
	font-weight: bold;
	font-size: 10px;
	line-height: inherit;
	cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
	line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
	display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
	display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
	display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
	vertical-align: -0.2em;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
	color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
	margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
	position: relative;
	max-width: 100%;
	margin-top: 2px;
	margin-bottom: 2px;
	margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
	height: 24px;
	
	line-height: 24px;
	transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
	width: 100%;
	min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	white-space: pre;
	visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
	position: absolute;
	top: 50%;
	right: 11px;
	left: 11px;
	transform: translateY(-50%);
	transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
	line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
	height: 32px;
	line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
	height: 32px;
	line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
	height: 32px;
	line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
	line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
	height: 16px;
	line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
	height: 16px;
	line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
	height: 16px;
	line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
	left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
	margin-inline-start: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
	height: 32px;
	line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
	display: none;
}
/* Reset search input style */
.ant-select {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: #1b59a8;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
	appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
	display: none;
	-webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #1b59a8;
	border-right-width: 1px !important;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-right-width: 0;
	border-left-width: 1px !important;
}
.ant-select-selection-item {
	flex: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
	.ant-select-selection-item *::-ms-backdrop,
	.ant-select-selection-item {
		flex: auto;
	}
}
.ant-select-selection-placeholder {
	flex: 1;
	overflow: hidden;
	color: #bfbfbf;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
	.ant-select-selection-placeholder *::-ms-backdrop,
	.ant-select-selection-placeholder {
		flex: auto;
	}
}
.ant-select-arrow {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	top: 50%;
	right: 11px;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 1;
	text-align: center;
	pointer-events: none;
}
.ant-select-arrow > * {
	line-height: 1;
}
.ant-select-arrow svg {
	display: inline-block;
}
.ant-select-arrow::before {
	display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
	display: block;
}
.ant-select-arrow .anticon {
	vertical-align: top;
	transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
	vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
	pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
	cursor: not-allowed;
}
.ant-select-clear {
	position: absolute;
	top: 50%;
	right: 11px;
	z-index: 1;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	font-style: normal;
	line-height: 1;
	text-align: center;
	text-transform: none;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	transition: color 0.3s ease, opacity 0.15s ease;
	text-rendering: auto;
}
.ant-select-clear::before {
	display: block;
}
.ant-select-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
	opacity: 1;
}
.ant-select-dropdown {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	box-sizing: border-box;
	padding: 4px 0;
	overflow: hidden;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
	animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
	animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
	animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
	animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
	display: none;
}
.ant-select-dropdown-empty {
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;
	transition: background 0.3s ease;
}
.ant-select-item-group {
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	cursor: default;
}
.ant-select-item-option {
	display: flex;
}
.ant-select-item-option-content {
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-select-item-option-state {
	flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: rgba(0, 0, 0, 0.65);
	font-weight: 600;
	background-color: #fff4e6;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
	color: #1b59a8;
}
.ant-select-item-option-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
	background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
	padding-left: 24px;
}
.ant-select-lg {
	font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}
.ant-select-rtl {
	direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
	right: initial;
	left: 11px;
}
.ant-select-rtl .ant-select-clear {
	right: initial;
	left: 11px;
}
.ant-select-dropdown-rtl {
	direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
	padding-right: 24px;
	padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
	padding-right: 4px;
	padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
	text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
	margin-right: 0;
	margin-left: 4px;
	text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
	right: 0;
	left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
	right: 11px;
	left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
	right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	right: 0;
	left: 9px;
	text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 11px;
	left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 0;
	padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
	right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 0;
	padding-left: 21px;
}
.ant-skeleton {
	display: table;
	width: 100%;
}
.ant-skeleton-header {
	display: table-cell;
	padding-right: 16px;
	vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 32px;
	height: 32px;
	line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
	border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
	width: 40px;
	height: 40px;
	line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
	border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
	width: 24px;
	height: 24px;
	line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
	border-radius: 50%;
}
.ant-skeleton-content {
	display: table-cell;
	width: 100%;
	vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
	width: 100%;
	height: 16px;
	margin-top: 16px;
	background: rgba(190, 190, 190, 0.2);
	border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
	margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
	padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
	width: 100%;
	height: 16px;
	list-style: none;
	background: rgba(190, 190, 190, 0.2);
	border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
	width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
	margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
	margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
	margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
	border-radius: 100px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
	background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
	background-size: 400% 100%;
	animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
	background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
	background-size: 400% 100%;
	animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
	background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
	background-size: 400% 100%;
	animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
	background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
	background-size: 400% 100%;
	animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
	background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
	background-size: 400% 100%;
	animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton-element {
	display: inline-block;
	width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	border-radius: 2px;
	width: 64px;
	height: 32px;
	line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
	width: 32px;
	border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
	border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
	width: 80px;
	height: 40px;
	line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
	width: 40px;
	border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
	border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
	width: 48px;
	height: 24px;
	line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
	width: 24px;
	border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
	border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 32px;
	height: 32px;
	line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
	border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
	width: 40px;
	height: 40px;
	line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
	border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
	width: 24px;
	height: 24px;
	line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
	border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 100%;
	height: 32px;
	line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
	width: 100%;
	height: 40px;
	line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
	width: 100%;
	height: 24px;
	line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 96px;
	height: 96px;
	line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
	border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
	fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
	width: 48px;
	height: 48px;
	line-height: 48px;
	max-width: 192px;
	max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
	border-radius: 50%;
}
@keyframes ant-skeleton-loading {
	0% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}
.ant-skeleton-rtl {
	direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
	padding-right: 0;
	padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
	animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
	animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}
.ant-slider {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	height: 12px;
	margin: 10px 6px 10px;
	padding: 4px 0;
	cursor: pointer;
	touch-action: none;
}
.ant-slider-vertical {
	width: 12px;
	height: 100%;
	margin: 6px 10px;
	padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
	width: 4px;
	height: 100%;
}
.ant-slider-vertical .ant-slider-track {
	width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
	margin-top: -6px;
	margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
	top: 0;
	left: 12px;
	width: 18px;
	height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
	left: 4px;
	white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
	width: 4px;
	height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
	top: auto;
	left: 2px;
	margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
	min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
	margin-right: -5px;
	margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
	right: 12px;
	left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
	right: 4px;
	left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
	right: 2px;
	left: auto;
}
.ant-slider-with-marks {
	margin-bottom: 28px;
}
.ant-slider-rail {
	position: absolute;
	width: 100%;
	height: 4px;
	background-color: #f5f5f5;
	border-radius: 2px;
	transition: background-color 0.3s;
}
.ant-slider-track {
	position: absolute;
	height: 4px;
	background-color: #ffbd7a;
	border-radius: 2px;
	transition: background-color 0.3s;
}
.ant-slider-handle {
	position: absolute;
	width: 14px;
	height: 14px;
	margin-top: -5px;
	background-color: #fff;
	border: solid 2px #ffbd7a;
	border-radius: 50%;
	box-shadow: 0;
	cursor: pointer;
	transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
	border-color: #ff8333;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-slider-handle:focus {
	border-color: #ff8333;
	outline: none;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-slider-handle.ant-tooltip-open {
	border-color: #1b59a8;
}
.ant-slider:hover .ant-slider-rail {
	background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
	background-color: #ffa352;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
	border-color: #ffa352;
}
.ant-slider-mark {
	position: absolute;
	top: 14px;
	left: 0;
	width: 100%;
	font-size: 14px;
}
.ant-slider-mark-text {
	position: absolute;
	display: inline-block;
	color: rgba(0, 0, 0, 0.45);
	text-align: center;
	word-break: keep-all;
	cursor: pointer;
	user-select: none;
}
.ant-slider-mark-text-active {
	color: rgba(0, 0, 0, 0.65);
}
.ant-slider-step {
	position: absolute;
	width: 100%;
	height: 4px;
	background: transparent;
}
.ant-slider-dot {
	position: absolute;
	top: -2px;
	width: 8px;
	height: 8px;
	margin-left: -4px;
	background-color: #fff;
	border: 2px solid #f0f0f0;
	border-radius: 50%;
	cursor: pointer;
}
.ant-slider-dot:first-child {
	margin-left: -4px;
}
.ant-slider-dot:last-child {
	margin-left: -4px;
}
.ant-slider-dot-active {
	border-color: #ffb280;
}
.ant-slider-disabled {
	cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
	background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
	background-color: #fff;
	border-color: rgba(0, 0, 0, 0.25) !important;
	box-shadow: none;
	cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
	cursor: not-allowed !important;
}
.ant-slider-rtl {
	direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
	right: 0;
	left: auto;
}
.ant-slider-rtl .ant-slider-dot {
	margin-right: -4px;
	margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:first-child {
	margin-right: -4px;
	margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:last-child {
	margin-right: -4px;
	margin-left: 0;
}
.ant-space {
	display: inline-flex;
}
.ant-space-vertical {
	flex-direction: column;
}
.ant-space-align-center {
	align-items: center;
}
.ant-space-align-start {
	align-items: flex-start;
}
.ant-space-align-end {
	align-items: flex-end;
}
.ant-space-align-baseline {
	align-items: baseline;
}
.ant-space-item:empty {
	display: none;
}
.ant-space-rtl {
	direction: rtl;
}
.ant-spin {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	display: none;
	color: #1b59a8;
	text-align: center;
	vertical-align: middle;
	opacity: 0;
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
	position: static;
	display: inline-block;
	opacity: 1;
}
.ant-spin-nested-loading {
	position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	display: block;
	width: 100%;
	height: 100%;
	max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
	position: absolute;
	top: 50%;
	width: 100%;
	padding-top: 5px;
	text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
	margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
	margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
	padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
	margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
	margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
	padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
	margin-top: -26px;
}
.ant-spin-container {
	position: relative;
	transition: opacity 0.3s;
}
.ant-spin-container::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	display: none \9;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	transition: all 0.3s;
	content: '';
	pointer-events: none;
}
.ant-spin-blur {
	clear: both;
	overflow: hidden;
	opacity: 0.5;
	user-select: none;
	pointer-events: none;
}
.ant-spin-blur::after {
	opacity: 0.4;
	pointer-events: auto;
}
.ant-spin-tip {
	color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
	position: relative;
	display: inline-block;
	font-size: 20px;
	width: 1em;
	height: 1em;
}
.ant-spin-dot-item {
	position: absolute;
	display: block;
	width: 9px;
	height: 9px;
	background-color: #1b59a8;
	border-radius: 100%;
	transform: scale(0.75);
	transform-origin: 50% 50%;
	opacity: 0.3;
	animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
	top: 0;
	left: 0;
}
.ant-spin-dot-item:nth-child(2) {
	top: 0;
	right: 0;
	animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
	right: 0;
	bottom: 0;
	animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
	bottom: 0;
	left: 0;
	animation-delay: 1.2s;
}
.ant-spin-dot-spin {
	transform: rotate(45deg);
	animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
	font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
	width: 6px;
	height: 6px;
}
.ant-spin-lg .ant-spin-dot {
	font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
	width: 14px;
	height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
	display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ */
	.ant-spin-blur {
		background: #fff;
		opacity: 0.5;
	}
}
@keyframes antSpinMove {
	to {
		opacity: 1;
	}
}
@keyframes antRotate {
	to {
		transform: rotate(405deg);
	}
}
.ant-spin-rtl {
	direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
	transform: rotate(-45deg);
	animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
	to {
		transform: rotate(-405deg);
	}
}
.ant-statistic {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
}
.ant-statistic-title {
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}
.ant-statistic-content {
	color: rgba(0, 0, 0, 0.85);
	font-size: 24px;
	
}
.ant-statistic-content-value {
	display: inline-block;
	direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
	display: inline-block;
}
.ant-statistic-content-prefix {
	margin-right: 4px;
}
.ant-statistic-content-suffix {
	margin-left: 4px;
}
.ant-statistic-rtl {
	direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
	margin-right: 0;
	margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
	margin-right: 4px;
	margin-left: 0;
}
.ant-steps {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: flex;
	width: 100%;
	font-size: 0;
	text-align: initial;
}
.ant-steps-item {
	position: relative;
	display: inline-block;
	flex: 1;
	overflow: hidden;
	vertical-align: top;
}
.ant-steps-item-container {
	outline: none;
}
.ant-steps-item:last-child {
	flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
	display: inline-block;
	vertical-align: top;
}
.ant-steps-item-icon {
	width: 32px;
	height: 32px;
	margin: 0 8px 0 0;
	font-size: 16px;
	
	line-height: 32px;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 32px;
	transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
	position: relative;
	top: -0.5px;
	color: #1b59a8;
	line-height: 1;
}
.ant-steps-item-tail {
	position: absolute;
	top: 12px;
	left: 0;
	width: 100%;
	padding: 0 10px;
}
.ant-steps-item-tail::after {
	display: inline-block;
	width: 100%;
	height: 1px;
	background: #f0f0f0;
	border-radius: 1px;
	transition: background 0.3s;
	content: '';
}
.ant-steps-item-title {
	position: relative;
	display: inline-block;
	padding-right: 16px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 16px;
	line-height: 32px;
}
.ant-steps-item-title::after {
	position: absolute;
	top: 16px;
	left: 100%;
	display: block;
	width: 9999px;
	height: 1px;
	background: #f0f0f0;
	content: '';
}
.ant-steps-item-subtitle {
	display: inline;
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.45);
	font-weight: normal;
	font-size: 14px;
}
.ant-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
	background-color: #fff;
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
	color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
	background-color: #fff;
	border-color: #1b59a8;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
	color: #1b59a8;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: #1b59a8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
	color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background: #1b59a8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
	color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
	font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
	background-color: #fff;
	border-color: #1b59a8;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #1b59a8;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: #1b59a8;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: #1b59a8;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #1b59a8;
}
.ant-steps-item-error .ant-steps-item-icon {
	background-color: #fff;
	border-color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
	color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
	color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
	background: #f5222d;
}
.ant-steps-item-disabled {
	cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
	cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
	transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
	color: #1b59a8;
}
.ant-steps
	.ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
	> .ant-steps-item-container[role='button']:hover
	.ant-steps-item-icon {
	border-color: #1b59a8;
}
.ant-steps
	.ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
	> .ant-steps-item-container[role='button']:hover
	.ant-steps-item-icon
	.ant-steps-icon {
	color: #1b59a8;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-left: 16px;
	white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
	padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
	display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
	max-width: 140px;
	white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
	height: auto;
	background: none;
	border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
	top: 0px;
	left: 0.5px;
	width: 32px;
	height: 32px;
	font-size: 24px;
	line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
	color: #1b59a8;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
	width: auto;
	background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
	width: 24px;
	height: 24px;
	margin: 0 8px 0 0;
	font-size: 12px;
	line-height: 24px;
	text-align: center;
	border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
	padding-right: 12px;
	font-size: 14px;
	line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
	top: 12px;
}
.ant-steps-small .ant-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
	top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
	width: inherit;
	height: inherit;
	line-height: inherit;
	background: none;
	border: 0;
	border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
	font-size: 24px;
	line-height: 24px;
	transform: none;
}
.ant-steps-vertical {
	display: flex;
	flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
	display: block;
	flex: 1 0 auto;
	padding-left: 0;
	overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
	float: left;
	margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
	display: block;
	min-height: 48px;
	overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
	line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
	padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
	position: absolute;
	top: 0;
	left: 16px;
	width: 1px;
	height: 100%;
	padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
	width: 1px;
	height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
	display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
	position: absolute;
	top: 0;
	left: 12px;
	padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
	line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
	overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
	margin-left: 58px;
	padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
	display: block;
	width: 116px;
	margin-top: 8px;
	text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
	display: inline-block;
	margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
	padding-right: 0;
	padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
	display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
	display: block;
	margin-bottom: 4px;
	margin-left: 0;
	line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
	margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
	line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
	top: 2px;
	width: 100%;
	margin: 0 0 0 70px;
	padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
	width: calc(100% - 20px);
	height: 3px;
	margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
	left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
	width: 8px;
	height: 8px;
	margin-left: 67px;
	padding-right: 0;
	line-height: 8px;
	background: transparent;
	border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
	border-radius: 100px;
	transition: all 0.3s;
	/* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
	position: absolute;
	top: -12px;
	left: -26px;
	width: 60px;
	height: 32px;
	background: rgba(0, 0, 0, 0.001);
	content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
	width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
	position: relative;
	top: -1px;
	width: 10px;
	height: 10px;
	line-height: 10px;
	background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
	left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
	margin-top: 8px;
	margin-left: 0;
	background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
	top: 2px;
	left: -9px;
	margin: 0;
	padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
	left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
	width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
	left: -2px;
}
.ant-steps-navigation {
	padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
	margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
	overflow: visible;
	text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
	display: inline-block;
	height: 100%;
	margin-left: -16px;
	padding-bottom: 12px;
	text-align: left;
	transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
	max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
	max-width: 100%;
	padding-right: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
	display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
	cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
	opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
	flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
	display: none;
}
.ant-steps-navigation .ant-steps-item::after {
	position: absolute;
	top: 50%;
	left: 100%;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -14px;
	margin-left: -2px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-bottom: none;
	border-left: none;
	transform: rotate(45deg);
	content: '';
}
.ant-steps-navigation .ant-steps-item::before {
	position: absolute;
	bottom: 0;
	left: 50%;
	display: inline-block;
	width: 0;
	height: 2px;
	background-color: #1b59a8;
	transition: width 0.3s, left 0.3s;
	transition-timing-function: ease-out;
	content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
	left: 0;
	width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
	margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
	display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
	top: 0;
	right: 0;
	left: unset;
	display: block;
	width: 3px;
	height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
	position: relative;
	top: -2px;
	left: 50%;
	display: block;
	width: 8px;
	height: 8px;
	margin-bottom: 8px;
	text-align: center;
	transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
	visibility: hidden;
}
.ant-steps-rtl {
	direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
	margin-right: 0;
	margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
	right: 0;
	left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
	padding-right: 0;
	padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title::after {
	right: 100%;
	left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-right: 16px;
	padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
	padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
	right: 0.5px;
	left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
	margin-right: -12px;
	margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
	margin-right: -16px;
	margin-left: 0;
	text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
	padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
	right: 100%;
	left: auto;
	margin-right: -2px;
	margin-left: 0;
	transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-right: 12px;
	padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
	padding-right: 0;
	padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
	float: right;
	margin-right: 0;
	margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
	right: 16px;
	left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
	right: 12px;
	left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
	padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
	margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
	margin-right: 12px;
	margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
	right: 2px;
	left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
	margin-right: 67px;
	margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
	/* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
	float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
	right: -26px;
	left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
	margin-right: 0;
	margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
	right: -9px;
	left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
	right: 0;
	left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
	right: -2px;
	left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
	padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
	padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
	top: 4px !important;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child {
	padding-bottom: 4px;
	padding-left: 4px;
}
.ant-steps-with-progress .ant-steps-item-icon {
	position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
	position: absolute;
	top: -5px;
	right: -5px;
	bottom: -5px;
	left: -5px;
}
.ant-switch {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	min-width: 44px;
	height: 22px;
	line-height: 22px;
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.25);
	border: 0;
	border-radius: 100px;
	cursor: pointer;
	transition: all 0.2s;
	user-select: none;
}
.ant-switch:focus {
	outline: 0;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-switch:focus:hover {
	box-shadow: none;
}
.ant-switch-checked {
	background-color: #1b59a8;
}
.ant-switch-loading,
.ant-switch-disabled {
	cursor: not-allowed;
	opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
	box-shadow: none;
	cursor: not-allowed;
}
.ant-switch-inner {
	display: block;
	margin: 0 7px 0 25px;
	color: #fff;
	font-size: 12px;
	transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
	margin: 0 25px 0 7px;
}
.ant-switch-handle {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 18px;
	height: 18px;
	transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	border-radius: 9px;
	box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
	transition: all 0.2s ease-in-out;
	content: '';
}
.ant-switch-checked .ant-switch-handle {
	left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
	right: -30%;
	left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
	right: 0;
	left: -30%;
}
.ant-switch-loading-icon {
	position: relative;
	top: 2px;
	color: rgba(0, 0, 0, 0.65);
	vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
	color: #1b59a8;
}
.ant-switch-small {
	min-width: 28px;
	height: 16px;
	line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
	margin: 0 5px 0 18px;
	font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
	width: 12px;
	height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
	top: 1.5px;
	font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
	margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
	left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
	direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
	margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
	right: 2px;
	left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
	right: 0;
	left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
	right: -30%;
	left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
	margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
	right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
	right: calc(100% - 12px - 2px);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
	padding: 8px 0;
	font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
	padding: 16px 0;
	font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
	padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
	padding: 7px 16px 6px;
}
.ant-tabs-rtl {
	direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
	margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
	margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
	margin-right: 0;
	margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
	margin-right: 8px;
	margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
	margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
	order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
	order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
	order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
	order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
	margin-right: 2px;
	margin-left: 0;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
	margin-right: 2px;
	margin-left: 0;
}
.ant-tabs-dropdown-rtl {
	direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
	text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
	flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
	margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
	position: absolute;
	right: 0;
	left: 0;
	border-bottom: 1px solid #f0f0f0;
	content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
	height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
	transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
	top: 0;
	bottom: 0;
	width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
	left: 0;
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
	right: 0;
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
	opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
	opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
	bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
	bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
	order: 1;
	margin-top: 16px;
	margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
	top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
	top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
	order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
	flex-direction: column;
	min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
	padding: 8px 24px;
	text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
	margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
	flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
	right: 0;
	left: 0;
	height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
	top: 0;
	box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
	bottom: 0;
	box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
	opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
	opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
	width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
	transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
	flex: 1 0 auto;
	flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
	right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
	margin-left: -1px;
	border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
	padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
	order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
	left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
	order: 0;
	margin-right: -1px;
	border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
	padding-right: 24px;
}
.ant-tabs-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	display: block;
}
.ant-tabs-dropdown-hidden {
	display: none;
}
.ant-tabs-dropdown-menu {
	max-height: 200px;
	margin: 0;
	padding: 4px 0;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: left;
	list-style-type: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tabs-dropdown-menu-item {
	min-width: 120px;
	margin: 0;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item:hover {
	background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
	margin: 0;
	padding: 8px 16px;
	background: #fafafa;
	border: 1px solid #f0f0f0;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
	color: #1b59a8;
	background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
	visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
	margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 5px 5px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 0 5px 5px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
	margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 5px 0 0 5px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 5px 5px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-left-color: #fff;
}
.ant-tabs {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: flex;
	overflow: hidden;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
	position: relative;
	display: flex;
	flex: none;
	align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
	position: relative;
	display: inline-block;
	display: flex;
	flex: auto;
	align-self: stretch;
	overflow: hidden;
	white-space: nowrap;
	transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
	position: absolute;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
	content: '';
	pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
	position: relative;
	display: flex;
	transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
	display: flex;
	align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
	position: absolute;
	visibility: hidden;
	pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
	position: relative;
	padding: 8px 16px;
	background: transparent;
	border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 5px;
	transform: translateY(100%);
	content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
	min-width: 40px;
	margin-left: 2px;
	padding: 0 8px;
	background: #fafafa;
	border: 1px solid #f0f0f0;
	border-radius: 5px 5px 0 0;
	outline: none;
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
	color: #1b59a8;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
	color: #d94f00;
}
.ant-tabs-extra-content {
	flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
	justify-content: center;
}
.ant-tabs-ink-bar {
	position: absolute;
	background: #1b59a8;
	pointer-events: none;
}
.ant-tabs-tab {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding: 12px 0;
	font-size: 14px;
	background: transparent;
	border: 0;
	outline: none;
	cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: #d94f00;
}
.ant-tabs-tab-btn {
	outline: none;
	transition: all 0.3s;
}
.ant-tabs-tab-remove {
	flex: none;
	margin-right: -4px;
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
	color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab:hover {
	color: #1b59a8;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #1b59a8;
	text-shadow: 0 0 0.25px currentColor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
	color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
	margin: 0;
}
.ant-tabs-tab .anticon {
	margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
	margin: 0 0 0 32px;
}
.ant-tabs-content {
	display: flex;
	width: 100%;
}
.ant-tabs-content-holder {
	flex: auto;
	min-width: 0;
	min-height: 0;
}
.ant-tabs-content-animated {
	transition: margin 0.3s;
}
.ant-tabs-tabpane {
	flex: none;
	width: 100%;
	outline: none;
}
.ant-tag {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
	height: auto;
	margin-right: 8px;
	padding: 0 7px;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	background: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	opacity: 1;
	transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
	color: rgba(0, 0, 0, 0.65);
}
.ant-tag > a:first-child:last-child {
	display: inline-block;
	margin: 0 -8px;
	padding: 0 8px;
}
.ant-tag-close-icon {
	margin-left: 3px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 10px;
	cursor: pointer;
	transition: all 0.3s;
}
.ant-tag-close-icon:hover {
	color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
	border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
	color: #fff;
}
.ant-tag-checkable {
	background-color: transparent;
	border-color: transparent;
	cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
	color: #1b59a8;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
	color: #fff;
}
.ant-tag-checkable-checked {
	background-color: #1b59a8;
}
.ant-tag-checkable:active {
	background-color: #d94f00;
}
.ant-tag-hidden {
	display: none;
}
.ant-tag-pink {
	color: #c41d7f;
	background: #fff0f6;
	border-color: #ffadd2;
}
.ant-tag-pink-inverse {
	color: #fff;
	background: #eb2f96;
	border-color: #eb2f96;
}
.ant-tag-magenta {
	color: #c41d7f;
	background: #fff0f6;
	border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
	color: #fff;
	background: #eb2f96;
	border-color: #eb2f96;
}
.ant-tag-red {
	color: #cf1322;
	background: #fff1f0;
	border-color: #ffa39e;
}
.ant-tag-red-inverse {
	color: #fff;
	background: #f5222d;
	border-color: #f5222d;
}
.ant-tag-volcano {
	color: #d4380d;
	background: #fff2e8;
	border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
	color: #fff;
	background: #fa541c;
	border-color: #fa541c;
}
.ant-tag-orange {
	color: #d46b08;
	background: #fff7e6;
	border-color: #ffd591;
}
.ant-tag-orange-inverse {
	color: #fff;
	background: #fa8c16;
	border-color: #fa8c16;
}
.ant-tag-yellow {
	color: #d4b106;
	background: #feffe6;
	border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
	color: #fff;
	background: #fadb14;
	border-color: #fadb14;
}
.ant-tag-gold {
	color: #d48806;
	background: #fffbe6;
	border-color: #ffe58f;
}
.ant-tag-gold-inverse {
	color: #fff;
	background: #faad14;
	border-color: #faad14;
}
.ant-tag-cyan {
	color: #08979c;
	background: #e6fffb;
	border-color: #87e8de;
}
.ant-tag-cyan-inverse {
	color: #fff;
	background: #13c2c2;
	border-color: #13c2c2;
}
.ant-tag-lime {
	color: #7cb305;
	background: #fcffe6;
	border-color: #eaff8f;
}
.ant-tag-lime-inverse {
	color: #fff;
	background: #a0d911;
	border-color: #a0d911;
}
.ant-tag-green {
	color: #389e0d;
	background: #f6ffed;
	border-color: #b7eb8f;
}
.ant-tag-green-inverse {
	color: #fff;
	background: #52c41a;
	border-color: #52c41a;
}
.ant-tag-blue {
	color: #096dd9;
	background: #e6f7ff;
	border-color: #91d5ff;
}
.ant-tag-blue-inverse {
	color: #fff;
	background: #1890ff;
	border-color: #1890ff;
}
.ant-tag-geekblue {
	color: #1d39c4;
	background: #f0f5ff;
	border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
	color: #fff;
	background: #2f54eb;
	border-color: #2f54eb;
}
.ant-tag-purple {
	color: #531dab;
	background: #f9f0ff;
	border-color: #d3adf7;
}
.ant-tag-purple-inverse {
	color: #fff;
	background: #722ed1;
	border-color: #722ed1;
}
.ant-tag-success {
	color: #52c41a;
	background: #f6ffed;
	border-color: #b7eb8f;
}
.ant-tag-processing {
	color: #1890ff;
	background: #e6f7ff;
	border-color: #91d5ff;
}
.ant-tag-error {
	color: #f5222d;
	background: #fff1f0;
	border-color: #ffa39e;
}
.ant-tag-warning {
	color: #fa8c16;
	background: #fff7e6;
	border-color: #ffd591;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
	margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
	margin-right: 0;
	margin-left: 8px;
	direction: rtl;
	text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
	margin-right: 3px;
	margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
	margin-right: 7px;
	margin-left: 0;
}
.ant-timeline {
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: 'tnum';
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-timeline-item {
	position: relative;
	margin: 0;
	padding-bottom: 20px;
	font-size: 14px;
	list-style: none;
}
.ant-timeline-item-tail {
	position: absolute;
	top: 10px;
	left: 4px;
	height: calc(100% - 10px);
	border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
	font-size: 12px;
	background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
	display: none;
}
.ant-timeline-item-head {
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: #fff;
	border: 2px solid transparent;
	border-radius: 100px;
}
.ant-timeline-item-head-blue {
	color: #1b59a8;
	border-color: #1b59a8;
}
.ant-timeline-item-head-red {
	color: #f5222d;
	border-color: #f5222d;
}
.ant-timeline-item-head-green {
	color: #52c41a;
	border-color: #52c41a;
}
.ant-timeline-item-head-gray {
	color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
	position: absolute;
	top: 5.5px;
	left: 5px;
	width: auto;
	height: auto;
	margin-top: 0;
	padding: 3px 1px;
	line-height: 1;
	text-align: center;
	border: 0;
	border-radius: 0;
	transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
	position: relative;
	top: -7.001px;
	margin: 0 0 0 26px;
	word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
	display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
	min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
	left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
	margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
	margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
	left: calc(50% - 4px);
	width: calc(50% - 14px);
	text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
	width: calc(50% - 12px);
	margin: 0;
	text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
	left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
	width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
	display: block;
	height: calc(100% - 14px);
	border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
	display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
	top: 15px;
	display: block;
	height: calc(100% - 15px);
	border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
	min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
	position: absolute;
	top: -7.001px;
	width: calc(50% - 12px);
	text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
	left: calc(50% + 14px);
	width: calc(50% - 14px);
	text-align: left;
}
.ant-timeline-rtl {
	direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
	right: 4px;
	left: auto;
	border-right: 2px solid #f0f0f0;
	border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
	right: 5px;
	left: auto;
	transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
	margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
	right: 50%;
	left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
	margin-right: -4px;
	margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
	margin-right: 1px;
	margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
	right: calc(50% - 4px);
	left: auto;
	text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
	text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
	right: 0;
	left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
	width: 100%;
	margin-right: 18px;
	text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
	border-right: 2px dotted #f0f0f0;
	border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
	border-right: 2px dotted #f0f0f0;
	border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
	text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
	right: calc(50% + 14px);
	text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
	flex: 1 1 50%;
	width: auto;
	height: auto;
	min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
	border: 0;
	border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
	background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
	border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
	margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
	margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
	background-color: transparent;
}
.ant-transfer {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: flex;
	align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
	background: #f5f5f5;
}
.ant-transfer-list {
	display: flex;
	flex-direction: column;
	width: 180px;
	height: 200px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}
.ant-transfer-list-with-pagination {
	width: 250px;
	height: auto;
}
.ant-transfer-list-search {
	padding-right: 24px;
	padding-left: 8px;
}
.ant-transfer-list-search-action {
	position: absolute;
	top: 12px;
	right: 12px;
	bottom: 12px;
	width: 28px;
	color: rgba(0, 0, 0, 0.25);
	line-height: 32px;
	text-align: center;
}
.ant-transfer-list-search-action .anticon {
	color: rgba(0, 0, 0, 0.25);
	transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
	color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
	pointer-events: none;
}
.ant-transfer-list-header {
	display: flex;
	flex: none;
	align-items: center;
	height: 40px;
	padding: 8px 12px 9px;
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
	border-bottom: 1px solid #f0f0f0;
	border-radius: 5px 5px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
	margin-right: 4px;
}
.ant-transfer-list-header > * {
	flex: none;
}
.ant-transfer-list-header-title {
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-align: right;
	text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
	font-size: 10px;
	transform: translateY(10%);
	cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
	cursor: not-allowed;
}
.ant-transfer-list-body {
	display: flex;
	flex: auto;
	flex-direction: column;
	overflow: hidden;
	font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
	position: relative;
	flex: none;
	padding: 12px;
}
.ant-transfer-list-content {
	flex: auto;
	margin: 0;
	padding: 0;
	overflow: auto;
	list-style: none;
}
.ant-transfer-list-content-item {
	display: flex;
	align-items: center;
	min-height: 32px;
	padding: 6px 12px;
	line-height: 20px;
	transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
	margin-right: 8px;
}
.ant-transfer-list-content-item > * {
	flex: none;
}
.ant-transfer-list-content-item-text {
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
	color: #5c987a;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	position: relative;
	color: #d9d9d9;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
	color: #80a691;
}
.ant-transfer-list-content-item-remove:active {
	color: #40735b;
}
.ant-transfer-list-content-item-remove::after {
	position: absolute;
	top: -6px;
	right: -50%;
	bottom: -6px;
	left: -50%;
	content: '';
}
.ant-transfer-list-content-item-remove:hover {
	color: #80a691;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
	background-color: #f5f5f5;
	cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
	background-color: #fff0dc;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
	background: transparent;
	cursor: default;
}
.ant-transfer-list-content-item-checked {
	background-color: #fff4e6;
}
.ant-transfer-list-content-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-transfer-list-pagination {
	padding: 8px 0;
	text-align: right;
	border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
	flex: none;
	width: 100%;
	margin: auto 0;
	color: rgba(0, 0, 0, 0.25);
	text-align: center;
}
.ant-transfer-list-footer {
	border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
	display: flex;
	flex: none;
	flex-direction: column;
	align-self: center;
	margin: 0 8px;
	vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
	display: block;
}
.ant-transfer-operation .ant-btn:first-child {
	margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
	font-size: 12px;
}
.ant-transfer .ant-empty-image {
	max-height: -2px;
}
.ant-transfer-rtl {
	direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
	padding-right: 8px;
	padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
	right: auto;
	left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
	margin-right: 0;
	margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
	right: 0;
	left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
	text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
	margin-right: 0;
	margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
	text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
	right: 0;
	left: auto;
}
.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
	top: auto !important;
	bottom: auto !important;
	height: 14px !important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
	position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 4px;
	left: 0;
	transition: background-color 0.3s;
	content: '';
	pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
	background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
	z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
	transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
	border-radius: 0;
	user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
	background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
	color: #fff;
	background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
	background: #1b59a8;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
	color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
	color: #fff;
	background: transparent;
}
.ant-tree-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	top: 0.2em;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
	border-color: #1b59a8;
}
.ant-tree-checkbox-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #1b59a8;
	border-radius: 2px;
	visibility: hidden;
	animation: antCheckboxEffect 0.36s ease-in-out;
	animation-fill-mode: backwards;
	content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
	visibility: visible;
}
.ant-tree-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	direction: ltr;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 22%;
	display: table;
	width: 5.71428571px;
	height: 9.14285714px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	opacity: 0;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	content: ' ';
}
.ant-tree-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
	background-color: #1b59a8;
	border-color: #1b59a8;
}
.ant-tree-checkbox-disabled {
	cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
	cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}
.ant-tree-checkbox-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
	visibility: hidden;
}
.ant-tree-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-flex;
	align-items: baseline;
	line-height: unset;
	cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
	cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
	margin-left: 8px;
}
.ant-tree-checkbox + span {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-tree-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
}
.ant-tree-checkbox-group-item {
	margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
	margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
	margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: #1b59a8;
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
	direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
	margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
	margin-left: 8px;
}
.ant-tree {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	background: #fff;
	border-radius: 2px;
	transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
	background: #fff4e6;
}
.ant-tree-list-holder-inner {
	align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
	align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
	flex: auto;
}
.ant-tree .ant-tree-treenode {
	display: flex;
	align-items: flex-start;
	padding: 0 0 4px 0;
	outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
	background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
	background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
	color: inherit;
	font-weight: 500;
}
.ant-tree-indent {
	align-self: stretch;
	white-space: nowrap;
	user-select: none;
}
.ant-tree-indent-unit {
	display: inline-block;
	width: 24px;
}
.ant-tree-switcher {
	position: relative;
	flex: none;
	align-self: stretch;
	width: 24px;
	margin: 0;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
	display: inline-block;
	font-size: 10px;
	vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
	transition: transform 0.3s;
}
.ant-tree-switcher-noop {
	cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
	transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
	color: #1b59a8;
}
.ant-tree-switcher-leaf-line {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;
	height: 100%;
}
.ant-tree-switcher-leaf-line::before {
	position: absolute;
	top: 0;
	bottom: -4px;
	margin-left: -1px;
	border-left: 1px solid #d9d9d9;
	content: ' ';
}
.ant-tree-switcher-leaf-line::after {
	position: absolute;
	width: 10px;
	height: 14px;
	margin-left: -1px;
	border-bottom: 1px solid #d9d9d9;
	content: ' ';
}
.ant-tree-checkbox {
	top: initial;
	margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
	position: relative;
	z-index: auto;
	min-height: 24px;
	margin: 0;
	padding: 0 4px;
	color: inherit;
	line-height: 24px;
	background: transparent;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
	background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background-color: #ffd4a3;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
	display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
	background-color: transparent;
}
.ant-tree-node-content-wrapper[draggable='true'] {
	line-height: 24px;
	user-select: none;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
	position: absolute;
	z-index: 1;
	height: 2px;
	background-color: #1b59a8;
	border-radius: 1px;
	pointer-events: none;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
	position: absolute;
	top: -3px;
	left: -6px;
	width: 8px;
	height: 8px;
	background-color: transparent;
	border: 2px solid #1b59a8;
	border-radius: 50%;
	content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
	box-shadow: 0 0 0 2px #1b59a8;
}
.ant-tree-show-line .ant-tree-indent-unit {
	position: relative;
	height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
	position: absolute;
	top: 0;
	right: 12px;
	bottom: -4px;
	border-right: 1px solid #d9d9d9;
	content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
	display: none;
}
.ant-tree-show-line .ant-tree-switcher {
	background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
	vertical-align: -0.225em;
}
.ant-tree-rtl {
	direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
	right: -6px;
	left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
	direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
	transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
	right: auto;
	left: -13px;
	border-right: none;
	border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl.ant-tree-checkbox {
	margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
	margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	top: 0.2em;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
	border-color: #1b59a8;
}
.ant-select-tree-checkbox-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #1b59a8;
	border-radius: 2px;
	visibility: hidden;
	animation: antCheckboxEffect 0.36s ease-in-out;
	animation-fill-mode: backwards;
	content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
	visibility: visible;
}
.ant-select-tree-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	direction: ltr;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 22%;
	display: table;
	width: 5.71428571px;
	height: 9.14285714px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	opacity: 0;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	content: ' ';
}
.ant-select-tree-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
	background-color: #1b59a8;
	border-color: #1b59a8;
}
.ant-select-tree-checkbox-disabled {
	cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
	cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
	visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-flex;
	align-items: baseline;
	line-height: unset;
	cursor: pointer;
}
.ant-select-tree-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
	cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
	margin-left: 8px;
}
.ant-select-tree-checkbox + span {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-select-tree-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
}
.ant-select-tree-checkbox-group-item {
	margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
	margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
	margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: #1b59a8;
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
	direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
	margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
	margin-left: 8px;
}
.ant-tree-select-dropdown {
	padding: 8px 4px 0;
}
.ant-tree-select-dropdown-rtl {
	direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
	border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
	align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
	padding-bottom: 8px;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
	flex: auto;
}
.ant-select-tree {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	background: #fff;
	border-radius: 2px;
	transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
	background: #fff4e6;
}
.ant-select-tree-list-holder-inner {
	align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
	align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
	flex: auto;
}
.ant-select-tree .ant-select-tree-treenode {
	display: flex;
	align-items: flex-start;
	padding: 0 0 4px 0;
	outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
	background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
	background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
	color: inherit;
	font-weight: 500;
}
.ant-select-tree-indent {
	align-self: stretch;
	white-space: nowrap;
	user-select: none;
}
.ant-select-tree-indent-unit {
	display: inline-block;
	width: 24px;
}
.ant-select-tree-switcher {
	position: relative;
	flex: none;
	align-self: stretch;
	width: 24px;
	margin: 0;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
	display: inline-block;
	font-size: 10px;
	vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
	transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
	cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
	transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
	color: #1b59a8;
}
.ant-select-tree-switcher-leaf-line {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;
	height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
	position: absolute;
	top: 0;
	bottom: -4px;
	margin-left: -1px;
	border-left: 1px solid #d9d9d9;
	content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
	position: absolute;
	width: 10px;
	height: 14px;
	margin-left: -1px;
	border-bottom: 1px solid #d9d9d9;
	content: ' ';
}
.ant-select-tree-checkbox {
	top: initial;
	margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
	position: relative;
	z-index: auto;
	min-height: 24px;
	margin: 0;
	padding: 0 4px;
	color: inherit;
	line-height: 24px;
	background: transparent;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
	background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
	background-color: #ffd4a3;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
	display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
	background-color: transparent;
}
.ant-select-tree-node-content-wrapper[draggable='true'] {
	line-height: 24px;
	user-select: none;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
	position: absolute;
	z-index: 1;
	height: 2px;
	background-color: #1b59a8;
	border-radius: 1px;
	pointer-events: none;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
	position: absolute;
	top: -3px;
	left: -6px;
	width: 8px;
	height: 8px;
	background-color: transparent;
	border: 2px solid #1b59a8;
	border-radius: 50%;
	content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
	box-shadow: 0 0 0 2px #1b59a8;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
	position: relative;
	height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
	position: absolute;
	top: 0;
	right: 12px;
	bottom: -4px;
	border-right: 1px solid #d9d9d9;
	content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
	display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
	background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
	vertical-align: -0.225em;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
	transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
	transform: scaleY(-1);
}
.ant-typography {
	color: rgba(0, 0, 0, 0.65);
	overflow-wrap: break-word;
}
.ant-typography.ant-typography-secondary {
	color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
	color: #52c41a;
}
.ant-typography.ant-typography-warning {
	color: #faad14;
}
.ant-typography.ant-typography-danger {
	color: #f5222d;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus,
a.ant-typography.ant-typography-danger:hover {
	color: #ff4d4f;
}
.ant-typography.ant-typography-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	user-select: none;
}
div.ant-typography,
.ant-typography p {
	margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 38px;
	line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 30px;
	line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 24px;
	line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 20px;
	line-height: 1.4;
}
h5.ant-typography,
.ant-typography h5 {
	margin-bottom: 0.5em;
	/* color: rgba(0, 0, 0, 0.85); */
	font-weight: 600;
	font-size: 16px;
	line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
	margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
	margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
	display: inline-block;
	max-width: 100%;
}
a.ant-typography,
.ant-typography a {
	color: #5c987a;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	text-decoration: none;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
	color: #80a691;
}
a.ant-typography:active,
.ant-typography a:active {
	color: #40735b;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
	text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
	pointer-events: none;
}
.ant-typography code {
	margin: 0 0.2em;
	padding: 0.2em 0.4em 0.1em;
	font-size: 85%;
	background: rgba(150, 150, 150, 0.1);
	border: 1px solid rgba(100, 100, 100, 0.2);
	border-radius: 3px;
}
.ant-typography kbd {
	margin: 0 0.2em;
	padding: 0.15em 0.4em 0.1em;
	font-size: 90%;
	background: rgba(150, 150, 150, 0.06);
	border: 1px solid rgba(100, 100, 100, 0.2);
	border-bottom-width: 2px;
	border-radius: 3px;
}
.ant-typography mark {
	padding: 0;
	background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
	text-decoration: underline;
	text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
	text-decoration: line-through;
}
.ant-typography strong {
	font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
	color: #5c987a;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	margin-left: 4px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
	color: #80a691;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
	color: #40735b;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
	color: #52c41a;
}
.ant-typography-edit-content {
	position: relative;
}
div.ant-typography-edit-content {
	left: -12px;
	margin-top: -5px;
	margin-bottom: calc(1em - 4px - 1px);
}
.ant-typography-edit-content-confirm {
	position: absolute;
	right: 10px;
	bottom: 8px;
	color: rgba(0, 0, 0, 0.45);
	pointer-events: none;
}
.ant-typography-edit-content textarea {
	-moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
	margin: 0 0 1em 0;
	padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
	margin: 0 0 0 20px;
	padding: 0 0 0 4px;
}
.ant-typography ul {
	list-style-type: circle;
}
.ant-typography ul ul {
	list-style-type: disc;
}
.ant-typography ol {
	list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
	margin: 1em 0;
}
.ant-typography pre {
	padding: 0.4em 0.6em;
	white-space: pre-wrap;
	word-wrap: break-word;
	background: rgba(150, 150, 150, 0.1);
	border: 1px solid rgba(100, 100, 100, 0.2);
	border-radius: 3px;
}
.ant-typography pre code {
	display: inline;
	margin: 0;
	padding: 0;
	font-size: inherit;
	font-family: inherit;
	background: transparent;
	border: 0;
}
.ant-typography blockquote {
	padding: 0 0 0 0.6em;
	border-left: 4px solid rgba(100, 100, 100, 0.2);
	opacity: 0.85;
}
.ant-typography-single-line {
	white-space: nowrap;
}
.ant-typography-ellipsis-single-line {
	overflow: hidden;
	text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
	vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 3;
	/*! autoprefixer: ignore next */
	-webkit-box-orient: vertical;
}
.ant-typography-rtl {
	direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
	margin-right: 4px;
	margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
	float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
	right: -12px;
	left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
	right: auto;
	left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
	margin: 0 20px 0 0;
	padding: 0 4px 0 0;
}
.ant-upload {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	outline: 0;
}
.ant-upload p {
	margin: 0;
}
.ant-upload-btn {
	display: block;
	width: 100%;
	outline: none;
}
.ant-upload input[type='file'] {
	cursor: pointer;
}
.ant-upload.ant-upload-select {
	display: inline-block;
}
.ant-upload.ant-upload-disabled {
	cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
	width: 104px;
	height: 104px;
	margin-right: 8px;
	margin-bottom: 8px;
	text-align: center;
	vertical-align: top;
	background-color: #fafafa;
	border: 1px dashed #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
	border-color: #1b59a8;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
	border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	background: #fafafa;
	border: 1px dashed #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
	padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
	border-color: #d94f00;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
	cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
	display: table;
	height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
	display: table-cell;
	vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
	border-color: #1b59a8;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
	margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
	color: #1b59a8;
	font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
	margin: 0 0 4px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
	color: rgba(0, 0, 0, 0.25);
	font-size: 30px;
	transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
	color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
	display: inline-block;
	width: 100%;
}
.ant-upload-picture-card-wrapper::before {
	display: table;
	content: '';
}
.ant-upload-picture-card-wrapper::after {
	display: table;
	clear: both;
	content: '';
}
.ant-upload-picture-card-wrapper::before {
	display: table;
	content: '';
}
.ant-upload-picture-card-wrapper::after {
	display: table;
	clear: both;
	content: '';
}
.ant-upload-list {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	line-height: 1.5715;
}
.ant-upload-list::before {
	display: table;
	content: '';
}
.ant-upload-list::after {
	display: table;
	clear: both;
	content: '';
}
.ant-upload-list::before {
	display: table;
	content: '';
}
.ant-upload-list::after {
	display: table;
	clear: both;
	content: '';
}
.ant-upload-list-item {
	position: relative;
	height: 22.001px;
	margin-top: 8px;
	font-size: 14px;
}
.ant-upload-list-item-name {
	display: inline-block;
	width: 100%;
	padding-left: 22px;
	overflow: hidden;
	line-height: 1.5715;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
	position: absolute;
	right: 0;
}
.ant-upload-list-item-card-actions-btn {
	opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
	height: 20px;
	line-height: 1;
}
.ant-upload-list-item-card-actions.picture {
	top: 22px;
	line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
	opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
	color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
	height: 100%;
	padding: 0 4px;
	transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
	display: block;
	width: 100%;
	height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
	position: absolute;
	top: 5px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}
.ant-upload-list-item .anticon-close {
	position: absolute;
	top: 6px;
	right: 4px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 10px;
	line-height: 0;
	cursor: pointer;
	opacity: 0;
	transition: all 0.3s;
}
.ant-upload-list-item .anticon-close:hover {
	color: rgba(0, 0, 0, 0.65);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
	background-color: #f5f5f5;
}
.ant-upload-list-item:hover .anticon-close {
	opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
	opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
	color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
	color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
	opacity: 1;
}
.ant-upload-list-item-progress {
	position: absolute;
	bottom: -12px;
	width: 100%;
	padding-left: 26px;
	font-size: 14px;
	line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
	position: relative;
	height: 66px;
	padding: 8px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
	background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
	border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
	padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
	background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
	border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
	width: 48px;
	height: 48px;
	line-height: 54px;
	text-align: center;
	opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
	font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
	fill: #fff1f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
	fill: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 26px;
	transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
	font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
	max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	display: block;
	width: 48px;
	height: 48px;
	overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
	display: inline-block;
	box-sizing: border-box;
	max-width: 100%;
	margin: 0 0 0 8px;
	padding-right: 8px;
	padding-left: 48px;
	overflow: hidden;
	line-height: 44px;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
	line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
	bottom: 14px;
	width: calc(100% - 24px);
	margin-top: 0;
	padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
	position: absolute;
	top: 8px;
	right: 8px;
	line-height: 1;
	opacity: 1;
}
.ant-upload-list-picture-card-container {
	display: inline-block;
	width: 104px;
	height: 104px;
	margin: 0 8px 8px 0;
	vertical-align: top;
}
.ant-upload-list-picture-card.ant-upload-list::after {
	display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
	height: 100%;
	margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
	position: relative;
	height: 100%;
	overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: all 0.3s;
	content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
	opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	white-space: nowrap;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
	z-index: 10;
	width: 16px;
	margin: 0 4px;
	color: rgba(255, 255, 255, 0.85);
	font-size: 16px;
	cursor: pointer;
	transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
	color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
	opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	position: static;
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
	display: none;
	margin: 8px 0 0;
	padding: 0;
	line-height: 1.5715;
	text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
	position: absolute;
	bottom: 10px;
	display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
	background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
	height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
	display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
	bottom: 32px;
	width: calc(100% - 14px);
	padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
	transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
	display: table;
	width: 0;
	height: 0;
	content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
	display: block;
	flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
	display: flex;
	align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
	flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
	flex: auto;
	padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
	position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
	position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
	animation-duration: 0.3s;
	animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
	animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
	animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
	from {
		width: 0;
		height: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
	}
}
@keyframes uploadAnimateInlineOut {
	to {
		width: 0;
		height: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
	}
}
.ant-upload-rtl {
	direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
	margin-right: auto;
	margin-left: 8px;
}
.ant-upload-list-rtl {
	direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
	padding-right: 22px;
	padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
	padding-right: 22px;
	padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
	padding-right: 22px;
	padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
	padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
	right: auto;
	left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
	padding-right: 0;
	padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
	padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
	right: auto;
	left: 4px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
	padding-right: 0;
	padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
	padding-right: 26px;
	padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
	padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
	right: 8px;
	left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
	right: 50%;
	left: auto;
	transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
	margin: 0 8px 0 0;
	padding-right: 48px;
	padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
	padding-right: 48px;
	padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
	padding-right: 48px;
	padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
	padding-right: 0;
	padding-left: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
	right: auto;
	left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
	margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
	right: 50%;
	left: auto;
	transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
	margin: 8px 0 0;
	padding: 0;
}
